import React, { useState } from "react";
import SubtabsHeader from "../SubtabsHeader";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SendIcon from "@mui/icons-material/Send";
import Loader from "../../../components/atoms/loader";

export default function AIStudio() {
  const [search, setSearch] = useState("");
  const [isImage, setImage] = useState(false)
  const [isLoader, setLoader] = useState(false)

  return (
    <div className="h-[100vh] bg-white ">
      <div>
        <SubtabsHeader />
      </div>
      <div className=" mb-2" style={{ height: "80%" }}>
        {isImage && <FilerobotImageEditor
          source="/demo-template.jpg"
          onSave={(editedImageObject, designState) => {
            //console.log("saved", editedImageObject, designState)
          }}
          //onClose={closeImgEditor}
          annotationsCommon={{
            fill: "#ff0000",
          }}
          Text={{ text: "Filerobot..." }}
          Rotate={{ angle: 90, componentType: "slider" }}
          tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK]} // or {['Adjust', 'Annotate', 'Watermark']}
          defaultTabId={TABS.ANNOTATE} // or 'Annotate'
          defaultToolId={TOOLS.TEXT} // or 'Text'
        />}
        <div style={{marginLeft: isImage ? 60 : 0}} className="mt-2 w-full flex items-center justify-center flex-col">
          
          <TextField
            onChange={(e) => setSearch(e.target.value)}
            variant="outlined"
            value={search}
            sx={{
              width: "600px",
              "& .MuiOutlinedInput-root:hover fieldset": {
                borderColor: "rgb(69, 69, 69)",
              },
              "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                outline: "none",
                boxShadow: "none",
                border: search
                  ? "1px solid rgb(48, 36, 139)"
                  : "1px solid rgb(69, 69, 69)",
              },
              "& .MuiOutlinedInput-root": {
                paddingRight: "10px",
              },
            }}
            placeholder="Type here to Create content using GenAI............."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SendIcon
                    sx={{
                      color: search ? "rgb(91, 71, 245)" : "rgb(142, 139, 157)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setLoader(true)
                      setTimeout(() => {
                        setLoader(false)
                        setImage(true)
                        setSearch("")
                      }, 3400)
                      
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          {isLoader &&<div className="mt-3"> <Loader /></div>}
        </div>
      </div>
    </div>
  );
}
