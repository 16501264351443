import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { Connection, Input } from '@nodl/core';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useHover } from '../../hooks/useHover/useHover';
import { StoreContext } from '../../stores/CircuitStore/CircuitStore';
import { Tooltip } from '../Tooltip/Tooltip';
import { TooltipPosition } from '../Tooltip/Tooltip.types';
import { portTypeStyles, portWrapperStyles } from './Port.styles';
export const Port = observer(({ port, isOutput }) => {
    const ref = React.useRef(null);
    const { onMouseEnter, onMouseLeave, isHovered } = useHover();
    const { onMouseEnter: onPortTypeEnter, onMouseLeave: onPortTypeLeave, isHovered: isPortTypeHovered } = useHover();
    const { store } = React.useContext(StoreContext);
    const tooltipPosition = React.useMemo(() => (isOutput ? TooltipPosition.RIGHT : TooltipPosition.LEFT), [isOutput]);
    const visuallyDisabled = React.useMemo(() => {
        const isOccupied = !isOutput && port.connected;
        const hasDifferentValueType = store.draftConnectionSource && port instanceof Input ? !Connection.isTypeCompatible(store.draftConnectionSource, port) : true;
        const hasSharedNode = store.draftConnectionSource
            ? store.getNodeByPortId(store.draftConnectionSource.id) === store.getNodeByPortId(port.id)
            : false;
        // const isUnrelatedToConnectionDraft = store.draftConnectionSource !== port;
        return store.draftConnectionSource ? isOccupied || hasDifferentValueType || isOutput || hasSharedNode : false;
    }, [isOutput]);
    React.useEffect(() => {
        if (ref.current) {
            store.setPortElement(port.id, ref.current);
            return () => {
                store.removePortElement(port.id);
            };
        }
    }, []);
    const onMouseDown = React.useCallback(() => {
        if (isOutput) {
            store.setDraftConnectionSource(port);
        }
    }, [isOutput]);
    const onMouseUp = React.useCallback(() => {
        if (!isOutput && store.draftConnectionSource) {
            store.commitDraftConnection(port);
        }
    }, [isOutput]);
    const onClick = React.useCallback(() => {
        if (port.connected) {
            const connections = 'connection' in port ? [port.connection] : port.connections;
            for (const connection of connections) {
                if (connection) {
                    connection.dispose();
                }
            }
        }
    }, [port]);
    return (_jsx(Tooltip, Object.assign({ text: port.type.name, position: tooltipPosition }, { children: _jsxs("div", Object.assign({ ref: ref, css: portWrapperStyles(port.connected ||
                (!store.draftConnectionSource && isHovered) ||
                (!!store.draftConnectionSource && !visuallyDisabled), isOutput, visuallyDisabled), onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onMouseUp: onMouseUp, onMouseDown: onMouseDown }, { children: [_jsx("div", Object.assign({ css: portTypeStyles(port.connected, isOutput, isHovered && !visuallyDisabled, isPortTypeHovered && !visuallyDisabled), onMouseEnter: onPortTypeEnter, onMouseLeave: onPortTypeLeave, onClick: onClick }, { children: port.connected && isPortTypeHovered && !visuallyDisabled ? (_jsx("span", { children: "x" })) : (_jsx("span", { children: port.type.name.charAt(0) })) })), _jsx("span", { children: port.name })] })) })));
});
