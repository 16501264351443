import { CircuitStore, Circuit } from "@nodl/react";
import { Node, Input, Output, schema } from "@nodl/core";
import { Observable, map } from "rxjs";

import { DataTempSchema } from "../Schema/DataTemp";

class UserOutput extends Node {
  name = "Users Output";

  inputs = {
    source: new Input({ name: "input", type: DataTempSchema, defaultValue: {} }),
  };
  outputs = {}

  uid = "output-user";

  outputs_: any = {}

  getMaps() {
    return this.outputs_
  }

  getColumns() {
    const val : any = this.inputs.source.value
    return val?.columns || []
  }

  setMap(key: any, val: any) {
    this.outputs_[key] = val
  }

  constructor(outputs_?: any) {
    super();
    if(outputs_){
      console.log("olrr", outputs_)
      this.outputs_ = outputs_
    }else{
      this.outputs_ ={
        "FName": "",
        "LName": "",
        "Gender": "",
        "Age": ""
      }
    }
  }
}

export { UserOutput };
