import React, { useEffect, useState } from "react";
import styles from "../sidebarLayout.module.scss";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";

const OutputRSB = ({ node }: { node: any }) => {
  const [maps, setMaps] : [any, any] = useState(node.outputs_)
  return (
    <div>
      <div className={styles.MainTextHeader}>Output</div>
      <div className="p-2 m-2">
        <span>Mapping</span>
      </div>
      <div className="p-2 m-2">
        <div className="flex">
          <div className="flex-1">Source</div>
          <div className="flex-1">Destination</div>
        </div>
        {Object.keys(maps).map((val: any) => {
          return (
            <div className="flex">
              <div className="flex-1 mr-1">
                <FormControl fullWidth>
                  <Select value={maps[val]} onChange={(e) => {
                    node.setMap(val, e.target.value)
                    let x : any = {}
                    x[val] = e.target.value
                    setMaps({...maps, ...x})
                  }}>
                    {node.getColumns().map((val: any) => {
                      return <MenuItem value={val.name}>{val.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="flex-1 ml-1">
                <TextField
                  disabled
                  value={val}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { OutputRSB };
