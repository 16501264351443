import React, { useState } from "react";
import styles from "../sidebarLayout.module.scss";
import { Box } from "@mui/system";
import { Button } from "@mui/material";

const CsvConnectorRSB = ({ node }: { node: any }) => {
  const [selectedFile, setSelectedFile]: [any, any] = useState(null);

  const handleFileChange = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };
  return (
    <div>
      <div>
        <div className={styles.MainTextHeader}>Columns</div>
        <div className="flex flex-col mx-4 my-2">
          {node.getColumns().map((val: {name: string, type: string})=>{
            return (<span>{val.name}</span>)
          })}
        </div>
        <div className={styles.MainTextHeader}>Upload Data</div>
        <div className="mx-4 my-2">
          <Box mt={2}>
            <input
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
              id="file-upload"
            />
            <label htmlFor="file-upload">
              <Button
                sx={{
                  backgroundColor: "#5F46FF",
                  ":hover": {
                    backgroundColor: "#5F46FF",
                  },
                }}
                variant="contained"
                component="span"
              >
                Choose CSV File
              </Button>
            </label>
            {selectedFile && (
              <Box mt={2}>
                <Box component="span">
                  {selectedFile.name}
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </div>
    </div>
  );
};

export { CsvConnectorRSB };
