import CommonTable from "../../../../../components/organisms/CommonTable";

const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}
export const ActiveCol: any = () => {
    return (
        [
            {
                id: 'ad',
                columnLabel: 'Ad',
                sortLabel: true,
                style: {
                    //width: 300,
                    textAlign: 'left',
                    paddingLeft: '16px'
                },
                render: (row: any, index: any) => (
                    <p onClick={() => { }} style={{ paddingLeft: '16px', textDecoration: "underline", color: '#5B47F5', cursor: 'pointer' }}>
                        {row?.ad}
                    </p>
                ),
            },
            {
                id: 'type',
                columnLabel: 'Type',
                style: {
                    //width: '300px'
                },
                render: (row: any) => (
                    <p color='#051433' onClick={() => { }} style={{ ...style }}>
                        {row.type}
                    </p>
                ),
            },
            {
                id: 'budget',
                columnLabel: 'Budget',
                style: {
                    //textAlign: 'left',
                    //width: '150px'
                },
                render: (row: any) => (
                    <p color='#051433' onClick={() => { }} style={{  ...style }}>
                        {row.price}
                    </p>
                ),
            },
            {
                id: 'createdOn',
                columnLabel: 'Created on',
                //minWidth: 40,
                render: (row: any) => (
                    <p color='#051433' onClick={() => { }} style={{ ...style }}>
                        {row?.created_on}
                    </p>
                ),
            },
            {
                id: 'status',
                columnLabel: 'Status',
                style: {
                    //width: '190px',
                    //textAlign: 'left'
                },
                render: (row: any) => (
                    <p color='#051433' onClick={() => { }} style={{ ...style }}>
                        {row?.status}
                    </p>
                ),
            },
            // {
            //     id: 'report',
            //     columnLabel: 'Report',
            //     minWidth: 40,
            //     render: (row: any) => (
            //         <p color='#051433' onClick={() => { }} style={{ ...style,gap:'8px' }}>
            //             <img src={desc} alt="" width={24} />
            //             <p style={{textDecoration:'underline'}}>{row.report}</p>
            //         </p>
            //     ),
            // },
        ]
    )
}

export const rows = [
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '24,755', price: '$ 2000 ', created_on: '24/05/2023', status: 'Active'},
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '35,786', price: '$ 300 ', created_on: '24/05/2023', status: 'Active'},
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '254', price: '$ 600 ', created_on: '24/05/2023', status: 'Withdrawn'},
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '1,22,558', price: '$ 800 ', created_on: '24/05/2023', status: 'Active'},
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '500', price: '$ 900 ', created_on: '24/05/2023', status: 'Withdrawn'},
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '9,728', price: '$ 3000 ', created_on: '24/05/2023', status: 'Withdrawn'},
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '20,889', price: '$ 1600 ', created_on: '24/05/2023', status: 'Active'},
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '256', price: '$ 400 ', created_on: '24/05/2023', status: 'Withdrawn'},
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '500', price: '$ 6000 ', created_on: '24/05/2023', status: 'Withdrawn'},
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '9,728', price: '$ 900 ', created_on: '24/05/2023', status: 'Withdrawn'},
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '20,889', price: '$ 2000 ', created_on: '24/05/2023', status: 'Active'},
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '256', price: '$ 400 ', created_on: '24/05/2023', status: 'Withdrawn'},
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '500', price: '$ 700 ', created_on: '24/05/2023', status: 'Withdrawn'},
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '9,728', price: '$ 850 ', created_on: '24/05/2023', status: 'Withdrawn'},
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '20,889', price: '$ 200 ', created_on: '24/05/2023', status: 'Active'},
    { ad: '‘Welcome back’ 200 X 200 banner', type: 'Product', budget: '256', price: '$ 4000 ', created_on: '24/05/2023', status: 'Withdrawn'},
];

export const DataTable = ({ data, handleRowClick }: any) => {
    return (
        <div>
            <CommonTable {...{
                cols: ActiveCol(),
                data: data,
                tableData: data,
                selection: false,
                onRowClick: (row:any) => handleRowClick(row, "manage"),
                tableContainerStyles: {
                    "& .MuiTableCell-head": {
                        textTransform: 'none'
                    }
                }
            }} />
        </div>
    )
}