import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Content = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("/content-manager")
  }, [])
  return (
    <div>
    </div>
  );
};

export { Content };
