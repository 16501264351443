import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ReactComponent as XylicLogo } from '../../../assets/svg/xylicLogo.svg';
import { ReactComponent as BottomLogo } from '../../../assets/svg/loginLogo.svg';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormInput from '../../../components/atoms/FormInputField';
import { CustomButton } from '../../../components/atoms/button/button';
import styles from './register.module.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const defaultTheme = createTheme();

export default function RegisterPage() {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };

    const navigate = useNavigate()

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid
                container
                component='main'
                sx={{ height: '100vh' }}
                className={`font-poppins ${styles.mainCont}`}
            >

                <CssBaseline />

                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={8}
                    // className={styles.bgimage}
                    sx={{
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#5B47F5',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <Grid sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh'
                    }}>
                        <Grid sx={{
                            gap: '20px', display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <p style={{ color: 'white', fontWeight: 800, fontSize: '40px' }}>Xylic Data</p>
                            <p style={{ color: 'white', fontWeight: 400, fontSize: '20px' }}>
                                Build, Manage and Optimise <br />
                                multiple revenue streams from a single platform
                            </p>
                            <Button sx={{
                                padding: '10px',
                                background: 'white',
                                borderRadius: '20px',
                                fontSize: '14px',
                                width: '135px', height: '37px',
                                '&:hover': {
                                    background: 'white',
                                }
                            }}>
                                Read More
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid sx={{
                        position: 'fixed',
                        top: 0,
                        right: '33.3%',
                        filter: 'blur(200px)',
                        background: 'rgb(207, 200, 255)',
                        height: '40%',
                        width: '30%'
                    }}></Grid>
                    <Grid sx={{
                        position: 'fixed',
                        bottom: 10,
                        filter: 'blur(200px)',
                        background: 'rgb(207, 200, 255)',
                        height: '30%',
                        width: '20%'
                    }}></Grid>
                    <Grid sx={{
                        position: 'fixed',
                        bottom: 0,
                    }}>
                        <BottomLogo />
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        padding: '0px 80px',
                        gap: '24px',
                        background: 'white'
                    }}
                >
                    <Grid>
                        <XylicLogo />
                    </Grid>
                    <Typography component='h1' variant='h5' className={styles.headerText}>
                        Create an account
                    </Typography>
                    <Box
                        component='form'
                        noValidate
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                        display={'flex'}
                        gap={'24px'}
                        flexDirection={'column'}
                    >
                        <FormInput
                            variant='filled'
                            label='First Name'
                            size='small'
                            sx={{}}
                        />

                        <FormInput
                            variant='filled'
                            size='small'
                            label='Last Name'
                            sx={{}}
                        />

                        <FormInput
                            variant='filled'
                            size='small'
                            label='Email'
                            sx={{}}
                        />

                        <FormInput
                            variant='filled'
                            size='small'
                            label='Password'
                            sx={{
                            }}
                        />

                        <CustomButton children={'Sign Up'} />

                        <Grid container display={'flex'} justifyContent={'center'}>
                            <Grid item>
                                <Typography fontWeight={500} className={styles.fontStyles}>
                                    Already have an account?
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    color={'rgb(95, 70, 254)'}
                                    className={styles.fontStyles}
                                    sx={{ marginLeft: '4px', cursor: 'pointer' }}
                                    onClick={() => navigate('/login')}
                                >
                                    Login here
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Checkbox {...{ checked: true }} />
                            <Grid item>
                                <Typography fontWeight={500} className={styles.fontStyles}>
                                    I accept the
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    color={'rgb(95, 70, 254)'}
                                    className={styles.fontStyles}
                                    sx={{ marginLeft: '4px', textDecoration: 'underline', cursor: 'pointer' }}
                                >
                                    Terms And Conditions
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
