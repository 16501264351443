import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Grid, TextField } from '@mui/material';
import styles from './textfeild.module.scss';

// eslint-disable-next-line react/display-name
export const CustomTextFeild = forwardRef((props: any, ref: any) => {
  props = { ...props };
  const inputRef: any = useRef(null);

  const isFilter = props.isFilter;
  delete props.isFilter;

  useImperativeHandle(ref, () => ({
    setFocus: () => {
      inputRef?.current?.focus();
    },
  }));

  return (
    <Grid>
      {props.head}
      <TextField
        className={`${styles.textbox} ${isFilter ? styles.textboxFilter : ''}`}
        id={props.id}
        label={props.label}
        type={props.type}
        required={props.isRequired}
        variant={props.variant}
        error={props.error}
        helperText={props.helperText}
        InputProps={props.InputProps}
        inputRef={inputRef}
        autoComplete='off'
        {...props}
      />
    </Grid>
  );
});
