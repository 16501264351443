import React from 'react'
import styles from "../../styles.module.scss";
import { CustomTextFeild } from '../../../../../components/atoms/TextFeild';

const formInputStyles = {
    input: {
      '&::placeholder': {
        textOverflow: 'ellipsis !important',
        color: 'rgb(94, 92, 113)',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        opacity: 1
      }
    },
    '& .MuiOutlinedInput-root': {
      padding: '0px 14px !important',
      height: '36px !important',
      width: '223px',
      borderRadius: '4px',
      boxShadow: '0px 0.9999999403953552px 1.9999998807907104px 0px rgba(16, 24, 40, 0.05)',
      backgroundColor: 'var(--gray-white, #FFF)'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgb(94, 92, 113)'
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      border: '1px solid rgb(94, 92, 113)',
      boxShadow: 'none',
      padding: 0,
      paddingBottom: '1.5px'
    },
    '& .MuiOutlinedInput-root:hover fieldset': {
      border: '1px solid rgb(94, 92, 113)'
    },
    '& .MuiInputBase-input': {
      height: '22px',
      backgroundColor: 'inherit',
      color: 'var(--gray-black, #000)',
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
      padding: 0,
      paddingBottom: '1.5px'
    },
    '& .MuiInputAdornment-root': { paddingLeft: '0px', margin: '0px' }
  };

const TextInput = ({label}: any) => {
  return (
    <div className={styles['input']}>
        {label ? <p className={styles['form-label']}>{label}:</p> : <div style={{lineHeight: '20px'}}>&nbsp;</div>}
        <CustomTextFeild 
            {...{
                sx: formInputStyles,
                placeholder: 'Click here to type...'
            }}
        />
    </div>
  )
}

export default TextInput