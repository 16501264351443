import { Node, Input, Output } from '@nodl/core';
import { Observable } from 'rxjs';

import { DataTempSchema } from "../Schema/DataTemp";


class Drop extends Node {
    name = 'Drop Column';
    uid = "operation-drop"
    inputs = {
        target: new Input({ name: 'input', type: DataTempSchema, defaultValue: {} }),
    };

    outputs = {
        data: new Output({
            name: 'output',
            type: DataTempSchema,
            observable: new Observable((subscriber) => {
                subscriber.next({ columns: [] })
            })
        })
    };

    constructor(droppedCols?: any){
        super()
        if(droppedCols){
            setTimeout(()=>{
                this.droppedCols = droppedCols
                console.log("OLP2",this.getColumns())
                console.log("OLP1",droppedCols)
                console.log("OLP",this.getColumns().filter((obj: any)=> !droppedCols.includes(obj.name)))
                this.setColumns(this.getColumns().filter((obj: any)=> !droppedCols.includes(obj.name)))
            }, 250)

        }   
    }

    droppedCols = []

    setDroppedCols(val: any){
        this.droppedCols = val
    }

    getDroppedCols(){
        return this.droppedCols
    }

    setColumns(val: Array<{ name: string, type: string }>) {
        console.log(val)
        this.outputs.data.next({ columns: val })
    }
    getColumns() {
        const val : any = this.inputs.target.value
        return val?.columns || []
    }
}

export { Drop }


