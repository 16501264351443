import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
	subscribers: [],
};

export const subscriberSlice = createSlice({
	name: 'subscribers',
	initialState,
	reducers: {
		addSubscribers: (state, action) => {
            const subs = state.subscribers || []
            console.log(subs)
            subs.push(action.payload?.data)
            state.subscribers = subs
            window.localStorage.setItem("subscribers", JSON.stringify(state.subscribers))
		},
        setSubscribers: (state, action) => {
            state.subscribers = action.payload?.data
        }
	}
});

export const {addSubscribers, setSubscribers} = subscriberSlice.actions;
export default subscriberSlice.reducer;