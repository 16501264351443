import { CANVAS_SIZE } from '../../constants';
/** Converts cartesian point to absolute coordinate system */
export const fromCartesianPoint = (width, height, x, y) => {
    const midWidth = width / 2;
    const midHeight = height / 2;
    const cartesianX = midWidth + x;
    const cartesianY = midHeight + -y;
    return { x: cartesianX, y: cartesianY };
};
/** Converts from absolute coordinate system to a cartesian system */
export const toCartesianPoint = (width, height, x, y) => {
    const midWidth = width / 2;
    const midHeight = height / 2;
    return { x: x - midWidth, y: -(y - midHeight) };
};
/** Resolves a cartesian coordinate point to an absolute coordinate, relative to the canvas size */
export const fromCanvasCartesianPoint = (x, y) => fromCartesianPoint(CANVAS_SIZE, CANVAS_SIZE, x, y);
/** Resolves an absolute coordinate point to a cartesian coordinate, relative to the canvas size */
export const toCanvasCartesianPoint = (x, y) => toCartesianPoint(CANVAS_SIZE, CANVAS_SIZE, x, y);
