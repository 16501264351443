"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.schema = void 0;
function schema(...args) {
    if (arguments.length > 1) {
        const [name, schema] = args;
        return {
            name,
            validator: schema
        };
    }
    else {
        const [schema] = args;
        return {
            name: schema.constructor.name.replace('Zod', ''),
            validator: schema
        };
    }
}
exports.schema = schema;
