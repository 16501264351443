import { CircuitStore, Circuit } from "@nodl/react";
import { Node, Input, Output, schema } from '@nodl/core';
import { Observable, map } from 'rxjs';

import { DataTempSchema } from "../Schema/DataTemp";


class Split  extends Node {
    name = 'Split Columns';
    uid = "operation-split"
    inputs = {
        target: new Input({ name: 'input', type: DataTempSchema, defaultValue: {} }),
    };

    outputs = {
        data: new Output({
            name: 'output',
            type: DataTempSchema,
            observable: new Observable((subscriber) => {
                subscriber.next({  columns: [] })
            })
        })
    };

    output_ : any = []
    
    setOutput(val: any) {
        this.output_ = val
        let k : any = {}
        let j : any = []

        for(let x of val){
            k[x[0]] = true
            j.push(x[1])
            j.push(x[2])
        }
        let res = this.getColumns()

        for(let i=0;i<res.length;i++) {
            if(k.hasOwnProperty(res[i].name))
            {
              continue
            }else{
                j.push(res[i])
            }
        }
        this.outputs.data.next({ columns: j })
    }

    getColumns() {
        const val : any = this.inputs.target.value
        return val?.columns || []
    }
}

export { Split }


