import { CircuitStore, Circuit } from "@nodl/react";
import { Node, Input, Output, schema } from '@nodl/core';
import { Observable, map } from 'rxjs';

import { DataTempSchema } from "../Schema/DataTemp";


class Rename extends Node {
    name = 'Rename Columns';
    uid = "operation-rename"
    inputs = {
        target: new Input({ name: 'input', type: DataTempSchema, defaultValue: {} }),
    };

    outputs = {
        data: new Output({
            name: 'output',
            type: DataTempSchema,
            observable: new Observable((subscriber) => {
                subscriber.next({  columns: [] })
            })
        })
    };

    output_ : any = []
    
    setOutput(val: any) {
        this.output_ = val
        let d : any = {}
        for(let x of val){
            d[x[0]] =x[1]
        }
        let res = this.getColumns()
        let j : any = []
        for(let i=0;i<res.length;i++) {
            if(d.hasOwnProperty(res[i].name))
            {
                
                j.push({name: d[res[i].name], type: res[i].type })
            }else{
                j.push(res[i])
            }
        }
        this.outputs.data.next({ columns: j })
    }

    getColumns() {
        const val : any = this.inputs.target.value
        return val?.columns || []
    }
}

export { Rename }


