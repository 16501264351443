
import { Bar, Chart } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);
export const DetailedLineGraph = () => {
    const getData = () => {
        const labels: any = [['Jan 2023'], ['Feb 2023'], ['Mar 2023'], ['Apr 2023'], ['May 2023'], ['Jun 2023']]
        const datasets: any = [{
            label: 'Impressions',
            data: [50000, 10000, 25000, 23000, 40000, 40000],
            fill: false,
            borderColor: '#85D8DD',
            tension: 0.1,
            backgroundColor: ['#85D8DD'],
            yAxisID: 'y',
        },
        {
            label: 'Attribute Sales',
            data: [10000, 70000, 50000, 55000, 22000, 40000],
            fill: false,
            borderColor: '#8B97FF',
            tension: 0.1,
            backgroundColor: ['#8B97FF'],
            yAxisID: 'y1',
        }]
        return {
            labels: labels,
            datasets: datasets
        }
    }

    const toptions:any = {
        chart: {
            type: 'bar',
            height: 350
        },
        responsive: true,
        stacked: false,
        colors: ["#85D8DD", "#427ae3", "#3dc7d1", '#faa93e'],
        fill: {
            colors: ["#85D8DD", "#427ae3", "#3dc7d1", '#faa93e']
        },
        dataLabels: {
            enabled: false,
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                  },
            },
            datalabels: {
                display: false,
            }
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        scales: {
            y: {
                min: 0,
                position: 'left',
                title: {
                    display: true,
                    text: 'Impressions',
                    color: 'black',
                    fontWeight: 700,
                    padding: 5
                },
                ticks: {
                    // stepSize: 1000// <----- This prop sets the stepSize
                }
            },
            y1: {
                min: 0,
                display: true,
                position: 'right',
                title: {
                    display: true,
                    text: 'Attribute Sales',
                    color: 'black',
                    fontWeight: 700,
                    padding: 5
                },
              },
            x: {
                barPercentage: 0.5,
                categorySpacing: 0,
                stacked: true,
                grid: {
                    display: false,
                },
            },
        },
        tooltip: {
            y: {
                formatter: function (context: any) {
                    if (typeof context === 'number') {
                        return context;
                    } else {
                        return context[1] - context[0]
                    }
                }
            }
        },
    };
    return (
        <div>
            <Chart type='line' data={getData()} width={'100%'} height={'29px'} options={{
                ...toptions
            }} />
        </div >
    )
}