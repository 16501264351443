import "./index.scoped.scss";
import { useCallback, useEffect } from "react";
import { SideLayout } from "./sideLayout";
import { CircuitStore, Circuit } from "@nodl/react";
import { Connector } from "./Nodes/connector";
import { Join } from "./Nodes/join";
import { Drop } from "./Nodes/drop";
import { Rename } from "./Nodes/rename";
import { TypeConvertor } from "./Nodes/typeConvertor";
import { RightSideLayout } from "./rightSideLayout";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { CsvInitDialog } from "./Components/CsvInitDialog";
import { TransactionOutput } from "./Nodes/out_transaction";
import { UserOutput } from "./Nodes/out_user";
import SubtabsHeader from "../SubtabsHeader";
import { Split } from "./Nodes/split";
import AiMessage from "./Components/AiMessage";
import { toast } from "react-toastify";
import useOutsideClick from "../../../hooks/useOutsideClick";
import AiMessageExport from "./Components/AiMessageExport";

const store = new CircuitStore();

export const DataAnimator = () => {
  const [nodeMenu, setNodeMenu] = useState("");
  const [dialogId, setDialoigId] = useState("");
  const [flowId, setFlwoId] = useState(0);
  const [aiMsg, setaiMsg] = useState(false);
  const [columnNames_, setColumnNames] = useState([]);
  const [name, setName] = useState("");
  const [prevNode, setPrevNode] = useState("");
  const [showExport, setShowExport] = useState(false)
  return (
    <div style={{ display: "flex" }}>
      <AiMessageExport open={showExport} handleClose={()=> {
        setShowExport(true)
      }}/>
      <AiMessage
        open={aiMsg}
        flowId={flowId}
        handleClose={() => {
          if (flowId == 0) {
            setFlwoId(1);
            setaiMsg(false);
            //store.dispose();
          } else if (flowId == 1) {
            setFlwoId(2);
            setaiMsg(false);
            //store.dispose();
            const conn = new Connector(name, columnNames_);
            const drop = new Drop(["EmailId", "PhoneNumber"]);
            const typeConvertor = new TypeConvertor(["Joined On", "Age"]);
            const usersOutput = new UserOutput({
              FName: "Name",
              LName: "Surname",
              Gender: "Gender",
              Age: "Age",
              CreationTime: "Joined On",
            });
            store.setNodes([[conn, { x: -600, y: 0 }]]);
            store.setNodes([[drop, { x: -200, y: 0 }]]);
            store.setNodes([[typeConvertor, { x: 100, y: 0 }]]);
            store.setNodes([[usersOutput, { x: 400, y: 0 }]]);
            //@ts-ignore
            conn.outputs.output.connect(drop.inputs.target);
            //@ts-ignore
            drop.outputs.data.connect(typeConvertor.inputs.target);
            //@ts-ignore
            typeConvertor.outputs.data.connect(usersOutput.inputs.source);
          } else if (flowId == 2) {
            setaiMsg(false);
            setFlwoId(3);
            //store.dispose();
            const conn = new Connector(name, columnNames_);
            const typeConvertor = new TypeConvertor(["ProductId"]);
            const transactionOutput = new TransactionOutput({
              ProductId: "ProductId",
              Amount: "Amount",
              "Brand Name": "",
              "Product Name": "",
              Qunatity: "",
              User: "UserId",
              "Payement Method": "TransactionMode",
              "Delivery Mode": "",
            });
            store.setNodes([[conn, { x: -600, y: 400 }]]);
            store.setNodes([[typeConvertor, { x: 100, y: 400 }]]);
            store.setNodes([[transactionOutput, { x: 400, y: 400 }]]);
            //@ts-ignore
            conn.outputs.output.connect(typeConvertor.inputs.target);
            //@ts-ignore
            typeConvertor.outputs.data.connect(transactionOutput.inputs.source);
            setPrevNode(name);
          } else if (flowId == 3) {
            setaiMsg(false);
            setFlwoId(4);
            const t1 = store.nodes.filter(
              (val: any) => val.nodeRedableName == prevNode
            )[0];
            let typec: any = null;
            let tout: any = null;
            for (let node of store.nodes) {
              for (let input of Object.values(node.inputs)) {
                console.log("jira1", input.id);
                console.log("jira2", t1.outputs.output.connections[0].to.id);
                if (input.id == t1.outputs.output.connections[0].to.id) {
                  typec = node;
                }
              }
            }
            for (let node of store.nodes) {
              for (let input of Object.values(node.inputs)) {
                console.log("jira1", input.id);
                console.log("jira2", typec.outputs.data.connections[0].to.id);
                if (input.id == typec.outputs.data.connections[0].to.id) {
                  tout = node;
                }
              }
            }

            //const typec = store.nodes.filter((val:any) =>val.inputs. )

            //@ts-ignore
            console.log("wola", typec);
            //@ts-ignore
            tout.setVal({
              ProductId: "ProductId",
              Amount: "Amount",
              "Brand Name": "BrandName",
              "Product Name": "ProductName",
              User: "UserId",
              "Payement Method": "TransactionMode",
            });
            const conn = new Connector(name, columnNames_);
            const join = new Join(true, true);
            store.setNodes([[conn, { x: -600, y: 200 }]]);
            store.setNodes([[join, { x: -300, y: 400 }]]);

            t1.outputs.output.connect(join.inputs.left);
            //@ts-ignore
            conn.outputs.output.connect(join.inputs.right);
            //@ts-ignore
            join.outputs.data.connect(typec.inputs.target);
            typec.setOutput([["ProductId ", "String", "Number"]]);

            setPrevNode(name);
          }
        }}
      />
      <CsvInitDialog
        flowId={flowId}
        open={dialogId == "csv-init"}
        handleClose={() => setDialoigId("")}
        handleAdd={(name: string, columnNames: any) => {
          //const conn = new Connector(name, columnNames);
          // store.setNodes([[conn, { x: 0, y: 0 }]]);
          setColumnNames(columnNames);
          setName(name);
          setDialoigId("");
          if (flowId == 0) {
            setFlwoId(1);
          } else if (flowId == 1) {
            setFlwoId(2);
          }
          setaiMsg(true);
        }}
      />
      <SideLayout
      onExport={()=> {
        setShowExport(true)
      }}
        flowId={flowId}
        onAdd={(id: string, x: number, y: number) => {
          switch (id) {
            case "data-connector":
              setDialoigId("csv-init");
              break;
            case "drop-column":
              const drop = new Drop();
              store.setNodes([[drop, { x, y }]]);
              break;
            case "join-tables":
              const join = new Join();
              store.setNodes([[join, { x, y }]]);
              break;
            case "rename-column":
              const rename = new Rename();
              store.setNodes([[rename, { x, y }]]);
              break;
            case "type-convert":
              const typeConvertor = new TypeConvertor();
              store.setNodes([[typeConvertor, { x, y }]]);
              break;
            case "operation-split":
              const splitNode = new Split();
              store.setNodes([[splitNode, { x, y }]]);
              break;
            case "output-transaction":
              const transactionOutput = new TransactionOutput();
              store.setNodes([[transactionOutput, { x, y }]]);
              break;
            case "output-user":
              const usersOutput = new UserOutput();
              store.setNodes([[usersOutput, { x, y }]]);
              break;
          }
        }}
      />
      <div className="absolute  w-full z-[100]" style={{ left: 260 }}>
        <SubtabsHeader />
      </div>
      <section className={"home-section"}>
        <Circuit
          className="canvas"
          store={store}
          nodeWindowResolver={useCallback((node: any) => {
            if (node instanceof Connector) {
              return (
                <div className="bg-[#242736] pt-2 px-2 text-white text-md font-bold">
                  {node.nodeRedableName}
                </div>
              );
            }
          }, [])}
          onSelectionChanged={(nodes) => {
            setNodeMenu("");
            if (nodes.length == 1) {
              const node = nodes[0];
              setNodeMenu(node.id);
            }
          }}
          onNodeRemoval={() => {
            setNodeMenu("");
          }}
        />
      </section>
      {nodeMenu == "" ? (
        <></>
      ) : (
        <RightSideLayout nodeId={nodeMenu} store={store} />
      )}
    </div>
  );
};
