import { Grid } from '@mui/material';
import { VennDiagram } from './components/venndiagrams';
import Select from '../../../../../../components/atoms/Select';
import { useEffect, useState } from 'react';
import { getOverlap, getOverlapChartData } from '../../../../../../services/insights';
import Loader from '../../../../../../components/atoms/loader';
import minus from '../../../../../../assets/svg/minus.svg'
import { useSelector } from 'react-redux';
import { getOverlapBrandData, getOverlapData } from '../../../../../../services/insights';
import InfiniteSelect from '../../../elements/InfiniteSelect';

export const selectStyles = {
    color: '#fff !important',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    borderRadius: '8px',
    paddingTop: '3px',
    width: '100%',
    height: '36px',
    border: 'none',
    background: '#FFF !important',
    '& .MuiOutlinedInput-notchedOutline': {

        border: '1px solid rgb(94, 92, 113)',
        color: 'rgb(94, 92, 113)'

    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgb(94, 92, 113)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgb(94, 92, 113)',
    },
    '& .MuiSelect-outlined': {
        color: 'rgb(94, 92, 113)'
    },
}
export const menustyles = {
    borderRadius: '9px',
    border: '1px solid rgba(191, 191, 191, 0.25)',
    background: ' #FFF',
    '& .MuiMenuItem-root': {
        color: '#5F46FF',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        minwidth: '100%',
        borderRadius: '8px',
        paddingTop: '3px',
    },
}
export const Overlap = ({ filter }: any) => {
    const [type, setType] = useState('brand_name')
    const [brands, setBrands] = useState<any>([])
    const [finalData, setFinalData] = useState([])
    const [brand, setBrand] = useState<any>()
    const [data, setData] = useState<any>([]);
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(0);

    const delivery = useSelector((state: any) => state?.sidebarContext?.delivery);
    const location = useSelector((state: any) => state?.sidebarContext?.location);
    const age_group = useSelector((state: any) => state?.sidebarContext?.age_group);
    const products = useSelector((state: any) => state?.sidebarContext?.products);

    const getData = async ({ brand, filter, based_on }: any) => {
        setLoader(true)
        const data = await getOverlapData({ based_on: based_on, brand: brand, timeline: filter.timeline, products, location, age_group, delivery })
        setLoader(false)
        return data
    }

    const getBrandsData = async () => {
        const data: any = await getOverlapBrandData('', page)
        const options = data?.results.map((time: string) => {
            return {
                label: time,
                value: time
            }
        })
        setBrands([{ label: 'FOOD_CLUB', value: 'FOOD_CLUB' },
        { label: 'FOOD_LION', value: 'FOOD_LION' },/* ...options,*/])
    }

    const getFinalData = async () => {
        const datafinal: any = await getData({ brand: data.map((item: any) => item.id), filter, based_on: type })
        return datafinal
    }

    useEffect(() => {
        (async () => {
            if (data.length > 1) {
                const data: any = await getFinalData()
                setFinalData(data)
            }
        })();
    }, [data, filter, location, age_group, products, age_group, delivery])

    useEffect(() => {
        getBrandsData()
    }, [page])

    const handleChange = (e: any) => {
        if (e.target.name == 'type') {
            setType(e.target.value)
        } else {
            setBrand(e.target.value)
        }
    }

    const handleChangeBrand = (e: any) => {
        setBrand(e)
    }

    const handleRemoveDataItems = (item: any) => {
        const filterData = data?.filter((param: any) => param.name !== item?.name);
        setData(filterData);
    }

    const handleAdd = () => {
        setData([...data, { name: brand, id: brand }])
        setBrand('');
    }

    const handleNextData = () => {
        setPage(page + 1)
    }

    return (
        <>
            <div>
                <Grid container>
                    <Grid xl={9.5} lg={9} display={"flex"} width={'100%'} flexDirection={'column'} sx={{ borderRight: '1px solid rgb(229, 225, 236)', padding: '16px' }}>
                        {<> {!loader ? <VennDiagram {...{ data: finalData, brandsData: data }} /> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Loader />
                        </div>}</>}
                    </Grid>
                    <Grid xl={2.5} lg={3}>
                        <Grid sx={{ padding: '16px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                            <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                <p style={{ fontSize: '14px', fontWeight: 500 }}>Parameter</p>
                                <Select
                                    value={type}
                                    name='type'
                                    options={[{
                                        label: 'Brand', value: 'brand_name'
                                    }]}
                                    onChange={(e: any) => handleChange(e)}
                                    variant="outlined"
                                    size='small'
                                    multiple={false}
                                    optionsType="default"
                                    sx={{
                                        ...selectStyles
                                    }}
                                    Menustyle={{
                                        ...menustyles
                                    }}
                                />
                            </Grid>
                            <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                <p style={{ fontSize: '14px', fontWeight: 500 }}>Select Brand</p>
                                {/* <Select
                                    value={brand}
                                    options={brands}
                                    onChange={(e: any) => handleChange(e)}
                                    variant="outlined"
                                    size='small'
                                    multiple={false}
                                    optionsType="default"
                                    sx={{
                                        ...selectStyles
                                    }}
                                    Menustyle={{
                                        ...menustyles
                                    }}
                                /> */}
                                <InfiniteSelect 
                                    handleNextData={handleNextData}
                                    data={brands}
                                    label={brand}
                                    page={page}
                                    setPage={setPage}
                                    handleMenuItemClick={handleChangeBrand}
                                />
                            </Grid>
                            <p style={{
                                textDecoration: 'underline', color: 'rgb(95, 70, 254)', fontSize: '14px', fontWeight: 600, display: 'flex', justifyContent: 'end', cursor: "pointer",
                                pointerEvents: brand === '' ? 'none' : 'all',
                                opacity: brand === '' ? 0.5 : 1
                            }} onClick={handleAdd}>+ Add</p>
                        </Grid>

                        <Grid mt={3} borderBottom={'1px solid rgb(229, 225, 236)'}>
                            {data.map((item: any) => {
                                return (
                                    <Grid borderTop={"1px solid rgb(229, 225, 236)"}>
                                        <Grid display={'flex'} justifyContent={"space-between"} alignItems={"center"} padding={"14px"}>
                                            <Grid display={'flex'} justifyContent={"center"} alignContent={"center"} gap={'16px'}>
                                                <input type="checkbox" checked={true} />
                                                <h2 style={{ fontSize: '12px', fontFamily: 'Poppins' }}>{item.name}</h2>
                                            </Grid>
                                            <img onClick={() => handleRemoveDataItems(item)} style={{ cursor: "pointer" }} src={minus} alt='' />
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </div></>
    )
}