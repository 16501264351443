import React from 'react'
import styles from "../../styles.module.scss";

const DateInput = ({label}: any) => {
  return (
    <div className={styles['input']}>
        {label ? <p className={styles['form-label']}>{label}:</p> : <div style={{lineHeight: '20px'}}>&nbsp;</div>}
        <input 
            type="date"
            className={styles['input-date']}
            placeholder='Start date'
        />
    </div>
  )
}

export default DateInput