import { useEffect, useState } from "react"
import { getBestSellers, getOverlap, getOverlapChartData, getUtilsData,getDashboardTreeData  } from "../../../services/insights";
import { timeline } from "../../../constants/utils";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ceilNumber, addComma } from "../Monetise/useDashboard";

export const useInsights = () => {
    const [data, setdata] = useState<any>([]);
    const [row, setRow] = useState<any>({});
    const [loader, setLoader] = useState(false);
    const [treeData, setTreeData ] = useState<any>({});
    const [chartLoader, setChartLoader] = useState<boolean>(false);
    const [filter, setFilter] = useState({
        based_on: 'product_category',
        timeline: '15 days',
        volume: 'amount'
    })
    const [page, setPage] = useState(1)
    const [detailed, setDetailed] = useState<boolean>(false);
    const [timeLine, setTimeLines] = useState<any>(timeline?.map((time: string) => {
        return {
            label: time,
            value: time
        }
    }))
    const loc = useLocation()
    const category = useSelector((state: any) => state?.sidebarContext?.category);
    const delivery = useSelector((state: any) => state?.sidebarContext?.delivery);
    const location = useSelector((state: any) => state?.sidebarContext?.location);
    const age_group = useSelector((state: any) => state?.sidebarContext?.age_group);
    const products = useSelector((state: any) => state?.sidebarContext?.products);

    const getBestSellersData = async () => {
        setLoader(true)
        const response: any = await getBestSellers({ ...filter, page_size: 25, page, delivery, location, age_group, products,category });
        setLoader(false)
        setdata(response)
    }

    useEffect(() => {
        if(loc.pathname =='/insights/best-sellers'){
            getBestSellersData()
        }
    }, [filter, page, delivery, location, age_group, products,category]);

    const getTimeLines = async () => {

        return [];
    }

    const getBrands = async () => {
        const response: any = await getUtilsData('brand_name/?offset=0&limit=10');
        return response;
    }


    const getSkus = async () => {
        const response: any = await getUtilsData('unique_values?column=promo_code');
        return response;
    }

    const getTreeStructureData = async (rowData:any) => {
        try {
            setChartLoader(true);
          const response: any = await getDashboardTreeData({ 
            products: [rowData?.product],
            lastKey: [rowData?.timestamp]
        });
        const items = response?.[rowData?.product?.toLowerCase()]?.[0];
        const orgChart: any = {
            name: "Total revenue",
            value: `$ ${addComma(ceilNumber(items?.total_revenue))}`,
            children: [
              {
                name: "Revenue/customer",
                value: `$ ${addComma(ceilNumber(items?.revenue_per_customers))}`,
                children: [
                  {
                    name: "Revenue/purchase",
                    value: `$ ${addComma(ceilNumber(items?.revenue_per_purchase))}`,
                    children: [
                    ],
                  },
                  {
                    name: "Orders/customer",
                    value: `${addComma(ceilNumber(items?.orders_per_customer))}`,
                    children: [
                      {
                        name: "Avg price/purchase",
                        value: `$ ${addComma(ceilNumber(items?.average_unit_price))}`,
                      },
                      {
                          name: "Units/purchase",
                          value: `${addComma(ceilNumber(items?.revenue_per_purchase))}`,
                      },
                    ],
                  },
                ],
              },
              {
                  name: "Customers",
                  value: `${items.total_active_customers}`,
                  children: [
          
                  ]
              }
            ],
          };
          setTreeData(orgChart || {});
          setChartLoader(false)
        } catch (error) {
            setChartLoader(true);
            const orgChart: any = {
                name: "Total revenue",
                value: `$ 0`,
                children: [
                  {
                    name: "Revenue/customer",
                    value: `$ 0`,
                    children: [
                      {
                        name: "Revenue/purchase",
                        value: `$ 0`,
                        children: [
                        ],
                      },
                      {
                        name: "Orders/customer",
                        value: `0`,
                        children: [
                          {
                            name: "Avg price/purchase",
                            value: "$ 0"
                          },
                          {
                              name: "Units/purchase",
                              value: "$ 0"
                          },
                        ],
                      },
                    ],
                  },
                  {
                      name: "Customers",
                      value: `0`,
                      children: [
              
                      ]
                  }
                ],
              };
              setTreeData(orgChart || {});
              setChartLoader(false);
          console.log("error", error)
        }
    }

    const handleRowClick = (row: any) => {
        setRow(row);
        setDetailed(true);
        getTreeStructureData(row);
    }

    const handleBack = () => {
        setRow({});
        setDetailed(false);
    }

    const handleChange = ({ target: { name, value } }: any) => {
        let updatedData: any = { ...filter };
        updatedData[name] = value;
        setFilter(updatedData);
    };

    return {
        data,
        handleRowClick,
        row,
        detailed,
        setDetailed,
        handleBack,
        handleChange,
        filter,
        setPage,
        page,
        getTimeLines,
        getBrands,
        timeLine,
        loader,
        getSkus,
        treeData,
        chartLoader
    }
}