//@ts-nocheck
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
const grid = 8;

const getItemStyle = (draggableStyle: any, isDragging: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  color: isDragging ? "white" : "",
  background: isDragging ? "#8e2de2" : "",
  textAling: "center",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: any) => ({});

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function DragableList({ items_ }: any) {
  const [items, setItems] = useState(items_);
  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items__ = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(items__);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            {...provided.droppableProps}
          >
            {items.map((item: any, index: any) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div>
                    <div
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        provided.draggableProps.style,
                        snapshot.isDragging
                      )}
                      className="bg-gray-100 rounded-lg p-2 py-2 my-4 shadow-xl"
                    >
                      <details>
                        <summary> {item.component}</summary>
                        <div className="my-3">
                          <FormGroup>
                            {item.checkboxList.map((val: any, i: any) => {
                              return (
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label={val}
                                  key={i}
                                />
                              );
                            })}
                          </FormGroup>
                        </div>
                      </details>
                    </div>
                    
                    {provided.placeholder}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
