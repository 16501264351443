import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import Draggable from 'react-draggable';
import { NODE_POSITION_OFFSET_X } from '../../constants';
import { useHover } from '../../hooks/useHover/useHover';
import { StoreContext } from '../../stores/CircuitStore/CircuitStore';
import { fromCanvasCartesianPoint } from '../../utils/coordinates/coordinates';
import { Port } from '../Port/Port';
import { nodeHeaderWrapperStyles, nodeContentWrapperStyles, nodeWrapperStyles, nodePortsWrapperStyles, nodeHeaderActionsStyles, nodeActionStyles, nodeHeaderNameWrapperStyle, nodeWindowWrapperStyles } from './Node.styles';
export const Node = observer(({ node, actions, window }) => {
    var _a;
    const ref = React.useRef(null);
    const { onMouseEnter, onMouseLeave, isHovered } = useHover();
    const { store } = React.useContext(StoreContext);
    React.useEffect(() => {
        if (ref.current) {
            store.setNodeElement(node.id, ref.current);
            return () => {
                store.removeNodeElement(node.id);
            };
        }
    }, [ref]);
    const handleOnClick = React.useCallback((e) => {
        var _a;
        if (!((_a = store.selectedNodes) === null || _a === void 0 ? void 0 : _a.includes(node))) {
            store.selectNodes([node]);
        }
    }, [node]);
    const handleOnFocus = React.useCallback(() => {
        var _a;
        if (!((_a = store.selectedNodes) === null || _a === void 0 ? void 0 : _a.includes(node))) {
            store.selectNodes([node]);
        }
    }, [node]);
    const handleOnDrag = React.useCallback((e, { deltaX, deltaY }) => {
        var _a, _b;
        e.preventDefault();
        e.stopPropagation();
        for (const selectedNode of store.selectedNodes || []) {
            store.setNodePosition(selectedNode.id, {
                x: (((_a = store.nodePositions.get(selectedNode.id)) === null || _a === void 0 ? void 0 : _a.x) || 0) + deltaX,
                y: (((_b = store.nodePositions.get(selectedNode.id)) === null || _b === void 0 ? void 0 : _b.y) || 0) + -deltaY
            });
        }
    }, [node]);
    const handleRemoveNode = React.useCallback(() => {
        node.dispose();
        store.removeNode(node.id);
    }, [node]);
    const active = ((_a = store.selectedNodes) === null || _a === void 0 ? void 0 : _a.indexOf(node)) !== -1;
    const position = store.nodePositions.get(node.id) || { x: 0, y: 0 };
    return (_jsx(Draggable, Object.assign({ nodeRef: ref, position: fromCanvasCartesianPoint(position.x - NODE_POSITION_OFFSET_X, position.y), onDrag: handleOnDrag, handle: ".handle" }, { children: _jsxs("div", Object.assign({ ref: ref, css: nodeWrapperStyles(active), onClick: handleOnClick, onFocus: handleOnFocus, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, tabIndex: 0 }, { children: [_jsxs("div", Object.assign({ css: nodeHeaderWrapperStyles(active), className: 'handle' }, { children: [_jsx("div", Object.assign({ css: nodeHeaderNameWrapperStyle }, { children: _jsx("span", { children: node.name }) })), _jsx("div", Object.assign({ css: nodeHeaderActionsStyles(isHovered || active) }, { children: _jsx(NodeAction, { color: "#ff4444", onClick: handleRemoveNode }) }))] })), window ? _jsx("div", { css: nodeWindowWrapperStyles, children: window }) : undefined, _jsxs("div", Object.assign({ css: nodeContentWrapperStyles }, { children: [_jsx(NodePorts, { ports: Object.values(node.inputs) }), _jsx(NodePorts, { ports: Object.values(node.outputs), isOutputWrapper: true })] }))] })) })));
});
const NodeAction = ({ color = '#fff', onClick }) => {
    return _jsx("div", { css: nodeActionStyles(color), color: color, onClick: onClick });
};
const NodePorts = ({ ports, isOutputWrapper }) => {
    return (_jsx("div", Object.assign({ css: nodePortsWrapperStyles(isOutputWrapper) }, { children: ports.map(port => (_jsx(Port, { port: port, isOutput: !!isOutputWrapper }, port.id))) })));
};
