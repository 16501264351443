import { isEmpty, isEqual, xorWith } from 'lodash';
import { autorun, makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import { NODE_CENTER } from '../../constants';
import { normalizeBounds, withinBounds } from '../../utils/bounds/bounds';
import { fromCanvasCartesianPoint } from '../../utils/coordinates/coordinates';
export class CircuitStore {
    constructor() {
        /** Associated Nodes */
        this.nodes = [];
        /** Associated Node Elements */
        this.nodeElements = new Map();
        /** Node Positions */
        this.nodePositions = new Map();
        /** Associated Port Elements */
        this.portElements = new Map();
        /** Selected Nodes */
        this.selectedNodes = [];
        /** Draft Connection Source */
        this.draftConnectionSource = null;
        /** Selection bounds */
        this.selectionBounds = null;
        /** Mouse Position */
        this.mousePosition = { x: 0, y: 0 };
        makeAutoObservable(this);
        this.selectionBoundsDisposer = this.onSelectionBoundsChange();
    }
    /** All associated connections */
    get connections() {
        return this.nodes
            .flatMap(node => node.connections)
            .filter((value, index, self) => self.indexOf(value) === index);
    }
    /** Sets the associated nodes */
    setNodes(nodesWithPosition) {
        for (const [node, position] of nodesWithPosition) {
            this.nodes.push(node);
            this.nodePositions.set(node.id, position);
        }
    }
    /** Removes a node from the store */
    removeNode(nodeId) {
        this.nodes = this.nodes.filter(node => node.id !== nodeId);
        this.nodeElements.delete(nodeId);
        this.nodePositions.delete(nodeId);
    }
    /** Associates a given Node instance with an HTML Element */
    setNodeElement(nodeId, portElement) {
        this.nodeElements.set(nodeId, portElement);
    }
    /** Clears a given Node's associated HTML Element from store */
    removeNodeElement(nodeId) {
        this.nodeElements.delete(nodeId);
    }
    /** Associates a given Input or Output instance with an HTML Element */
    setPortElement(portId, portElement) {
        this.portElements.set(portId, portElement);
    }
    /** Clears a given Input's or Output's associated HTML Element from store */
    removePortElement(portId) {
        this.portElements.delete(portId);
    }
    /** Sets an Output as the current draft connection source */
    setDraftConnectionSource(source) {
        this.draftConnectionSource = source;
    }
    /** Sets an Output as the current draft connection source */
    commitDraftConnection(target) {
        if (this.draftConnectionSource) {
            const connection = this.draftConnectionSource.connect(target);
            this.setDraftConnectionSource(null);
            return connection;
        }
    }
    /** Selects the given nodes */
    selectNodes(nodes) {
        this.selectedNodes = nodes;
    }
    /** Sets the selection bounds */
    setSelectionBounds(bounds) {
        this.selectionBounds = bounds;
    }
    /** Sets the mouse position */
    setMousePosition(mousePosition) {
        this.mousePosition = mousePosition;
    }
    /** Sets a node's position */
    setNodePosition(nodeId, position) {
        this.nodePositions.set(nodeId, position);
    }
    /** Remove a node's position */
    removeNodePosition(nodeId) {
        this.nodePositions.delete(nodeId);
    }
    /** Returns the node with the associated port */
    getNodeByPortId(portId) {
        return this.nodes.find(node => {
            return [...Object.values(node.inputs), ...Object.values(node.outputs)].some(port => port.id === portId);
        });
    }
    /** Disposes the store by cleaning up effects */
    dispose() {
        this.nodes = [];
        this.nodeElements.clear();
        this.nodePositions.clear();
        this.portElements.clear();
        this.selectedNodes = [];
        this.selectionBounds = null;
        this.draftConnectionSource = null;
        this.mousePosition = { x: 0, y: 0 };
        this.selectionBoundsDisposer();
    }
    /** Automatically selects the nodes which are within the selection bounds */
    onSelectionBoundsChange() {
        return autorun(() => {
            if (this.selectionBounds) {
                const bounds = normalizeBounds(this.selectionBounds);
                const selectionCandidates = [];
                for (const node of this.nodes) {
                    const nodeElement = this.nodeElements.get(node.id);
                    if (nodeElement) {
                        const nodeRect = nodeElement.getBoundingClientRect();
                        const nodePosition = this.nodePositions.get(node.id);
                        if (nodePosition &&
                            withinBounds(bounds, Object.assign(Object.assign({}, fromCanvasCartesianPoint(nodePosition.x - NODE_CENTER, nodePosition.y)), { width: nodeRect.width, height: nodeRect.height }))) {
                            selectionCandidates.push(node);
                        }
                    }
                }
                if (!isEmpty(xorWith(this.selectedNodes, selectionCandidates, isEqual))) {
                    this.selectNodes(selectionCandidates);
                }
            }
        });
    }
}
const defaultStoreProviderValue = {
    store: new CircuitStore()
};
export const StoreContext = createContext(defaultStoreProviderValue);
