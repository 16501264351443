"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = void 0;
const mobx_1 = require("mobx");
const rxjs_1 = require("rxjs");
const uuid_1 = require("uuid");
class Input extends rxjs_1.BehaviorSubject {
    constructor(props) {
        super(props.defaultValue);
        /** Identifier */
        this.id = (0, uuid_1.v4)();
        this.name = props.name || 'Untitled';
        this.type = props.type;
        this.defaultValue = props.defaultValue;
        this.connection = null;
        (0, mobx_1.makeObservable)(this, {
            id: mobx_1.observable,
            name: mobx_1.observable,
            type: mobx_1.observable,
            defaultValue: mobx_1.observable,
            connection: mobx_1.observable,
            connected: mobx_1.computed,
            dispose: mobx_1.action
        });
    }
    /** Determines if input is connected */
    get connected() {
        return !!this.connection;
    }
    /** Disposes the Input */
    dispose() {
        var _a;
        (_a = this.connection) === null || _a === void 0 ? void 0 : _a.dispose();
        this.connection = null;
        this.unsubscribe();
    }
}
exports.Input = Input;
