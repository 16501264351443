import { Grid } from '@mui/material';
import TabPanel from '../../../../../components/atoms/Tabpanel/index';
import { ReactComponent as Tableview } from '../../../../../assets/svg/tableview.svg'
import { ReactComponent as GraphView } from '../../../../../assets/svg/lineGraph.svg'

const Tabs = ({ styles,selTab,setSelTab, detailed }: any) => {
    const tabs: Array<any> = [
        { name: '1', icon: <GraphView fill={selTab == '1' ? 'white' : '#5B47F5'} />, id: 0, value: "graph", hide: true, style:{borderRadius:'10px 0px'} },
        { name: '2', icon: <Tableview fill={selTab == '2' ? 'white' : '#5B47F5'} />, id: 1, value: "grid", hide: true}
    ];

    const handleType = (item: any) => {
        setSelTab(item.name)
    }

    return (
        <Grid
            item
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignSelf: 'stretch',
            }}
        >
            <Grid item display={'flex'} justifyContent={'start'} alignItems={'center'} style={{marginTop:'12px'}}>
                <TabPanel
                    data-testid='view-process-tabs'
                    {...{
                        tabs: tabs,
                        selectedTab: selTab,
                    }}
                    variant='standard'
                    textColor='primary'
                    handleTab={(item: any) => handleType(item)}
                    sx={{
                        // marginTop: '5px',
                        "& .MuiTabs-flexContainer": {
                            display: "flex",
                            flexDirection: "row",
                            margin: 0,
                            padding: 0,
                        },
                        "& .MuiToggleButtonGroup-root": {
                            padding: 0,
                            margin: 0,
                            height: '40px'
                        },
                        "& .MuiBox-root": {
                            padding: "0px",
                            margin: "0px",
                        },
                        "& .MuiToggleButton-root": {
                            fontSize: "12px",
                            paddingRight: '10px',
                            paddingLeft: '10px',
                            background: 'white',
                            borderRadius:selTab==='1'?'0px 8px 8px 0px': '8px 0px 0px 8px',
                            height:'37px'
                        },
                        "& .Mui-selected.MuiToggleButton-root": {
                            backgroundColor: "rgb(95, 70, 254)",
                            color: "black",
                            borderRadius:selTab==='1'?'8px 0px 0px 8px': '0px 8px 8px 0px',
                            height:'37px'
                        },
                        "& .Mui-selected.MuiToggleButton-root:hover": {
                            backgroundColor: "rgb(95, 70, 254)",
                            color: "black",
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default Tabs;
