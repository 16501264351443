import React from "react";
import SubtabsHeader from "../SubtabsHeader";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

const NBtn = styled(Button)({
  backgroundColor: "#5F46FF",
  color: "white",
  ":hover": {
    backgroundColor: "#5F46FF",
  },
});

export default function ContentManager() {
  return (
    <div className="bg-white h-[100vh]">
      <div>
        <SubtabsHeader />
      </div>
      <div className="my-4 ml-4">
        <NBtn>Upload Content</NBtn>
      </div>
      <div className="mx-4 flex flex-wrap">
        <div>
          <img src="/banner2.jpg" height={240} width={480} />
        </div>
        <div className="ml-4">
          <div className="fixed p-2 py-1 bg-[#5F46FF] text-white font-semibold">
            Generated by AI studio
          </div>
          <img src="/banner3.png" height={240} width={480} />
        </div>
        <div className="ml-4">
          <div className="fixed p-2 py-1 bg-[#5F46FF] text-white font-semibold">
            Generated by AI studio
          </div>

          <img src="/banner1.png" height={240} width={480} />
        </div>
      </div>
    </div>
  );
}
