import { ReactComponent as PlusIcon } from "../assets/svg/btnplus.svg";
import styles from './sidebarLayout.module.scss'
import insights from '../assets/svg/insights.svg'
import { ReactComponent as LeftArrow } from '../assets/svg/leftArrow.svg'
import { useLocation, useNavigate } from "react-router-dom";
import { SearchInput } from "../components/atoms/search";
import { Grid } from "@mui/material";
import MultipleSelectChip from "../components/molecules/select";
import { ReactComponent as SavedIcon } from '../assets/svg/saved.svg'
import { ReactComponent as XylicIcon } from '../assets/svg/xylicIcon.svg'
import { ReactComponent as Filter } from '../assets/svg/filter.svg'
import { useEffect, useState } from "react";
import { getCategoryData, getUtilsData } from "../services/insights";
import { ChatBubbleOutline } from '@mui/icons-material';
import { StarBorder } from '@mui/icons-material';
export const Chip = ({ item, selected }: any) => {
    return (
        <div style={{
            height: '32px',
            padding: '6px 12px',
            background: selected ? 'rgba(93, 66, 255, 0.08)' : 'rgb(243, 243, 248)',
            color: selected ? 'rgb(100, 77, 237)' : 'rgb(128, 129, 146)',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            borderRadius: '6px',
            width: 'fit-content'
        }}>
            {item}
        </div>
    )
}

export const SideLayout = () => {
    const [state, setState] = useState({
        brand: [],
        category: [],
        delivery: [],
        products: [],
        location: [],
        age_group: []
    })

    const getCategory = async () => {
        const response: any = await getCategoryData();
        const data = response || []
        setState({
            ...state, category: data
        })
    }

    const getBrands = async () => {
        try {
            const response: any = await getUtilsData('brand_name/?limit=10');
            const data = response?.results || []
            setState({
                ...state, brand: data
            })
        } catch (error) {
            setState({
                ...state, brand: []
            })
        }
    }

    const getLocation = async () => {
        try {
            const response: any = await getUtilsData('location?limit=10');
            const data = response?.results || []
            setState({
                ...state, location: data
            })
        } catch (error) {
            setState({
                ...state, location: []
            })
        }
    }

    useEffect(() => {
        getCategory();
        getBrands();
        // getLocation();
    }, [])

    const sidebarData = [
        {
            label: 'Transaction',
            children: [
                // {
                //     label: 'Seller',
                //     options: ['Brand 1', 'Brand 2', 'Brand 3', 'Brand 4', 'Brand 5', 'Brand 6', 'Brand 7', 'Brand 8']
                // },
                {
                    label: 'Business Model',
                    disable: true
                },
                {
                    label: 'Merchant type',
                    options: ['Option 1', 'Option 2', 'Option 3'],
                    disable: true
                },
                { label: 'Delivery', path: 'delivery_type?limit=10', id: 'delivery' },
                { label: 'Day of week', disable: true },
                { label: 'Time of Day', disable: true },
                { label: 'Payment Method', disable: true }
            ]
        },
        {
            label: 'Products',
            children: [
                { label: 'Category', data: state.category, path: 'unique_values?column=product_category' },
                { label: 'Sub Category', disable: true },
                {
                    label: 'Brand Group',
                    path: 'brand_group'
                },
                {
                    label: 'Brand',
                    path: 'brand_name/?limit=10',
                    id: 'products',
                    data: state.brand
                },
                {
                    label: 'Item',
                    disable: true
                },
                {
                    label: 'Price Range',
                    disable: true
                },
                { label: 'Second-hand & Refurbished', disable: true },
            ]
        },
        {
            label: 'Customers',
            children: [
                {
                    label: 'Region & city',
                    path: 'location?limit=10',
                    id: 'location',
                    data: []
                },
                {
                    label: 'Age Range',
                    path: 'age_group?limit=10',
                    id: 'age_group'
                },
                {
                    label: 'Gender',
                    disable: true
                },
                { label: 'Customer Base', options: ['Option M', 'Option N', 'Option O'], disable: true },
            ]
        }
    ];

    const sidebarData5 = [{
        label: 'Archived',
        children: [{
            label: 'Archived1'
        }, {
            label: 'Archived2'
        }, {
            label: 'Archived3'
        }, {
            label: 'Archived4'
        }]
    }, {
        label: 'History',
        children: [{
            label: 'History1'
        }, {
            label: 'Hisotry2'
        },]
    }]
    const sidebarData2 = [
        {
            label: 'Solutions',
            children: [
                {
                    label: 'Collaboration'
                },
                {
                    label: 'Sharing'
                },
                {
                    label: 'Insights'
                },
                { label: 'Media' },
                { label: 'A/B Testing' }
            ]
        },
        /*{
            label: 'Subscriber',
            children: [
                {
                    label: 'Unilever'
                },
                {
                    label: 'P&G'
                },
                {
                    label: 'Loreal'
                },
                { label: 'Pepsico' },
                { label: 'Kelloges' },
            ]
        },*/
        /* {
             label: 'Saved Collection',
             children: [
                 {
                     label: 'Unilever'
                 },
                 {
                     label: 'P&G'
                 },
                 {
                     label: 'Loreal'
                 },
                 { label: 'Pepsico' },
                 { label: 'Kelloges' },
             ]
         }*/
    ]
    const sidebarData3 = [
        {
            label: 'Product Selection',
            children: [
                {
                    label: 'Category'
                },
                {
                    label: 'CPG'
                },
                {
                    label: 'Brand'
                },
                {
                    label: 'Sub-brand'
                },
                {
                    label: 'Vendor'
                },
            ]
        },
        /* {
             label: 'Saved Collection',
             children: [
                 {
                     label: 'Unilever'
                 },
                 {
                     label: 'P&G'
                 },
                 {
                     label: 'Loreal'
                 },
                 { label: 'Pepsico' },
                 { label: 'Kelloges' },
             ]
         }*/
    ]
    const location = useLocation()
    const navigate = useNavigate()
    return (<>
        <div className={`sidebar`}>
            {location.pathname === '/dashboard' && <ul>
                <ul className="sidebarRightSideContainer">
                    <div style={{ padding: '22px 26px' }}>
                        <p style={{ fontSize: '18px', fontWeight: 600, lineHeight: '16px' }}>Hello, James</p>
                        <p style={{ fontSize: '12px', fontWeight: 500, lineHeight: '16px', color: 'rgb(151, 151, 151)', marginTop: '4px' }}>Thursday, February 15</p>
                    </div>
                    <div style={{ padding: '4px 26px' }}><SearchInput sx={{
                        width: '200px'
                    }} />
                        <div style={{ paddingTop: '20px' }}>
                            <p style={{ fontSize: '14px', fontWeight: 500, color: 'rgb(95, 70, 254)' }}>Clear All</p>
                        </div>
                    </div>
                    <div className={styles.scrollContainer2}>
                        {sidebarData2.map((item_) => {
                            return (
                                <div >
                                    <div className={styles.MainTextHeader}>{item_.label}</div>
                                    <Grid container style={{ padding: '16px' }}>
                                        {item_.children.map((item, index) => {
                                            return <Grid item padding={'5px'}>
                                                {item_.label == "Solutions" ? <Chip {...{ item: item.label, selected: [2, 6, 8].includes(index) }} /> : <Chip {...{ item: item.label }} />}
                                            </Grid>
                                        })}
                                    </Grid>
                                </div>
                            )
                        })}
                        <div style={{ borderTop: '1px solid lightgrey', padding: '21px' }} className="flex items-center justify-center">
                            <button
                                onClick={() => {
                                    if (window) {
                                        window.open("/xylic-ai", "_blank")?.focus();
                                    }
                                }}
                                style={{
                                    background: "rgb(19, 15, 53)",
                                    padding: "0px 21px",
                                    color: "white",
                                    height: "40px",
                                    borderRadius: "100px",
                                    display: "flex",
                                    gap: "8px",
                                    alignItems: "center",
                                }}
                                className="active:scale-90 cursor-pointer select-none"
                            >
                                Open Store View
                            </button>
                        </div>
                    </div>

                </ul>
            </ul>}
            {(location.pathname === '/xylic-ai' || location.pathname == '/new-dashboard') && 
            <ul>
                <ul className="sidebarRightSideContainer">
                    <div style={{ padding: '16px', display: 'flex', justifyContent: 'start' }}>
                        <p className="insightsHeader" style={{ fontSize: '18px' }}>Dashboard</p>
                    </div>
                    <Grid sx={{
                        display: 'flex', justifyContent: 'space-between',
                        padding: '16px',
                        cursor: 'pointer'
                    }} onClick={() => navigate('/new-dashboard')}>
                        <p style={{ color: 'rgb(48, 36, 139)', fontWeight: 500 }}>Favourites</p>
                        <StarBorder sx={{
                            color: "#FFD600"
                        }} />
                    </Grid>
                    <Grid sx={{
                        display: 'flex', justifyContent: 'space-between',
                        padding: '16px',
                        borderBottom: '1px solid rgb(239, 239, 241)',
                        cursor: 'pointer'
                    }} onClick={() => navigate('/xylic-ai')}>
                        <p style={{ color: 'rgb(48, 36, 139)', fontWeight: 500 }}>Xylic AI</p>
                        <XylicIcon />
                    </Grid>
                    {location.pathname === '/xylic-ai' && <>
                        <Grid sx={{
                            display: 'flex', justifyContent: 'space-between',
                            padding: '16px',
                        }}>
                            <p style={{ fontWeight: 500 }}>New Chat</p>
                            <ChatBubbleOutline sx={{
                                color: 'rgb(94, 92, 113)'
                            }} />
                        </Grid>
                        <div className={styles.scrollContainer2}>
                            {sidebarData5.map((item_) => {
                                return (
                                    <div >
                                        <div className={styles.MainTextHeader}>{item_.label}</div>
                                        <Grid container style={{ padding: '16px', display: 'flex', flexDirection: 'column', gap: '14px' }}>
                                            {item_.children.map((item) => {
                                                return (
                                                    <Grid >{item.label} <br /></Grid>
                                                )
                                            })}
                                        </Grid>
                                    </div>
                                )
                            })}
                        </div>
                    </>}

                </ul>
            </ul>}
            {location.pathname.startsWith("/insights") && <ul className="sidebarRightSideContainer">
                {false && <div className="sidebarInsightsHeader">
                    <img src={insights} alt="" />
                    <p className="insightsHeader">Insights</p>
                </div>}
                <div className={styles.scrollContainer}>
                    <div>
                        <div style={{
                            padding: '14px 26px 14px 26px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px'
                        }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: "center"
                            }}>
                                <div style={{
                                    display: 'flex',
                                    gap: '5px'
                                }}>
                                    <Filter />
                                    <p style={{
                                        fontFamily: "Poppins",
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        lineHeight: "20px",
                                        color: "#130F35"
                                    }}>
                                        Filters
                                    </p>
                                </div>
                                <SavedIcon />
                            </div>
                            <div>
                                <p style={{
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    color: "#454545"
                                }}>0 selected</p>
                            </div>
                        </div>
                        <div style={{
                            padding: "12px 26px 12px 26px",
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: "center"
                        }}>
                            <p style={{
                                color: "rgb(48, 36, 139)",
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: 600,
                                lineHeight: "20px",
                                letterSpacing: "0.1px"
                            }}>
                                Saved Filters
                            </p>
                            <LeftArrow />
                        </div>
                    </div>
                    {sidebarData.map((item) => {
                        return (
                            <div >
                                <div className={styles.MainTextHeader} style={{ marginTop: '10px' }}>{item.label}</div>
                                <div>
                                    {
                                        item.children.map((child: any) => {
                                            return (
                                                <MultipleSelectChip
                                                    key={child.label}
                                                    label={child.label}
                                                    options={child.options || []}
                                                    disable={child.disable || false}
                                                    path={child?.path || ''}
                                                    data={child?.data || []}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </ul>}
            {location.pathname === '/supplychain' && <ul className="sidebarRightSideContainer">
                <div className={styles.scrollContainer}>
                    <div>
                        <div style={{
                            padding: '14px 26px 14px 26px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px'
                        }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: "center"
                            }}>
                                <div style={{
                                    display: 'flex',
                                    gap: '5px'
                                }}>
                                    <Filter />
                                    <p style={{
                                        fontFamily: "Poppins",
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        lineHeight: "20px",
                                        color: "#130F35"
                                    }}>
                                        Filters
                                    </p>
                                </div>
                                <SavedIcon />
                            </div>
                            <div>
                                <p style={{
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    color: "#454545"
                                }}>0 selected</p>
                            </div>
                        </div>
                        <div style={{
                            padding: "12px 26px 12px 26px",
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: "center"
                        }}>
                            <p style={{
                                color: "rgb(48, 36, 139)",
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: 600,
                                lineHeight: "20px",
                                letterSpacing: "0.1px"
                            }}>
                                Saved Filters
                            </p>
                            <LeftArrow />
                        </div>
                    </div>
                    {sidebarData3.map((item) => {
                        return (
                            <div >
                                <div className={styles.MainTextHeader} style={{ marginTop: '10px' }}>{item.label}</div>
                                <div>
                                    {
                                        item.children.map((child: any) => {
                                            return (
                                                <MultipleSelectChip
                                                    key={child.label}
                                                    label={child.label}
                                                    options={child.options || []}
                                                    disable={child.disable || false}
                                                    path={child?.path || ''}
                                                    data={child?.data || []}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </ul>}
        </div>
    </>)
}