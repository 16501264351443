import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
	mainHeader:'Dashboard',
	subHeader:'',
	isDashboard: true
};

export const appContextSlice = createSlice({
	name: 'tabpanel',
	initialState,
	reducers: {
		switchDashboard: (state, action) => {
			state.isDashboard = action.payload?.isEnabled
		},
		onMainTabChange: (state,action) => {
			state.mainHeader = action.payload?.name;
		},
		onsubTabChange: (state,action) => {
			state.subHeader = action.payload?.name;
		},
	}
});

export const {onMainTabChange,onsubTabChange, switchDashboard} = appContextSlice.actions;
export default appContextSlice.reducer;