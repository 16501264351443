import styles from "./rightSidebarLayout.module.scss";
import insights from "../../../assets/svg/insights.svg";
import { ReactComponent as LeftArrow } from "../../../assets/svg/leftArrow.svg";
import { CsvConnectorRSB } from "./Components/CsvConnectorRSB";
import { CircuitStore } from "@nodl/react";
import { forwardRef, useCallback, useEffect, useMemo } from "react";
import { DropColumnRSB } from "./Components/DropColumnRSB";
import { JoinConnectorRSB } from "./Components/JoinConnectorRSB";
import { OutputRSB } from "./Components/OutputRSB";
import { RenameRSB } from "./Components/RenameRSB";
import { SplitRSB } from "./Components/SplitRSB";
import { TypeConvertorRSB } from "./Components/TypeConvertorRSB";

export const RightSideLayout = forwardRef(({
  nodeId,
  store,
}: {
  nodeId: string;
  store: CircuitStore;
}, ref: any) => {
  const node: any = useMemo(() => {
    console.log(store.nodes.filter((val) => val.id == nodeId)[0]);
    return store.nodes.filter((val) => val.id == nodeId)[0];
  }, [nodeId]);

  return (
    <>
      <div ref={ref} className={styles.sidebarRight}>
        <ul className="sidebarRightSideContainer">
          <div className={styles.scrollContainer}>
            {node.uid == "csv-connector" && <CsvConnectorRSB node={node} />}
            {node.uid == "operation-drop" && <DropColumnRSB node={node} />}
            {node.uid == "operation-join" && <JoinConnectorRSB node={node} />}
            {(node.uid == "output-user" ||
              node.uid == "output-transaction") && <OutputRSB node={node} />}
              {node.uid == "operation-rename" && <RenameRSB node={node} />}
              {node.uid == "operation-split" && <SplitRSB node={node} />}
              {node.uid == "operation-typeConvert" && <TypeConvertorRSB node={node} />}
          </div>
        </ul>
      </div>
    </>
  );
});
