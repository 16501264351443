// commented lines for future purpose
// commented lines for future purpose
import React from "react"
import Box from "@mui/material/Box"
import { StyledTablePagination } from "./styles"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import styles from "./pagiantion.module.scss"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface PaginationProps {
  isTablePagination?: boolean
  rowsPerPageOptions?: Array<any>
  colSpan?: number
  paginationDisplay?: object
  count?: number
  SelectProps?: any
  boundaryCount?: number
  color?: "primary" | "secondary" | "standard"
  defaultPage?: number
  disabled?: boolean
  hideNextButton?: boolean
  hidePrevButton?: boolean
  shape?: "circular" | "rounded"
  showFirstButton?: boolean
  showLastButton?: boolean
  siblingCount?: number
  size?: "small" | "medium" | "large"
  variant?: "outlined" | "text"
  sx?: any
  // setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  LeftActive?: any
  LeftDisable?: any
  RightDisable?: any
  RightActive?: any
  isFilterDrawerOpen?: any
  //
  showGenerate?: any
  resultCount?: any
  clientPage?: any
  generateRowsDisplayPerPage?: any
  showNext?: any
  wareHouseTablePage?: any
  wareHouseRowsResultCount?: any
  selectedWareHouseRows?: any
  page?: any
  setPage?: any
  tableData?: any
  rowsPerPage?: any
  setRowsPerPage?: any
  wareHouseStatusRows?: any
  setClientPage?: any
  setGenerateRowsDisplayPerPage?: any
  setWareHouseTablePage?: any
  clientRowsPerPage?: any
  setClientRowsPerPage?: any
  handleChangeRowsPerPage?: any
  handleChangePage?: any
  isInstructions?: any
}

interface TablePaginationActionsProps {
  count: number
  page: number
  // clientPage?: number;
  rowsPerPage: number
  LeftActive?: any
  LeftDisable?: any
  RightDisable?: any
  RightActive?: any
  isFilterDrawerOpen?: any
  rowsDisplayPerPage?: any
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void
  showGenerate?: any
  resultCount?: any
  clientPage?: any
  generateRowsDisplayPerPage?: any
  showNext?: any
  wareHouseTablePage?: any
  wareHouseRowsResultCount?: any
  selectedWareHouseRows?: any
  clientRowsPerPage?: any
  setClientRowsPerPage?: any
  handleChangePage?: any
  handleChangeRowsPerPage?: any
}
function TablePaginationActionsSecond(props: TablePaginationActionsProps) {
  const {
    count,
    page,
    rowsPerPage,
    LeftActive,
    LeftDisable,
    RightDisable,
    RightActive,
    isFilterDrawerOpen,
    handleChangePage,
  } = props
  const handleBackButtonClick: any = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    let returnPage = page === 0 ? 1 : page
    handleChangePage(event, returnPage)
  }

  const handleNextButtonClick: any = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    let returnPage = (page === 1) ? 2 : page+1
    handleChangePage(event, returnPage + 1)
  }

  return (
    <Box
      sx={{ flexShrink: 0, ml: isFilterDrawerOpen ? "16px" : "32px" }}
    // className={styles.paginationButton}
    >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <KeyboardArrowLeftIcon onClick={handleBackButtonClick} style={{ cursor: 'pointer', color: "rgb(94, 92, 113)", width: 23 }} />
        <div className={styles.fontColorGrey}>
          {page === 1 ? page : (page-1) * 25}-{page * 25} of {count || 0}
        </div>
        <KeyboardArrowRightIcon onClick={handleNextButtonClick}
          style={{ cursor: 'pointer', color: "rgb(94, 92, 113)", width: 23 }}
        />
      </div>
    </Box>
  )
}

const CommonPagination = ({
  isTablePagination,
  rowsPerPageOptions,
  colSpan,
  paginationDisplay,
  SelectProps,
  LeftActive,
  LeftDisable,
  RightDisable,
  RightActive,
  isFilterDrawerOpen,
  count = 0,
  //
  page,
  setPage,
  tableData,
  resultCount,
  rowsPerPage,
  setRowsPerPage,
  wareHouseStatusRows,
  showGenerate,
  showNext,
  clientPage,
  setClientPage,
  generateRowsDisplayPerPage,
  setGenerateRowsDisplayPerPage,
  wareHouseTablePage,
  setWareHouseTablePage,
  wareHouseRowsResultCount,
  selectedWareHouseRows,
  clientRowsPerPage,
  setClientRowsPerPage,
  handleChangeRowsPerPage,
  isInstructions,
  ...other
}: PaginationProps) => {
  const handleChangePage = (e: any, newPage: number) => {
    setPage(newPage - 1)
  }
  return (
    <>
      <Box
        className={styles.containerPagination}
      >
        <StyledTablePagination
          {...{
            rowsPerPageOptions: [],
            colSpan: colSpan,
            count: resultCount,
            rowsPerPage: rowsPerPage,
            page: page,
            SelectProps: SelectProps,
            onPageChange: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
            clientPage: page,
            showGenerate: showGenerate,
            showNext: showNext,
          }}
          ActionsComponent={(props) => (
            <TablePaginationActionsSecond
              {...props}
              count={count}
              LeftActive={LeftActive}
              LeftDisable={LeftDisable}
              RightDisable={RightDisable}
              RightActive={RightActive}
              isFilterDrawerOpen={isFilterDrawerOpen}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
          sx={{
            ...other.sx,
          }}
          labelDisplayedRows={({ from, to, count }) => {
            return (
              <span>
              </span>
            )
          }}
        />
      </Box>
    </>
  )
}

export default CommonPagination
