import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      zIndex: 100000
    },
  },
};



export default function MultipleSelectChip({droppedCols,  names, onUpdate}: {droppedCols: any, names:Array<{name: string, type:string}>, onUpdate: any }) {

  const [personName, setPersonName] = React.useState<string[]>(droppedCols);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    const res = typeof value === 'string' ? value.split(',') : value
    setPersonName(
      res
    );
    onUpdate(res)
  };

  return (
    <FormControl fullWidth sx={{
      zIndex: 100000
    }}>
        <InputLabel id="demo-simple-select-label">Select Columns</InputLabel>
        <Select
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((val, i) => (
            <MenuItem
              key={i}
              value={val.name}
            >
              {val.name}
            </MenuItem>
          ))}
        </Select>
    </FormControl>
  );
}
