import React from "react";
import Dialog from "@mui/material/Dialog";
import { maxWidth, styled } from "@mui/system";
import { Button, InputAdornment, TextField, Box } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CsvDialog = styled(Dialog)({
  zIndex: 10000,
  ".MuiPaper-root": {
    height: "40%",
    width: "40%",
    maxWidth: "10000px",
  },
});

const NBtn = styled(Button)({
  backgroundColor: "#5F46FF",
  color: "white",
  ":hover": {
    backgroundColor: "#5F46FF",
  },
});

export const Chip = ({ item, selected }: any) => {
  return (
    <div
      style={{
        height: "32px",
        padding: "6px 12px",
        background: selected ? "rgba(93, 66, 255, 0.08)" : "rgb(243, 243, 248)",
        color: selected ? "rgb(100, 77, 237)" : "rgb(128, 129, 146)",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        borderRadius: "6px",
        width: "fit-content",
      }}
    >
      {item}
    </div>
  );
};

export default function AiMessageExport({
  open,
  handleClose,
}: {
  open: any;
  handleClose: any;
}) {
  const [message, setMessage] = useState("Analysing the ETL flow...");
  const [flow, setFlow] = useState(0);
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setFlow(1);
        toast.success("ETL flow verified successfully!", {
          toastId: "success1",
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          theme: "light",
        });
      }, 5000);
    }
  }, [open]);

  const navigate = useNavigate();

  return (
    <CsvDialog
      onClose={() => {
        handleClose();
      }}
      open={open}
    >
      <DialogContent>
        {flow == 0 && (
          <div className="flex flex-col items-center justify-center h-full w-full">
            <img height={128} width={128} src="/loader.gif" />
            <span>{message}</span>
          </div>
        )}
        {flow == 1 && (
          <div className="flex flex-col items-center justify-center h-full w-full">
            <img height={128} width={128} src="/done.gif" />
            <span>
              Congratulations, your data can be monetize using following modules
            </span>
            <div className="flex mt-2">
              <div>
                <Chip
                  {...{
                    item: "Insights",
                    selected: true,
                  }}
                />
              </div>
              <div className="ml-2">
                <Chip
                  {...{
                    item: "Media",
                    selected: true,
                  }}
                />
              </div>
              <div className="ml-2">
                <Chip
                  {...{
                    item: "Survey",
                    selected: true,
                  }}
                />
              </div>
              <div className="ml-2">
                <Chip
                  {...{
                    item: "Supply Chain",
                    selected: true,
                  }}
                />
              </div>
            </div>
            <div className="mt-6">
              <NBtn
                onClick={() => {
                  handleClose();
                  navigate("/on-boarding");
                }}
              >
                Continue
              </NBtn>
            </div>
          </div>
        )}
      </DialogContent>
    </CsvDialog>
  );
}
