import * as React from 'react';
export const useHover = (onHover) => {
    const [isHovered, setHover] = React.useState(false);
    const cleanup = React.useRef();
    const onMouseEnter = React.useCallback((e) => {
        setHover(true);
        cleanup.current = onHover === null || onHover === void 0 ? void 0 : onHover(e);
    }, []);
    const onMouseLeave = React.useCallback((e) => {
        var _a;
        setHover(false);
        (_a = cleanup.current) === null || _a === void 0 ? void 0 : _a.call(cleanup, e);
    }, []);
    return {
        onMouseEnter,
        onMouseLeave,
        isHovered
    };
};
