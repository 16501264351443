import React, { useEffect, useState } from "react";
import styles from "../../styles.module.scss";
import { ReactComponent as BackArrowIcon } from "../../../../../assets/svg/backarr.svg";
import { ReactComponent as TickIcon } from "../../../../../assets/svg/tick.svg";
import { ReactComponent as SaveIcon } from "../../../../../assets/svg/save.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/svg/cross.svg";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import SelectInput from "../inputs/SelectInput";
import DateInput from "../inputs/DateInput";
import TextInput from "../inputs/TextInput";
import RadioInput from "../inputs/RadioInput";
import { getUtilsData } from "../../../../../services/insights";
//@ts-ignore
import DragableList from "../../elements/DragableList";

const CreateForm = ({ handleBack }: any) => {
  const [catList, setcatList] = useState([]);
  const [bList, setBlist] = useState([]);
  const [target, setTarget] = useState("");
  useEffect(() => {
    fetch(
      "https://api.xylicdata.com/utils/unique_values?column=product_category"
    )
      .then((res) => res.json())
      .then((res) => {
        setcatList(
          res.map((r: any) => {
            return { label: r, value: r };
          })
        );
      });
    getUtilsData("brand_name/?limit=100").then((res: any) => {
      console.log("jor van", res);
      setBlist(
        res.results.map((r: any) => {
          return { label: r, value: r };
        })
      );
    });

    fetch("");
  }, []);
  return (
    <div
      style={{
        background: "white",
        borderRadius: "5px",
        marginTop: "8px",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid rgb(95, 70, 234,0.5)",
          borderRadius: "5px 5px 0px 0px",
          height: "63px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "18px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BackArrowIcon style={{ cursor: "pointer" }} onClick={handleBack} />
          <h2
            style={{
              color: "rgb(19, 15, 53)",
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0.5px",
            }}
          >
            Create Camapign
          </h2>
        </div>
      </div>
      <div>
        <div className={styles["form"]}>
          <div className={styles["left-form"]}>
            <SelectInput
              {...{
                label: "Type",
                options: [
                  { label: "Select campaign type", value: "" },
                  { label: "Sponsered product", value: "1" },
                  { label: "Sponered display", value: "2" },
                ],
              }}
            />
            <TextInput
              {...{
                label: "Campaing name",
              }}
            />
            <div
              style={{
                display: "flex",

                flexDirection: "column",
              }}
            >
              <p className={styles["form-label"]}>Duration:</p>
              <div className="flex mt-2 gap-2">
                <DateInput
                  {...{
                    label: "Start",
                  }}
                />
                <DateInput
                  {...{
                    label: "End",
                  }}
                />
              </div>
            </div>
            <TextInput
              {...{
                label: "Daily Budget ($)",
              }}
            />
            <div
              style={{
                display: "flex",
                gap: "50px",
                alignItems: "center",
              }}
            >
              <SelectInput
                {...{
                  label: "Category",
                  options: catList,
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: "50px",
                alignItems: "center",
              }}
            >
              <SelectInput
                {...{
                  label: "Brand",
                  options: bList,
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <p className={styles["form-label"]}>Target:</p>
              <div
                style={{
                  display: "flex",
                  gap: "50px",
                  alignItems: "center",
                }}
              >
                <FormControl>
                  <RadioGroup
                    value={target}
                    onChange={(e: any) => {
                      setTarget(e.target.value);
                    }}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="automatic_targeting"
                      control={<Radio />}
                      label="Automatic targeting"
                    />
                    <FormControlLabel
                      value="manual_targeting"
                      control={<Radio />}
                      label="Manual targeting"
                    />
                    <FormControlLabel
                      value="manual_stack"
                      control={<Radio />}
                      label="Saved segmentation"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="my-3">
              {target == "manual_targeting" && (
                <div className="my-4 max-w-[40%] !z-[10000]">
                  <span>Drag to change priority:</span>
                  <DragableList
                    items_={[
                      {
                        id: "item-0",
                        component: "Based on competition",
                        checkboxList: [
                          "Switched to competition",
                          "Churned out of the market",
                          "Repeat customers",
                          "New buyers from competition",
                          "New buyers from the market",
                        ],
                      },
                      {
                        id: "item-1",
                        component: "Based on behavior",
                        checkboxList: [
                          "Heavy buyers",
                          "Medium buyers",
                          "Lite buyers",
                        ],
                      },
                      {
                        id: "item-2",
                        component: "Based on demographics",
                        checkboxList: ["Male", "Female"],
                      },
                      {
                        id: "item-3",
                        component: "Based on keywords",
                        checkboxList: [],
                      },
                      {
                        id: "item-4",
                        component: "Based on added to cart but not purchased",
                        checkboxList: [],
                      },
                      {
                        id: "item-5",
                        component: "Based on clicked on ads but not purchased",
                        checkboxList: [],
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles["rule-content"]}>
            <p className={styles["title"]}>Rule book</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <p className={styles["mini-title"]}>How to select:</p>
              <p className={styles["content"]}>
                Selecting categories, brands, duration and budgets are crucial
                for a successful media campaign. Ensure you understand your
                target audience and market to make informed decisions.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <p className={styles["mini-title"]}>Tips:</p>
              <ul className={styles["content-list"]}>
                <li>Understand your target audience thoroughly.</li>
                <li>
                  Choose categories and sub-categories relevant to your products
                  or services.
                </li>
                <li>Align the brand message with the campaign's goals.</li>
                <li>
                  Consider both short-term and long-term durations for the
                  campaign.
                </li>
                <li>
                  Allocate a realistic budget based on the campaign objectives.
                </li>
              </ul>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <p className={styles["mini-title"]}>Aditional Information:</p>
              <p className={styles["content"]}>
                Include any additional information relevant to the campaign
                here, such as market trends, case studies, or success stories.
              </p>
            </div>
          </div>
          <div className="flex gap-3">
            <Button
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 55px 10px 55px",
                color: "rgb(247, 247, 247)",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                letterSpacing: "0.1px",
                textAlign: "center",
                borderRadius: "100px",
                boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.15)",
                background: "rgb(19, 15, 53)",
                textTransform: "none",
                "&:hover": {
                  background: "rgb(19, 15, 53)",
                },
              }}
            >
              <TickIcon fill="white" />
              Create
            </Button>
            <Button
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 24px 10px 24px",
                color: "rgb(19, 15, 53)",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                letterSpacing: "0.1px",
                textAlign: "center",
                borderRadius: "100px",
                boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.15)",
                border: "1px solid rgb(19, 15, 53)",
                textTransform: "none",
                "&:hover": {
                  background: "inherit",
                },
              }}
            >
              <SaveIcon fill="#130F35" />
              Save as draft
            </Button>
            <Button
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                boxShadow: "none",
                background: "none",
                color: "rgb(19, 15, 53)",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                letterSpacing: "0.1px",
                textAlign: "center",
                textTransform: "none",
                "&:hover": {
                  background: "inherit",
                },
              }}
            >
              <CrossIcon fill="#130F35" />
              Discard
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateForm;
