var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { useHover } from '../../hooks/useHover/useHover';
import { tooltipWrapperStyles, tooltipNodeWrapperStyles } from './Tooltip.styles';
const TooltipNode = ({ text, position, offset }) => {
    const ref = React.useRef(null);
    const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });
    React.useEffect(() => {
        var _a;
        const { width, height } = ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) || { width: 0, height: 0 };
        setDimensions({ width, height });
    }, []);
    return (_jsx("span", Object.assign({ ref: ref, css: tooltipNodeWrapperStyles(position, dimensions, offset) }, { children: text })));
};
export const Tooltip = (_a) => {
    var { children, className } = _a, tooltipNodeProps = __rest(_a, ["children", "className"]);
    const { onMouseEnter, onMouseLeave, isHovered } = useHover();
    return (_jsxs("div", Object.assign({ css: tooltipWrapperStyles, className: className, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave }, { children: [_jsx("div", { children: children }), isHovered && _jsx(TooltipNode, Object.assign({}, tooltipNodeProps))] })));
};
