import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ReactComponent as XylicLogo } from '../../../assets/svg/xylicLogo.svg';
import { ReactComponent as BottomLogo } from '../../../assets/svg/loginLogo.svg';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormInput from '../../../components/atoms/FormInputField';
import { CustomButton } from '../../../components/atoms/button/button';
import styles from './register.module.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CheckCircleOutline } from '@mui/icons-material';
import { Close } from '@mui/icons-material';
import { Visibility } from '@mui/icons-material';
import { fontSize, fontWeight } from '@mui/system';
const defaultTheme = createTheme();

export default function PasswordSetup() {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };

    const navigate = useNavigate()

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid
                container
                component='main'
                sx={{ height: '100vh' }}
                className={`font-poppins ${styles.mainCont}`}
            >

                <CssBaseline />

                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={8}
                    // className={styles.bgimage}
                    sx={{
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#5B47F5',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <Grid sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh'
                    }}>
                        <Grid sx={{
                            gap: '20px', display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <p style={{ color: 'white', fontWeight: 800, fontSize: '40px' }}>Xylic Data</p>
                            <p style={{ color: 'white', fontWeight: 400, fontSize: '20px' }}>
                                Build, Manage and Optimise <br />
                                multiple revenue streams from a single platform
                            </p>
                            <Button sx={{
                                padding: '10px',
                                background: 'white',
                                borderRadius: '20px',
                                fontSize: '14px',
                                width: '135px', height: '37px',
                                '&:hover': {
                                    background: 'white',
                                }
                            }}>
                                Read More
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid sx={{
                        position: 'fixed',
                        top: 0,
                        right: '33.3%',
                        filter: 'blur(200px)',
                        background: 'rgb(207, 200, 255)',
                        height: '40%',
                        width: '30%'
                    }}></Grid>
                    <Grid sx={{
                        position: 'fixed',
                        bottom: 10,
                        filter: 'blur(200px)',
                        background: 'rgb(207, 200, 255)',
                        height: '30%',
                        width: '20%'
                    }}></Grid>
                    <Grid sx={{
                        position: 'fixed',
                        bottom: 0,
                    }}>
                        <BottomLogo />
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        padding: '0px 80px',
                        gap: '24px',
                        background: 'white'
                    }}
                >
                    <Grid sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems:'center',
                        gap:'24px',
                        background:'#D8F0DE',
                        padding:'16px',
                        marginBottom:'20px',
                        borderRadius:'8px'
                    }}>
                        <CheckCircleOutline fill='#5DB470'/>
                        <h1 style={{fontSize:'18px',fontWeight:500}}>Email Verified Successfully</h1>
                        <Close />
                    </Grid>
                    <Grid>
                        <XylicLogo />
                    </Grid>
                    <Typography component='h1' variant='h5' className={styles.headerText}>
                        Set your password
                    </Typography>
                    <Box
                        component='form'
                        noValidate
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                        display={'flex'}
                        gap={'24px'}
                        flexDirection={'column'}
                    >
                        <Grid sx={{ height: '40px' }}>

                        </Grid>

                        <FormInput
                            variant='filled'
                            size='small'
                            type='password'
                            label='Password'
                            endIcon={<Visibility />}
                            sx={{
                            }}
                        />

                        <FormInput
                            variant='filled'
                            size='small'
                            type='password'
                            label='Confirm Password'
                            endIcon={<Visibility />}
                            sx={{
                            }}
                        />

                        <Grid sx={{ height: '80px' }}>

                        </Grid>


                        <CustomButton children={'Confirm'} />
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
