import React, { useEffect } from "react";
import SubtabsHeader from "../../elements/Subtabs/SubtabsHeader";
import styles from "../../styles.module.scss";
import { ReactComponent as PlusIcon } from "../../../../../assets/svg/plus.svg";
import { ReactComponent as StarIcon } from "../../../../../assets/svg/start.svg";
import { ReactComponent as ExportIcon } from "../../../../../assets/svg/download.svg";
import { ReactComponent as ShareIcon } from "../../../../../assets/svg/share.svg";
import { DataTable } from "../../elements/tables/manageCompains";
import Pagination from "../../../../../components/molecules/Pagination";
import { useMedia } from "../../../useMedia";
import { rows } from "../../elements/tables/manageCompains";
import { ActiveCol } from "../../elements/tables/measurementAndAnalysis";
import { Button } from "@mui/material";
import SearchHeader from "../seach-header";
import CreateForm from "./CreateForm";
import { DetailedScreen } from "../Inner-Screen";

const ManageCampaigns = () => {
  const {
    page,
    setPage,
    handleRowClick,
    createForm,
    handleCreateForm,
    handleBack,
    detailed,
    setDetailed,
    manageRow,
    filter,
    listSelTab,
    setListSelTab
  } = useMedia();


  return (
    <div>
      <SubtabsHeader />
      <div style={{ padding: "16px", paddingTop: "0px" }}>
        <SearchHeader {...{
          detailed,
          listSelTab,
          setListSelTab
        }} />
        {!createForm && !detailed?.manage && !(listSelTab==='1') && (
          <div style={{ background: "white" }}>
            <div style={{ borderBottom: "1px solid rgb(95, 70, 234,0.5)" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "6px",
                  marginTop: "10px",
                  padding: "10px",
                  paddingLeft: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "18px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h2 className={styles.bestSellingHeader}>Manage Campaigns</h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination {...{ setPage, page: 1, count: 10 }} />{" "}
                  <StarIcon /> <ExportIcon /> <ShareIcon />
                </div>
              </div>
            </div>
            <div style={{ marginTop: "1px" }}>
              <DataTable
                handleRowClick={handleRowClick}
                data={rows}
                // {...{ filter }}
              />
            </div>
            <div className={styles["create-button"]}>
              <Button  onClick={handleCreateForm}>
                <PlusIcon fill="#F7F7F7" />
                Create Campaign
              </Button>
            </div>
          </div>
        )}
        {createForm && !detailed?.manage && !(listSelTab==='1') &&  (
          <CreateForm 
            {...{
              handleBack
            }}
          />
        )}
        {detailed?.manage && !(listSelTab==='1') && <DetailedScreen {...{row: manageRow, handleBack:() => setDetailed({...detailed, manage: false }),filter, ActiveCol }} />}
        {listSelTab==='1' && <DetailedScreen {...{row: {
          ad: 'Total 32 adverstisements',
          rows: '35,786',
          impressions: '35,786',
          views: '35,786',
          clicks: '35,786',
          conversions: '35,786',
          ctr: '35,786'
        }, handleBack:() => setListSelTab("2"),filter, ActiveCol }} />}
      </div>
    </div>
  );
};

export default ManageCampaigns;
