import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import ChartDataLabels from "chartjs-plugin-datalabels";

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );
const GroupedBarChart = ({ data, toptions,height='1000px',width='700px' }: any) => {
    return <Bar data={data} width={width} height={height} options={{
        ...toptions
    }} />;
};

export default GroupedBarChart;
