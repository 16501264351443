import { rows } from "../dataTable"
import styles from './listView.module.scss'
import { Grid } from '@mui/material'
import desc from '../../../../../assets/svg/description.svg'
import Loader from "../../../../../components/atoms/loader"

export const ListView = ({ handleClick, data, filter, loader }: any) => {
    return (
        <Grid className={styles.heightContainer} >
            {!loader ? <Grid container className={`${styles.listviewContainer}`}>
                {data.map((item: any) => {
                    return (
                        <Grid md={6} lg={4} xl={3} padding={1} >
                            <Grid className={`${styles.cardContainer1}`} onClick={() => handleClick(item)}>
                                <Grid className={styles.cardAlignments}>
                                    <Grid sx={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                                        {/* <img src={item.img} alt="" width={30} height={30} /> */}
                                        <h2 className={styles.cardNameText}>{item.product}</h2>
                                    </Grid>
                                    <Grid sx={{ display: 'flex' }}>
                                        {/* <img src={desc} alt="" width={30} height={30} /> */}
                                    </Grid>
                                </Grid>
                                <Grid style={{ display: "flex", justifyContent: 'space-between' }}>
                                    <Grid sx={{
                                        borderRight: '1px solid rgb(255, 216, 232)',
                                        paddingRight: '4%'
                                    }} className={styles.cardDescText}>
                                        <p>{filter.volume === 'volume' ? 'Quantity' : 'Sales'}</p>
                                        {filter.volume === 'volume' ? item.order_quantity_current.toFixed(1) : `$ ${item.sales_current.toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                                    </Grid>
                                    <Grid sx={{
                                        borderRight: '1px solid rgb(255, 216, 232)',
                                        paddingRight: '2%'
                                    }} className={styles.cardDescText}>
                                        <p>Prev. Period</p>
                                        {filter.volume === 'volume' ?
                                            <div style={{ color: item.change_in_order_quantity >= 0 ? 'rgb(88, 199, 2)' : 'rgb(246, 0, 0)' }}>{item.change_in_order_quantity >= 0 ? '+' : ''} {item.change_in_order_quantity}</div>
                                            : <div style={{ color: item.change_in_sales >= 0 ? 'rgb(88, 199, 2)' : 'rgb(246, 0, 0)' }}>{item.change_in_sales >= 0 ? '+' : ''} {item.change_in_sales}</div>
                                        }                                    </Grid>
                                    <Grid sx={{
                                        paddingRight: '2%'
                                    }} className={styles.cardDescText}>
                                        <p>Average Price</p>
                                        <p>$ {item.avg_sales_current.toFixed(1)}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid> :
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                    <Loader />
                </div>
            }
        </Grid>
    )
}