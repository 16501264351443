import React, { useEffect, useState } from "react";
import styles from "../sidebarLayout.module.scss";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const SplitRSB = ({ node }: { node: any }) => {
  const [inputs, setInputs] = useState(node.output_);

  return (
    <div>
      <div className={styles.MainTextHeader}>
        <div className="w-full flex items-center justify-between">
          <span>Split Columns</span>
          <div
            className={styles.headerAdd}
            onClick={(e) => {
              setInputs([...inputs, ["", "", ""]]);
            }}
          >
            <AddIcon />
          </div>
        </div>
      </div>
      <div className="p-2">
        {inputs.map((val: any, i: any) => {
          return (
            <div className="flex flex-col items-center justify-center mt-2">
              <div className="flex items-center justify-center w-full">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={val[0]}
                    label="Select column"
                    onChange={(e: any) => {
                      let j: any = [e.target.value, val[1]];
                      setInputs([
                        ...inputs.slice(0, i),
                        j,
                        ...inputs.slice(i + 1, inputs.length),
                      ]);
                      node.setOutput([
                        ...inputs.slice(0, i),
                        j,
                        ...inputs.slice(i + 1, inputs.length),
                      ]);
                    }}
                  >
                    {node.getColumns().map((val: any) => {
                      return <MenuItem value={val.name}>{val.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <div
                  onClick={() => {
                    setInputs([
                      ...inputs.slice(0, i),
                      ...inputs.slice(i + 1, inputs.length),
                    ]);
                    node.setOutput([
                      ...inputs.slice(0, i),
                      ...inputs.slice(i + 1, inputs.length),
                    ]);
                  }}
                  className="cursor-pointer active:scale-90"
                >
                  <RemoveIcon />
                </div>
              </div>
              <div className="mt-1">
                <TextField
                  variant="outlined"
                  value={val[1]}
                  onChange={(e) => {
                    let j: any = [val[0], e.target.value];

                    setInputs([
                      ...inputs.slice(0, i),
                      j,
                      ...inputs.slice(i + 1, inputs.length),
                    ]);
                    node.setOutput([
                      ...inputs.slice(0, i),
                      j,
                      ...inputs.slice(i + 1, inputs.length),
                    ]);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { SplitRSB };
