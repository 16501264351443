/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import styles from './commontable.module.scss';
import { Typography } from '@mui/material';
import Loader from '../../atoms/loader';

type Order = 'asc' | 'desc';

interface Cols {
  columnKey?: string;
  columnLabel?: string | React.ReactNode;
  minWidth?: number;
  style?: React.CSSProperties;
  // render?: (row: any, index: any, parentData?: any) => React.ReactNode;
  render?: (
    // row: any,
    // setEditingRow?: any,
    // setNonEditMode?: any,
    // page?: any,
    // rowsPerPage?: any,
    // updatedTableData?: any,
    // setResultCount?: any,
    // successWindow?: any,
    // setSuccessWindow?: any,
    // failedWindow?: any,
    // setFailedWindow?: any,
    // updatedWareHouseData?: any,
    // assignImage?: any,
    // deleteCharger?: any,
    // downloadTenantOrder?: any,
    // downloadVendorOrder?: any,
    // EditIconImage?: any,
    // DeleteIconImage?: any,
    row: any,
    handler?: any,
    toggleFunction?: any,
    pageNumber?: any,
    rowsPerPage?: any,
    updatedTableData?: any,
    setResultCount?: any,
    successWindow?: any,
    setSuccessWindow?: any,
    failedWindow?: any,
    setFailedWindow?: any,
    updatedWareHouseData?: any,
    assignImage?: any,
    deleteCharger?: any,
    downloadTenantOrder?: any,
    downloadVendorOrder?: any,
    EditIconImage?: any,
    DeleteIconImage?: any,
    getData?: any
  ) => any;
  id?: any;
  numeric?: boolean;
  sortLabel?: boolean;
}
interface TableProps {
  cols?: Array<Cols>;
  data?: Array<any>;
  isExpandable?: boolean;
  expandCols?: Array<object>;
  expandLabelKey?: string;
  preloading?: boolean;
  className?: string;
  onExpand?: (row: object, id: string) => void;
  sx?: any;
  isPagination?: boolean;
  page?: any;
  rowsPerPage?: any;
  tableContainerStyles?: any;
  childTableStyles?: any;
  childLoading?: boolean;
  parentData?: any;
  id?: string;
  onRowClick?: any;
  selectAll?: any;
  setPage?: any;
  headStyle?: any;
  bodyStyle?: any;
  Icon1?: any;
  Icon2?: any;
  Icon3?: any;
  Icon4?: any;
  rowStyle?: any;
  selected?: any;
  setSelected?: any;
  sortIcon?: any;
  userSelectedRow?: any;
  from?: any;
  hover?: any;
  Header?: any;
  DetailsForm?: any;
  CheckBoxIcon?: any;
  CheckBoxChecked?: any;
  chargerValue?: any;
  editingRow?: any;
  setEditingRow?: any;
  tableData?: any;
  selectedRows?: any;
  setSelectedRows?: any;
  nonEditMode?: any;
  setNonEditMode?: any;
  updatedTableData?: any;
  setResultCount?: any;
  showNext?: any;
  wareHouseStatusRows?: any;
  selectedWareHouseRows?: any;
  setSelectedWareHouseRows?: any;
  updatedWareHouseData?: any;
  successWindow?: any;
  setSuccessWindow?: any;
  failedWindow?: any;
  setFailedWindow?: any;
  assignImage?: any;
  deleteCharger?: any;
  downloadTenantOrder?: any;
  downloadVendorOrder?: any;
  EditIconImage?: any;
  DeleteIconImage?: any;
  selectedRowsWithPage?: any;
  setSelectedRowsWithPage?: any;
  selectedWareHouseRowsWithPage?: any;
  setSelectedWareHouseRowsWithPage?: any;
  isInstructions?: any;
  selection?: boolean;
  loading?: boolean;
  rowSpan?: boolean;
  getData?: any;
  chargerPopup?: boolean;
  warehouse?: any;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  cols?: Array<Cols>;
  data?: Array<object>;
  sx?: any;
  sortIcon?: any;
  CheckBoxIcon?: any;
  CheckBoxChecked?: any;
  page?: any;
  selectedRowsWithPage?: any;
  setSelectedRowsWithPage?: any;
  selection?: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    sortIcon,
    orderBy,
    rowCount,
    onRequestSort,
    cols,
    sx,
    CheckBoxIcon,
    CheckBoxChecked,
    page,
    selectedRowsWithPage,
    selection,
  } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const checkedStatus =
    page === 0 || page === 1
      ? selectedRowsWithPage?.length === 0
        ? false
        : selectedRowsWithPage?.some((item: any) => {
          if (1 in item) {
            return Object.values(item[1])?.length === rowCount ? true : false;
          } else return false;
        })
      : selectedRowsWithPage?.some((item: any) => {
        if (page in item) {
          return Object.values(item[page])?.length === rowCount;
        } else return false;
      });
  return (
    <TableHead className={styles.datatableHeader}>
      <TableRow sx={{ ...sx, background: 'rgb(255, 255, 255)', borderRadius: 'none' }}>
        {selection && (
          <TableCell
            sx={{
              ...sx,
              borderRadius: 'none',
              textAlign: 'center' as any,
              width: '52px',
              ':first-of-type': {
                paddingLeft: '10px !important',
                paddingRight: '10px !important',
              },
            }}
          >
            <Checkbox
              icon={<img src={CheckBoxIcon} alt='' />}
              checkedIcon={<img src={CheckBoxChecked} alt='' />}
              color='primary'
              checked={checkedStatus}
              onChange={onSelectAllClick}
              disableRipple
              inputProps={{
                'aria-label': 'select all desserts',
              }}
              className={styles.datatableCheckbox}
            />
          </TableCell>
        )}
        {cols?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ ...sx, textTransform: 'uppercase' }}
            style={headCell.style}
          >
            {headCell.sortLabel ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                IconComponent={() => sortIcon}
              >
                {headCell.columnLabel}
                {orderBy === headCell.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.columnLabel
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function CommonTable(tableProps: TableProps) {
  const {
    cols = [],
    page,
    onRowClick = () => { },
    rowsPerPage,
    headStyle,
    rowStyle,
    tableContainerStyles,
    sortIcon,
    userSelectedRow = [],
    from,
    CheckBoxIcon,
    CheckBoxChecked,
    editingRow,
    setEditingRow,
    tableData = [],
    selectedRows,
    setSelectedRows,
    setNonEditMode,
    updatedTableData,
    setResultCount,
    updatedWareHouseData,
    successWindow,
    setSuccessWindow,
    failedWindow,
    setFailedWindow,
    assignImage,
    deleteCharger,
    downloadTenantOrder,
    downloadVendorOrder,
    EditIconImage,
    DeleteIconImage,
    selectedRowsWithPage,
    setSelectedRowsWithPage,
    isInstructions,
    selection = true,
    loading = false,
    rowSpan,
    getData,
    chargerPopup,
    warehouse,
  } = tableProps;
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<any>();
  const [hoveredRow] = React.useState<any>();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSelectedWithPage = (param: number) => {
    return selectedRowsWithPage
      ?.filter((item: any) => {
        if (param in item) {
          return item;
        }
        return null;
      })
      ?.map((element: any) => element[param])
      .reduce(
        (accumulator: any, currValue: any) => [...accumulator, ...currValue],
        []
      );
  };

  const handleSelectionsforAll = (
    event: React.ChangeEvent<HTMLInputElement>,
    obj1: any
  ) => {
    if (event.target.checked) {
      const itemPresent = obj1.findIndex((item: any) =>
        page === 0 || page === 1 ? 1 in item : page in item
      );
      if (!(itemPresent in obj1)) {
        if (page === 0 && tableData?.length === rowsPerPage) {
          setSelectedRowsWithPage([
            ...selectedRowsWithPage,
            {
              1: [...tableData],
            },
          ]);
        } else {
          setSelectedRowsWithPage([
            ...selectedRowsWithPage,
            {
              [page]: [...tableData],
            },
          ]);
        }
      } else {
        if (page === 0 || page === 1) {
          const updatedItemIndex = obj1?.findIndex((item: any) => 1 in item);
          obj1[updatedItemIndex] = {
            1: [...tableData],
          };
          setSelectedRowsWithPage(obj1);
        } else {
          const updatedItemIndex = obj1?.findIndex((item: any) => page in item);
          obj1[updatedItemIndex] = {
            [page]: [...tableData],
          };
          setSelectedRowsWithPage(obj1);
        }
      }
      setSelectedRows(tableData);
      return;
    }

    if (page === 0 || page === 1) {
      obj1?.forEach((item: any, index: number) => {
        if (1 in item) {
          obj1[index][1] = [];
        }
      });

      setSelectedRowsWithPage(obj1);
    } else {
      obj1?.forEach((item: any, index: number) => {
        if (page in item) {
          obj1[index][page] = [];
        }
      });
      setSelectedRowsWithPage(obj1);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    let obj1 = selectedRowsWithPage;
    handleSelectionsforAll(event, obj1);
    setSelectedRows([]);
  };

  const elementIndexSelectedRows = (row: any) => {
    let newSelectedArr = [...selectedRowsWithPage];
    let updatedPageIndex = -1;
    let updatedItemIndex = -1;
    if (page === 0 || page === 1) {
      updatedPageIndex = newSelectedArr?.findIndex((item: any) => 1 in item);
    } else {
      updatedPageIndex = newSelectedArr?.findIndex((item: any) => page in item);
    }

    if (updatedPageIndex > -1) {
      if (page === 0 || page === 1) {
        let updatedPage = newSelectedArr[updatedPageIndex];
        updatedItemIndex = updatedPage[1]?.findIndex(
          (item: any) => row?.chargerId === item?.chargerId
        );
        if (updatedItemIndex === -1) {
          newSelectedArr[updatedPageIndex][1].push(row);
        } else {
          newSelectedArr[updatedPageIndex][1].splice(updatedItemIndex, 1);
        }
      } else {
        let updatedPage = newSelectedArr[updatedPageIndex];
        updatedItemIndex = updatedPage[page]?.findIndex(
          (item: any) => row?.chargerId === item?.chargerId
        );
        if (updatedItemIndex === -1) {
          newSelectedArr[updatedPageIndex][page].push(row);
        } else {
          newSelectedArr[updatedPageIndex][page].splice(updatedItemIndex, 1);
        }
      }
    } else if (updatedPageIndex === -1) {
      if (page === 0 || page === 1) {
        const arr = isSelectedWithPage(1);
        arr.push(row);
        newSelectedArr = [...newSelectedArr, { 1: arr }];
      } else {
        const arr = isSelectedWithPage(page);
        arr.push(row);
        newSelectedArr = [...newSelectedArr, { [page]: arr }];
      }
    }
    setSelectedRowsWithPage(newSelectedArr);
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const target = event.target as HTMLInputElement;
    const isCheckbox = target.type === 'checkbox' && target.tagName === 'INPUT';
    if (isCheckbox) {
      const selectedIndex = selectedRows?.indexOf(row);
      elementIndexSelectedRows(row);
      let newSelected: readonly string[] = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows?.slice(1));
      } else if (selectedIndex === selectedRows?.length - 1) {
        newSelected = newSelected.concat(selectedRows?.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows?.slice(0, selectedIndex),
          selectedRows?.slice(selectedIndex + 1)
        );
      }
      const finData = newSelected?.filter(
        (item: any, index: any, array: any) =>
          array.findIndex((obj: any) => obj?.chargerId === item?.chargerId) ===
          index
      );
      setSelectedRows(finData);
    } else if (!isCheckbox) {
      onRowClick(row);
    }
  };

  const isSelectedSingleClick = (row: any) => {
    const checkWithPage =
      page === 0 || page === 1
        ? isSelectedWithPage(1)
        : isSelectedWithPage(page);
    if (checkWithPage?.length > 0) {
      return (
        checkWithPage?.filter((item: any) => item[from] === row[from]).length >
        0
      );
    } else
      return (
        row[from] ===
        userSelectedRow?.filter((item: any) => item[from] === row[from])
          .length >
        0
      );
  };

  const isSelectedRowSpanClick = (row: any) => {
    const totData = tableData?.filter(
      (item: any) => row?.fileUploadId === item?.fileUploadId
    );
    const finData = selectedRows?.filter(
      (item: any) => row?.fileUploadId === item?.fileUploadId
    );
    return totData?.length === finData?.length;
  };

  const [, setTimer] = React.useState(false);

  React.useEffect(() => {
    if (editingRow !== null) {
      setTimer(true);
    }

    setTimeout(() => {
      setTimer(false);
    }, 4000);
  }, [editingRow]);

  return (
    <Box
      sx={{
        overflowX: 'auto',
        background: '#FFFFFF',
        width: '100%',
        borderRadius: '8px 8px 0px 0px',
      }}
    >
      {isInstructions && !warehouse && (
        <Typography
          sx={{
            color: 'var(--Primary-900, #051433)',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '24px',
            textTransform: 'uppercase',
            display: 'flex',
            padding: '12px 24px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '12px',
            alignSelf: 'stretch',
          }}
          className={styles.RightContainerHeader}
        >
          smart emobility Order form
        </Typography>
      )}

      <Paper sx={{ boxShadow: 'none' }}>
        <TableContainer
          sx={{
            ...tableContainerStyles,
            borderRadius: 'none',
            overflowY: 'auto',
            overflowX: 'auto',
            position: 'relative',
            '&::-webkit-scrollbar': {
              width: '8px',
              borderRadius: '8px',
              height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#EAECF0',
              borderRadius: '8px',
              width: '8px',
            },
          }}
          // className={styles.tableContainer}
          className={
            !warehouse
              ? isInstructions
                ? styles.instructionContainer
                : styles.orderTableContainer
              : ''
          }
        >
          {!loading ? (
            <Table
              sx={{ minWidth: chargerPopup ? '100%' : 750 , tableLayout:'fixed'}}
              stickyHeader
              aria-labelledby='tableTitle'
              className={styles.datatable}
            >
              <EnhancedTableHead
                cols={cols}
                data={tableData}
                numSelected={selectedRows?.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={tableData?.length}
                sx={headStyle}
                sortIcon={sortIcon}
                CheckBoxIcon={CheckBoxIcon}
                CheckBoxChecked={CheckBoxChecked}
                page={page}
                selectedRowsWithPage={selectedRowsWithPage}
                setSelectedRowsWithPage={setSelectedRowsWithPage}
                selection={selection}
              />
              <TableBody className={styles.datatableBody}>
                {tableData?.map((row: any, index: number) => {
                  // const isItemSelected = isSelected(row);
                  const isItemSelected = isSelectedSingleClick(row);
                  const isSelectedRowSpan: any = isSelectedRowSpanClick(row);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      onClick={(event: any) => handleClick(event, row)}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.chargerId}
                      selected={selection ? isItemSelected : false}
                    >
                      {' '}
                      {selection && (
                        <TableCell
                          sx={{
                            textAlign: 'center',
                            borderRight: '1px solid var(--gray-200, #EAECF0)',
                          }}
                        >
                          {hoveredRow === row ||
                            (Object.keys(userSelectedRow).length > 0 &&
                              userSelectedRow[from] === row[from] &&
                              (page === 0 || page === 1
                                ? isSelectedWithPage(1)?.length === 0
                                : isSelectedWithPage(page)?.length === 0)) ? (
                            <Checkbox
                              icon={<img src={CheckBoxIcon} alt='' />}
                              checkedIcon={<img src={CheckBoxChecked} alt='' />}
                              color='primary'
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                              disableRipple
                              sx={{
                                textAlign: 'center',
                                cursor: 'pointer',
                                '& .MuiCheckbox-root': {
                                  padding: 0,
                                },
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                              className={styles.datatableCheckbox}
                            />
                          ) : (
                            <Checkbox
                              icon={<img src={CheckBoxIcon} alt='' />}
                              checkedIcon={<img src={CheckBoxChecked} alt='' />}
                              color='primary'
                              checked={
                                isItemSelected &&
                                selectedRowsWithPage.length > 0
                              }
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                              disableRipple
                              sx={{
                                textAlign: 'center',
                                cursor: 'pointer',
                                '& .MuiCheckbox-root': {
                                  padding: 0,
                                },
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                              className={styles.datatableCheckbox}
                            />
                          )}
                        </TableCell>
                      )}
                      {cols?.map((col, key) => {
                        return (
                          <>
                            {col.id === 'fileUploadId' &&
                              row?.rowSpan &&
                              rowSpan ? (
                              <TableCell
                                key={key}
                                rowSpan={row.rowSpan}
                                sx={{
                                  ...rowStyle,
                                  minWidth: col?.minWidth,
                                }}
                                className={
                                  isSelectedRowSpan ||
                                    selectedRowsWithPage[0]?.undefined.length ===
                                    tableData.length
                                    ? ''
                                    : styles.customRowStyles
                                }
                              >
                                {row[col.id] || col.id === 'action'
                                  ? col.render!(
                                    row,
                                    index,
                                    setEditingRow,
                                    setNonEditMode,
                                    page,
                                    rowsPerPage,
                                    updatedTableData,
                                    setResultCount,
                                    successWindow,
                                    setSuccessWindow,
                                    failedWindow,
                                    setFailedWindow,
                                    updatedWareHouseData,
                                    assignImage,
                                    deleteCharger,
                                    downloadTenantOrder,
                                    downloadVendorOrder,
                                    EditIconImage,
                                    DeleteIconImage,
                                  )
                                  : '-'}
                              </TableCell>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}
                      {cols?.map((col, key) => {
                        return (
                          <>
                            {col.id !== 'fileUploadId' || !rowSpan ? (
                              <TableCell
                                key={key}
                                sx={{
                                  ...rowStyle,
                                  minWidth: col?.minWidth,
                                }}
                              >
                                {row[col.id] ||
                                  col.id === 'action' ||
                                  row?.connectors?.length !== 0
                                  ? col.render!(
                                    row,
                                    index,
                                    setEditingRow,
                                    setNonEditMode,
                                    page,
                                    rowsPerPage,
                                    updatedTableData,
                                    setResultCount,
                                    successWindow,
                                    setSuccessWindow,
                                    failedWindow,
                                    setFailedWindow,
                                    updatedWareHouseData,
                                    assignImage,
                                    deleteCharger,
                                    downloadTenantOrder,
                                    downloadVendorOrder,
                                    EditIconImage,
                                    DeleteIconImage,
                                  )
                                  : '-'}
                              </TableCell>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center',alignItems:'center',height:'60vh' }}>
              <Loader />
            </div>
          )}
        </TableContainer>
      </Paper>
    </Box>
  );
}
