import React, { useEffect } from 'react'
import styles from "../../insights.module.scss";
import RightTabs from '../../../../../components/molecules/TabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { onsubTabChange } from '../../../../../store/header/reducer';
import { useNavigate, useLocation } from 'react-router-dom';

const SubtabsHeader = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const subTab = useSelector((state: any) => state?.headerContext?.subHeader);
    const handleType = (item: any, portal: string) => {
        navigate(item?.value);
        dispatch(onsubTabChange(item));
    }

    const tabdata = [
        { name: 'Best Sellers', id: 0, value: '/insights/best-sellers' },
        { name: 'Customer Behaviour', id: 1, value: '/insights/customer-behaviour' },
        { name: 'Segmentation', id: 2, value: '/insights/segmentation' },
    ];

    return (
        <div className={styles.headerContainer}>
            <RightTabs
                {...{
                    tabs: tabdata,
                    selectedTab: subTab,
                    handleType: handleType,
                    styles: {
                        widdth: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '& .MuiToggleButton-root': {
                            fontWeight: 500,
                            textTransform: 'none',
                            fontSize: '16px',
                            borderRadius: '0px',
                            border: 'none',
                            fontFamily: 'Poppins',
                            color: 'rgb(94, 92, 113)',
                            padding: '10px 0px',
                            lineHeight: '24px',
                            textAlign: 'left',
                            '&:hover': {
                                backgroundColor: '#FFF',
                            },
                        },
                        '& .Mui-selected.MuiToggleButton-root': {
                            padding: '11px 0px 9px 0px',
                            backgroundColor: '#FFF',
                            border: 'none',
                            boxShadow: 'none',
                            borderRadius: 'none',
                            color: 'rgb(95, 70, 255)',
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '24px',
                            borderBottom: '2px solid rgb(95, 70, 255)',
                            '&:hover': {
                                backgroundColor: '#FFF',
                            },
                        },
                    }
                }
                }
            />
        </div>
    )
}

export default SubtabsHeader