import React from 'react';
import { VectorMap } from 'react-jvectormap';

const MapChart = ({style, data}: any) => {

    const renderTooltip = (event:any, tip:any, code:any) => {
        const value = data[code];
        if (value) {
          tip.html(tip.html() + '<br/>' + value);
        } else {
            event.preventDefault();
        }
      };
    
    const getLatLon = (code: string) => {
        // You need to provide latitude and longitude of each state for positioning
        // This function should return an object with 'lat' and 'lon' properties
        // You may need to research or obtain this data from a reliable source
        switch (code) {
          case 'IN-RJ': return { lat: 26.5834, lon: 74.0551 };
          case 'IN-MP': return { lat: 22.9734, lon: 78.6569 };
          case 'IN-DL': return { lat: 28.7041, lon: 77.1025 };
          case 'IN-KL': return { lat: 10.8505, lon: 76.2711 };
          default: return { lat: 0, lon: 0 }; // Default coordinates
        }
      };
      
    const markers = Object.keys(data).map(code => ({
        latLng: [getLatLon(code).lat, getLatLon(code).lon],
        name: data[code].toString(),
      }));

  return (
    <div style={{ width: 300, height: 300, ...style }}>
      <VectorMap
        map={'us_aea'}
        backgroundColor="#FFFFFF"
        focusOn={{
            x: 0.2,
            y: 2,
            scale: 1,
            animate: true
        }}
        zoomOnScroll={true}
        containerStyle={{
        //   width: 'calc(100% + 60px)',
          height: '100%',
        }}
        // onRegionClick={console.log(countryCode);}
        regionStyle={{
          initial: {
            fill: 'rgb(244, 240, 254)',
            'fill-opacity': 1,
            stroke: '#fff',
            'stroke-width': 3,
            'stroke-opacity': 1,
          },
          hover: {
            'fill-opacity': 0.8,
            cursor: 'pointer',
          },
          selected: {
            // fill: '#fff',
          },
          selectedHover: {},
        }}
        onRegionTipShow={renderTooltip}
        regionsSelectable={true}
        series={{
          regions: [
            {
                values: data,
                // scale: ['#C8EEFF', '#0071A4'],
                scale: ['#8B97FF', '#FFB68D', '#FF8D94', '#85D8DD'],
              normalizeFunction: 'polynomial',
            },
          ],
        }}
        zoomButtons={false}
        // markers={markers}
      />
    </div>
  );
};

export default MapChart;
