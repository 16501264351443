import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Grid } from '@mui/material'
export const ListSegment=({item}:any)=>{
    return(
        <Grid md={6} lg={4} padding={'12px 12px 12px 0px'} >
        <Grid className={`${styles.cardContainer1}`} sx={{background:'#fff',width:'100%'}}>
            <Grid className={styles.cardAlignments}>
                <Grid sx={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                    <h2 className={styles.cardNameText}>{item.name}</h2>
                </Grid>
            </Grid>
            <Grid style={{ display: "flex", justifyContent: 'space-between' }}>
                <Grid sx={{
                    borderRight: '1px solid rgb(229, 225, 236)',
                    paddingRight: '6%'
                }} className={styles.cardDescText}>
                    <p style={{fontSize:'12px',fontWeight:400}}>AOV</p>
                    <p style={{fontSize:'14px',fontWeight:400}}>{item.aov}</p>
                </Grid>
                <Grid sx={{
                    borderRight: '1px solid rgb(229, 225, 236)',
                    paddingRight: '6%'
                }} className={styles.cardDescText}>
                    <p style={{fontSize:'12px',fontWeight:400}}>Trips</p>
                    <p style={{fontSize:'14px',fontWeight:400}}>{item.trips}</p>
                </Grid>
                <Grid sx={{
                    paddingRight: '2%'
                }} className={styles.cardDescText}>
                    <p style={{fontSize:'12px',fontWeight:400}}>Customers</p>
                    <p style={{fontSize:'14px',fontWeight:400}}>{item.customers}</p>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    )
}
export const ListCard = ({autoStack}:any) => {
    const [rows, setRows] : any = useState([])
    useEffect(() => {
        const tempRows: any = []
        Object.keys(autoStack).map((item,index)=>{
            tempRows.push({
                aov:autoStack[item]['AOV'],name:`Target Segment ${index+1}`,trips:autoStack[item]['TRIPS'],customers:autoStack[item]['CUSTOMERS']
            })
        })
        setRows(tempRows)
    }, [autoStack])

    return (
        <Grid container className={`${styles.listviewContainer}`}>
            {rows.map((item:any) => {
                return (
                   <ListSegment item={item}/>
                )
            })}
        </Grid>
    )
}