import styles from "./sidebarLayout.module.scss";
import GestureIcon from "@mui/icons-material/Gesture";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/system";
import { Button, InputAdornment, TextField, Box } from "@mui/material";
import { toast } from "react-toastify";

const NBtn = styled(Button)({
  backgroundColor: "#5F46FF",
  color: "white",
  ":hover": {
    backgroundColor: "#5F46FF",
  },
});

export const SideLayout = ({
  flowId,
  onAdd,
  onExport
}: {
  flowId: any;
  onAdd: (id: string, x: number, y: number) => void;
  onExport: any
}) => {
  const sidebarData = [
    {
      label: "Inputs",
      children: [
        {
          label: "Data Connector",
          id: "data-connector",
        },
      ],
    },
    {
      label: "Operations",
      children: [
        {
          label: "Drop Column",
          id: "drop-column",
        },
        {
          label: "Join Tables",
          id: "join-tables",
        },
        {
          label: "Rename Column",
          id: "rename-column",
        },
        { label: "Type Convertor", id: "type-convert" },
        { label: "Split columns (string)", id: "operation-split" },
      ],
    },
    {
      label: "Outputs",
      children: [
        {
          label: "Users",
          id: "output-user",
        },
        {
          label: "Transactions",
          id: "output-transaction",
        },
      ],
    },
  ];

  return (
    <>
      <div className={`sidebar`}>
        <ul className="sidebarRightSideContainer">
          <div className="sidebarInsightsHeader">
            <GestureIcon className="insightsHeader" />
            <p className="insightsHeader select-none">Data Animator</p>
          </div>
          <div className={styles.scrollContainer}>
            {sidebarData.map((item) => {
              return (
                <div>
                  <div className={styles.MainTextHeader}>{item.label}</div>
                  <div>
                    {item.children.map((item) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "5px 14px",
                          }}
                        >
                          <li className={styles.subLabelText}>{item.label}</li>
                          <div
                            className={styles.addButton}
                            onClick={(e) => {
                              const x = -((window.innerWidth - 260) / 2 - 150);
                              const center = window.innerHeight / 2 + 70;
                              const y = -(e.clientY - center);
                              onAdd(item.id, x, y);
                            }}
                          >
                            <AddIcon />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <div
              style={{ borderTop: "1px solid lightgrey", padding: "21px" }}
              className="flex items-center justify-center w-full"
            >
              <button
                onClick={() => {
                  if (flowId == 0) {
                    toast.error(
                      "Please add user and transaction data before exporting!",
                      {
                        toastId: "error10",
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        theme: "light",
                      }
                    );
                  } else if (flowId == 1) {
                    toast.error(
                      "Please add transaction data before exporting!",
                      {
                        toastId: "error10",
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        theme: "light",
                      }
                    );
                  } else if (flowId == 2) {
                    toast.error(
                      "Please add transaction data before exporting!",
                      {
                        toastId: "error11",
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        theme: "light",
                      }
                    );
                  } else if(flowId == 3){
                    toast.error(
                      "Please add missing transaction data before exporting!",
                      {
                        toastId: "error11",
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        theme: "light",
                      }
                    );
                  }else { 
                    onExport()
                  }
                }}
                style={{
                  background: "rgb(19, 15, 53)",
                  padding: "0px 21px",
                  color: "white",
                  height: "40px",
                  borderRadius: "100px",
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
                className="active:scale-90 cursor-pointer select-none mt-2"
              >
                Save & Export
              </button>
            </div>
          </div>
        </ul>
      </div>
    </>
  );
};
