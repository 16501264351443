import React, {useEffect, useRef, useState} from "react";
import Tree from "react-d3-tree";
import styles from "./index.module.scss";
import { getDashboardTreeData } from "../../../services/insights";
// This is a simplified example of an org chart with a depth of 2.
// Note how deeper levels are defined recursively via the `children` property.
const orgChart = {
  name: "Total revenue",
  value: "$725,586",
  children: [
    {
      name: "Revenue/customer",
      value: "$725,586",
      children: [
        {
          name: "Revenue/purchase",
          value: "$725,586",
          children: [
          ],
        },
        {
          name: "Orders/customer",
          value: "$725,586",
          children: [
            {
              name: "Avg price/purchase",
              value: "$725,586"
            },
            {
                name: "Units/purchase",
                value: "$725,586"
            },
          ],
        },
      ],
    },
    {
        name: "Customers",
        value: "$725,586",
        children: [

        ]
    }
  ],
};
  
const ceilNumber = (value:number) => {
  return Math.ceil(value * 10) / 10;
}

export default function OrgChartTree({ style, translate, treeData}: any) {
    const renderForeignObjectNode = ({
        nodeDatum,
        toggleNode,
        foreignObjectProps
      }: any) => {
        return (
          <g>
            {/* `foreignObject` requires width & height to be explicitly set. */}
            <foreignObject {...foreignObjectProps}>
              <div style={{ 
                  borderRadius: "5px", 
                  backgroundColor: "#F4F0FE", 
                  minHeight: '52px', 
                  minWidth: '130px',
                  padding: '16px',
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: "space-between"
              }}>
                <div>
                  <p style={{
                      fontFamily: 'Inter',
                      fontSize: '10px',
                      fontWeight: 400,
                      lineHeight: '20px',
                      color: "#000",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100px"
                  }}>{nodeDatum?.name}</p>
                  <p style={{
                      fontFamily: 'Inter',
                      fontSize: '10px',
                      fontWeight: 600,
                      lineHeight: '20px',
                      color: "#000"
                  }}>{nodeDatum?.value}</p>
                </div>
                {nodeDatum?.children && nodeDatum?.children?.length > 0 && (
                  <button style={{ width: "5px", border: 'none', cursor: "pointer", background: "none" }} onClick={toggleNode}>
                    {nodeDatum.__rd3t.collapsed ? "+" : "-"}
                  </button>
                )}
              </div>
            </foreignObject>
          </g>
        )
      };

      const nodeSize = { x: 200, y: 200 };
      const foreignObjectProps = { width: '130px', height: '100px', x: 0, y: -35 };
  return (
    <div id="treeWrapper" style={{ width: "calc(100% - 5px)", height: "250px", ...style }}>
      {treeData && (
        <Tree 
          data={treeData} 
          orientation="horizontal"
          translate={translate ? translate : { x: 20, y: 120 }}
          separation={{ siblings: 2, nonSiblings: 2 }}
          collapsible={true}
          pathFunc={"step"}
          nodeSize={{
              y: 78,
              x: 170
          }}
          enableLegacyTransitions={true}
          renderCustomNodeElement={(rd3tProps) =>
              renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
          }
          pathClassFunc={(d, i) => `${styles['custom-link']}`}
        />
      )}
    </div>
  );
}
