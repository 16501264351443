import React, { useEffect, useState } from 'react'
import CardContainer from '../components/CardContainer';
import styles from "../monetise.module.scss";
import OrgChart from '../components/charts/OrgChart';
import MapChart from '../components/charts/MapChart';
import { BarchartLoyality } from '../components/charts/LoyalityChart';
import { BarchartCustomerRelationship } from '../components/charts/CustomerChart';
import { BarchartPromotions } from '../components/charts/PromotionChart';
import Loader from '../../../../components/atoms/loader';

const RightSideDiv = ({ expand, setExpand, segmentationData, selectBrand, setSelectBrand, brands, customerBrands, treeData, loyalityData, customerSwitchData, promotionsData, chartLoader,
    loyaltyLoader,
    customerLoader,
    promotionsLoader,
    bestsellersLoader,
    segmentationLoader,
    allTimes,
    setAllTimes,
    mapsLoader,
    mapsData }: any) => {

    return (
        <div className={`${styles["right-div"]} ${(expand.salesTree || expand.salesMap || expand.loyaltyChart || expand.customerSwitch || expand.promotions) ? styles['expand-right-div'] : ''}`}>
            <CardContainer
                {...{
                    title: 'Sales diagnostic tree',
                    dropdown: true,
                    onClick: () => setExpand({ ...expand, salesTree: !expand.salesTree }),
                    selectBrand,
                    setSelectBrand,
                    name: 'chart',
                    allTimes,
                    setAllTimes
                }}
            >
                {!chartLoader ?
                    <OrgChart
                        {...{
                            style: {
                                height: "300px"
                            },
                            treeData
                        }}
                    /> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader size={40} />
                    </div>
                }
            </CardContainer>
            <CardContainer
                {...{
                    title: 'Regional sales',
                    dropdown: true,
                    onClick: () => setExpand({ ...expand, salesMap: !expand.salesMap }),
                    selectBrand,
                    setSelectBrand,
                    name: 'map',
                    allTimes,
                    setAllTimes
                }}
            >
                {!mapsLoader ? <MapChart
                    {...{
                        style: {
                            // height: "calc(100% - 10px)",
                            width: "100%"
                        },
                        data: mapsData
                    }}
                /> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Loader size={40} />
                </div>}
            </CardContainer>
            <CardContainer
                {...{
                    title: 'Segmentation',
                    dropdown: true,
                    selectBrand,
                    setSelectBrand,
                    name: 'segmentation',
                    allTimes,
                    setAllTimes
                }}
            >
                {!segmentationLoader ? <div className={styles['segmentation-cards']}>
                    {segmentationData?.map((card: any, index: number) => (
                        <div className={styles['segment-card']} key={index}>
                            <p className={styles['title']}>{card?.title}</p>
                            <div className={styles["tiles"]}>
                                {card?.segment?.map((seg: any, ind: number) => (
                                    <div style={{ display: 'flex', gap: '60px', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                                            <p style={{ fontFamily: 'Inter', fontSize: '10px', fontWeight: 400, lineHeight: '20px', color: '#130F35' }}>{seg?.title}</p>
                                            <p style={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: 400, lineHeight: '20px', color: '#000000' }}>{seg?.content}</p>
                                        </div>
                                        <div style={{ height: '25px', width: '1px', background: '#E5E1EC', display: ind === card?.segment?.length - 1 ? 'none' : '' }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Loader size={40} />
                </div>}
            </CardContainer>
            <CardContainer
                {...{
                    title: 'Loyalty',
                    dropdown: true,
                    onClick: () => setExpand({ ...expand, loyaltyChart: !expand.loyaltyChart }),
                    selectBrand,
                    setSelectBrand,
                    name: 'loyalty',
                    options: brands,
                    allTimes,
                    setAllTimes
                }}
            >
                {!loyaltyLoader ? <div style={{}}>
                    <BarchartLoyality data={loyalityData} />
                </div> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Loader size={40} />
                </div>}
            </CardContainer>
            <CardContainer
                {...{
                    title: 'Customer switch',
                    dropdown: true,
                    onClick: () => setExpand({ ...expand, customerSwitch: !expand.customerSwitch }),
                    selectBrand,
                    setSelectBrand,
                    name: 'customer',
                    options: customerBrands,
                    allTimes,
                    setAllTimes
                }}
            >
                {!customerLoader ? <div>
                    <BarchartCustomerRelationship data={customerSwitchData} />
                </div> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Loader size={40} />
                </div>}
            </CardContainer>
            <CardContainer
                {...{
                    title: 'Promotions',
                    dropdown: true,
                    onClick: () => setExpand({ ...expand, promotions: !expand.promotions }),
                    selectBrand,
                    setSelectBrand,
                    name: 'promotions',
                    options: brands,
                    allTimes,
                    setAllTimes
                }}
            >
                {!promotionsLoader ? <div>
                    <BarchartPromotions data={promotionsData} />
                </div> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Loader size={40} />
                </div>}
            </CardContainer>
        </div>
    )
}

export default RightSideDiv