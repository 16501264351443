import React, { useEffect, useMemo, useState } from "react";
import styles from "../sidebarLayout.module.scss";
import ChipsInput from "../../../../components/inputs/ChipsInput"
const DropColumnRSB = ({ node }: { node: any }) => {
  return (
    <div>
      <div className={styles.MainTextHeader}>
        <div className="w-full flex items-center justify-between">
          <span>Drop Columns</span>
        </div>
      </div>
      <div className="flex flex-col mt-2 p-2">
        <ChipsInput droppedCols={node.getDroppedCols()} names={node.getColumns()} onUpdate={(val:any)=> {
          node.setDroppedCols(val)
          node.setColumns( node.getColumns().filter((obj: any)=> !val.includes(obj.name)))
        }}/>
      </div>
    </div>
  );
};

export { DropColumnRSB };
