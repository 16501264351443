import React, { useEffect, useState } from "react";
import styles from "../sidebarLayout.module.scss";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const TypeConvertorRSB = ({ node }: { node: any }) => {
  const [inputs, setInputs] = useState(node.output_);


  return (
    <div>
      <div className={styles.MainTextHeader}>
        <div className="w-full flex items-center justify-between">
          <span>Convert Columns Type</span>
          <div
            className={styles.headerAdd}
            onClick={(e) => {
              setInputs([...inputs, ["", "", ""]]);
            }}
          >
            <AddIcon />
          </div>
        </div>
      </div>
      <div className="p-2">
        <div className="flex">
          <div className="flex-1">old</div>
          <div className="flex-1">new</div>
        </div>
        {inputs.map((val: any, i: any) => {
          return (
            <div className="flex flex-col items-center justify-center mt-2">
           <div className="flex w-full items-center justify-center">
              <div className="flex-1">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={val[0]}
                    displayEmpty
                    onChange={(e: any) => {
                      let j: any = [e.target.value, val[1], val[2]];
                      setInputs([
                        ...inputs.slice(0, i),
                        j,
                        ...inputs.slice(i + 1, inputs.length),
                      ]);
                      node.setOutput([
                        ...inputs.slice(0, i),
                        j,
                        ...inputs.slice(i + 1, inputs.length),
                      ]);
                    }}
                  >
                    {node.getColumns().map((val: any) => {
                      return <MenuItem value={val.name}>{val.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
              <div
                onClick={() => {
                  setInputs([
                    ...inputs.slice(0, i),
                    ...inputs.slice(i + 1, inputs.length),
                  ]);
                  node.setOutput([
                    ...inputs.slice(0, i),
                    ...inputs.slice(i + 1, inputs.length),
                  ]);
                }}
                className="cursor-pointer active:scale-90"
              >
                <RemoveIcon />
              </div>
            </div>
              <div className="flex w-full my-2">
                <div className="flex-1">
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={val[1]}
                      displayEmpty
                      onChange={(e: any) => {
                        let j: any = [val[0], e.target.value, val[2]];
                        setInputs([
                          ...inputs.slice(0, i),
                          j,
                          ...inputs.slice(i + 1, inputs.length),
                        ]);
                        node.setOutput([
                          ...inputs.slice(0, i),
                          j,
                          ...inputs.slice(i + 1, inputs.length),
                        ]);
                      }}
                    >
                      <MenuItem value={"Object"}>Object</MenuItem>
                      <MenuItem value={"String"}>String</MenuItem>
                      <MenuItem value={"Number"}>Number</MenuItem>
                      <MenuItem value={"Array"}>Array</MenuItem>
                      <MenuItem value={"Float"}>Float</MenuItem>
                      <MenuItem value={"DateTime"}>DateTime</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="flex-1 ml-2">
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={val[2]}
                      displayEmpty
                      onChange={(e: any) => {
                        let j: any = [val[0], val[1], e.target.value];
                        setInputs([
                          ...inputs.slice(0, i),
                          j,
                          ...inputs.slice(i + 1, inputs.length),
                        ]);
                        node.setOutput([
                          ...inputs.slice(0, i),
                          j,
                          ...inputs.slice(i + 1, inputs.length),
                        ]);
                      }}
                    >
                      <MenuItem value={"Object"}>Object</MenuItem>
                      <MenuItem value={"String"}>String</MenuItem>
                      <MenuItem value={"Number"}>Number</MenuItem>
                      <MenuItem value={"Array"}>Array</MenuItem>
                      <MenuItem value={"Float"}>Float</MenuItem>
                      <MenuItem value={"DateTime"}>DateTime</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
        
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { TypeConvertorRSB };
