import { Grid } from "@mui/material"
import { ReactComponent as UpIcon } from "../../assets/svg/uparr.svg";
import { ReactComponent as DownIcon } from "../../assets/svg/downicon.svg";
import styles from './styles.module.scss'
import SmallCard from "../dashboard/components/SmallCard";
export const NewDashboard = () => {
    const cardsData: any = [
        {
            title: "Current month revenue",
            content: "$726,318",
            footer: (
                <div className={styles["card-footer"]}>
                    <UpIcon />
                    <p
                        style={{
                            fontFamily: "Inter",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "14px",
                            color: "#03A01C",
                        }}
                    >
                        10%{" "}
                        <span
                            style={{
                                fontFamily: "Inter",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "14px",
                                color: "#8B8B8B",
                            }}
                        >
                            vs prev = $726,200
                        </span>
                    </p>
                </div>
            ),
        },
        {
            title: "No. of customers",
            content: "18",
            footer: (
                <div className={styles["card-footer"]}>
                    <UpIcon />
                    <p
                        style={{
                            fontFamily: "Inter",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "14px",
                            color: "#03A01C",
                        }}
                    >
                        10%{" "}
                        <span
                            style={{
                                fontFamily: "Inter",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "14px",
                                color: "#8B8B8B",
                            }}
                        >
                            vs prev = 5
                        </span>
                    </p>
                </div>
            ),
        },
        {
            title: "Avg. modules per customer",
            content: "3",
            footer: (
                <div className={styles["card-footer"]}>
                    <UpIcon />
                    <p
                        style={{
                            fontFamily: "Inter",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "14px",
                            color: "#03A01C",
                        }}
                    >
                        10%{" "}
                        <span
                            style={{
                                fontFamily: "Inter",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "14px",
                                color: "#8B8B8B",
                            }}
                        >
                            vs prev = 2
                        </span>
                    </p>
                </div>
            ),
        },
        {
            title: "No. of categories monetized",
            content: "42",
            footer: (
                <div className={styles["card-footer"]}>
                    <UpIcon />
                    <p
                        style={{
                            fontFamily: "Inter",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "14px",
                            color: "#03A01C",
                        }}
                    >
                        10%{" "}
                        <span
                            style={{
                                fontFamily: "Inter",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "14px",
                                color: "#8B8B8B",
                            }}
                        >
                            vs prev = 5
                        </span>
                    </p>
                </div>
            ),
        },
    ];
    return (
        <Grid container sx={{
            height: '90vh'
        }}>
            <Grid item md={3} p={1}>
                <Grid sx={{
                    background: 'white',
                    height: '100%',
                    display:'flex',
                    flexDirection:'column',
                    gap:'10px',
                    padding:'10px'
                }}>
                    {cardsData?.map((card: any, index: number) => (
                        <SmallCard card={card} index={index} />
                    ))}
                </Grid>
            </Grid>
            <Grid item md={4.5} p={1}>
                <Grid style={{ display: 'flex', flexDirection: 'column', height: '100%', gap: '10px' }}>
                    <Grid sx={{
                        background: 'white',
                        height: '50%'
                    }}>

                    </Grid>
                    <Grid sx={{
                        background: 'white',
                        height: '50%'
                    }}>

                    </Grid>

                </Grid>
            </Grid>
            <Grid item md={4.5} p={1}>
                <Grid sx={{
                    background: 'white',
                    height: '100%'
                }}>
                </Grid>
            </Grid>
        </Grid>
    )
}