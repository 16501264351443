import GroupedBarChart from "../../../../../../../components/molecules/bar-chart";
import { addComma } from "../../../../../Monetise/useDashboard";
export const BarchartPromotions = ({dateLabels, promos, nonPromos}: any) => {
    var thresholdHighArray = [20000,30000,40000,50000,60000,10000];

    const getData = () => {
        const labels: any = dateLabels
        const datasets: any = [
            {
                label: 'Promotional',
                data: promos,
                backgroundColor: ['rgb(139, 151, 255)'],
                borderColor:['rgb(139, 151, 255)'],
                barPercentage: 0.4,
            },
            {
                label: 'Non-Promotional',
                data: nonPromos,
                backgroundColor: ['rgb(133, 216, 221)'],
                borderColor:['rgb(133, 216, 221)'],
                barPercentage: 0.4,
            },
        ]
        return {
            labels: labels,
            datasets: datasets
        }
    }

    const toptions = {
        chart: {
            type: 'bar',
            height: 350
        },
        colors: ["#1b3c7a", "#427ae3", "#3dc7d1", '#faa93e'],
        fill: {
            colors: ["#1b3c7a", "#427ae3", "#3dc7d1", '#faa93e']
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    fontColor: '#333',
                    usePointStyle: true,
                }
            },
            datalabels: {
                display: true,
                color: "black",
                formatter: function (context: any) {
                    if (typeof context === 'number') {
                        return addComma(context);
                    } else {
                        return context[1] - context[0]
                    }
                },
                anchor: "end",
                offset: 10,
                align: "start",

            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '96px',
                endingShape: 'rounded',
                barPercentage: 0.5,
                categorySpacing: 0,
            },
        },
        dataLabels: {
            enabled: false,

        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        scales: {
            y: {
                min: 0,
                title: {
                    display: true,
                    text: 'Net Sales',
                    color: 'black',
                    fontWeight: 700,
                    padding: 5
                },
                stacked: true,
                ticks: {
                    // stepSize: 100000// <----- This prop sets the stepSize
                }
            },
            x: {
                barPercentage: 0.5,
                categorySpacing: 0,
                stacked: true,
                grid: {
                    display: false,
                },
            },
        },
        tooltip: {
            y: {
                formatter: function (context: any) {
                    if (typeof context === 'number') {
                        return context;
                    } else {
                        return context[1] - context[0]
                    }
                }
            }
        },
    };
    return (
        <div style={{ height: '450px' }}>
            <GroupedBarChart {...{
                toptions,
                data: getData(),
                width: '1400%',
                height: 400
            }} />
        </div>
    )
}