import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ReactComponent as XylicLogo } from '../../../assets/svg/xylicLogo.svg';
import { ReactComponent as BottomLogo } from '../../../assets/svg/centerLogo.svg';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormInput from '../../../components/atoms/FormInputField';
import { CustomButton } from '../../../components/atoms/button/button';
import styles from './login.module.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const defaultTheme = createTheme();

export default function LoginPage() {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component='main'
        sx={{ height: '100vh' }}
        className={`font-poppins ${styles.mainCont}`}
      >

        <CssBaseline />

        <Grid
          item
          md={12}
          // className={styles.bgimage}
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#5B47F5',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Grid sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh'
          }}>
            <Box
              component='form'
              noValidate
              onSubmit={handleSubmit}
              display={'flex'}
              gap={'24px'}
              flexDirection={'column'}
              sx={{
                background: 'white',
                width: '540px',
                height: '563px'
              }}
              className={styles.mainLoginCont}
            >
              <p className={styles.mainLoginText}>Login to your account</p>
              <FormInput
                variant='filled'
                size='small'
                label='Email'
                sx={{}}
              />

              <Grid sx={{
                width: '100%'
              }}>
                <FormInput
                  variant='filled'
                  size='small'
                  label='Password'
                  sx={{}}
                />
                <Grid item display={"flex"} justifyContent={'start'} mt={1}>
                  <Typography
                    color={'rgb(95, 70, 254)'}
                    className='fontStyles'

                    sx={{ marginLeft: '0px', fontSize: '12px', cursor: 'pointer' }}
                  >
                    Forgot Password?
                  </Typography>
                </Grid>
              </Grid>

              <Button sx={{
                background: 'rgb(48, 79, 254) !important',
                borderRadius: '4px',
                width: '100%',
                marginTop: '50px',
                color: 'white !important'
              }}>Login</Button>

              <Grid container display={'flex'} justifyContent={'start'}>
                <Grid item>
                  <Typography fontWeight={500} className='fontStyles'>
                    New to Xylic data?
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    color={'rgb(95, 70, 254)'}
                    className='fontStyles'
                    sx={{ marginLeft: '4px',cursor:'pointer' }}
                    onClick={() => navigate('/register')}
                  >
                    Join now
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid sx={{
            position: 'fixed',
            bottom: 0,
            left: '40%',
            zIndex: 1
          }}>
            <BottomLogo />
          </Grid>
        </Grid>

      </Grid>
    </ThemeProvider>
  );
}
