import { ReactComponent as StarIcon } from '../../../../../assets/svg/start.svg';
import { ReactComponent as ExportIcon } from '../../../../../assets/svg/download.svg';
import { ReactComponent as ShareIcon } from '../../../../../assets/svg/share.svg';
import styles from '../customer-behaviour/styles.module.scss';
import { Grid } from '@mui/material';
import { ListCard, ListSegment } from './listCard';
import Select from '../../../../../components/atoms/Select';
import seggrid from '../../../../../assets/svg/seggrid.svg';
import edit from '../../../../../assets/svg/edit.svg';
import segStyles from './styles.module.scss'
import flowStart from '../../../../../assets/svg/flowStart.svg'
import arrow from '../../../../../assets/svg/lineArrow.svg'
import { useEffect, useState } from 'react';
import SubtabsHeader from '../../elements/Subtabs/SubtabsHeader';
import { useSelector } from 'react-redux';
import { getAutoStack, getSegmentProcessData } from '../../../../../services/insights';
import Loader from '../../../../../components/atoms/loader';
import Slider from '@mui/material/Slider';
const {
    reorder,
    reorderImmutable,
    reorderFromTo,
    reorderFromToImmutable
} = require("react-reorder");
const Reorder = require('react-reorder')
const move = require('lodash-move')

export const selectStyles = {
    color: '#fff !important',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    borderRadius: '8px',
    paddingTop: '3px',
    width: '100%',
    height: '36px',
    border: 'none',
    background: '#FFF !important',
    '& .MuiOutlinedInput-notchedOutline': {

        border: '1px solid rgb(94, 92, 113)',
        color: 'rgb(94, 92, 113)'

    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgb(94, 92, 113)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgb(94, 92, 113)',
    },
    '& .MuiSelect-outlined': {
        color: 'rgb(94, 92, 113)'
    },
}
export const menustyles = {
    borderRadius: '9px',
    border: '1px solid rgba(191, 191, 191, 0.25)',
    background: ' #FFF',
    '& .MuiMenuItem-root': {
        color: '#5F46FF',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        minwidth: '100%',
        borderRadius: '8px',
        paddingTop: '3px',
    },
}
export const Segmentation = () => {

    const [data, setData] = useState([
        { name: 'Time Shifting', id: 1 },
        { name: 'Average Order Value', id: 2 },
        { name: 'Trips', id: 3 },
        { name: 'Loyality Check', id: 4 }
    ]);
    const [minMax, setMinMax] = useState({min:0,max:0})
    const [avgminMax, setavgMinMax] = useState({min:0,max:0})
    const [checked, setChecked] = useState<boolean>(false);
    const [flowData, setFlowData] = useState<any>([]);
    const [autoStack, setAutoStack] = useState({})
    const [loader, setLoader] = useState(true)
    const [showManualStack, setShowManualStack] = useState(false)
    const [addLoader, setAddLoader] = useState(false)
    const [stackData, setStackData] : [any, any]= useState({})
    const processTabs = [
        { label: 'Select process', value: '' },
        { label: 'Time Shifting', value: 'Time Shifting' },
        //{ label: 'Brand Shifting', value: 'Brand Shifting' },
        { label: 'Average order value', value: 'Average order value' },
        { label: 'Trips', value: 'Trips' },
        { label: 'Demographics', value: 'Demographics' },
        { label: 'Loyalty check', value: 'Loyalty check' }
    ]

    const [brands, setBrands]  = useState([{ label: 'Select brand', value: '' }])
    const [categoryList, setCategoryList]  = useState([{ label: 'Select category', value: '' }])

    const timeSegmentsOne = [
        { label: 'Select time segment', value: '' },
        { label: '15 days', value: '15 days' },
        { label: '1 month', value: '1 month' },
        { label: '3 months', value: ' 3 months' },
        { label: '6 months', value: '6 months' }
    ]

    const place = [
        { label: 'Select place', value: '' },
        { label: 'Illinois', value: 'Illinois' },
        { label: 'Idaho', value: 'Idaho' },
        { label: 'Oregon', value: 'Oregon' },
        { label: 'Wisconsin', value: 'Wisconsin' }
    ]

    const gender = [
        { label: 'Select gender', value: '' },
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'Others', value: 'Others' },
    ]

    const ageGroup = [
        { label: 'Select age group', value: '' },
        { label: '5-18', value: '5-18' },
        { label: '18-25', value: '18-25' },
        { label: '25-50', value: '25-50' },
        { label: '50-70', value: '50-70' },
        { label: '70-100', value: '70-100' },
    ]


    const loyalty_scale  = [
        { label: 'High', value: 'High' },
        { label: 'Middle', value: 'Middle' },
        { label: 'Low', value: 'Low' },
    ]

    const outputs = [
        { label: 'Customers', value: 'customers' },
    ]

    const [formData, setFormData] = useState<any>({
        process: "",
        input: {
            brand: "",
            category: "",
            timeSegmentOne: "",
            place: "",
            gender: "",
            ageGroup: "",
            loyalty_scale: "",
            trips: 0,
            average_order_value: 0

        },
        output: 0
    });

    const [formData2, setFormData2] = useState<any>({});

    function array_move(arr: any, old_index: any, new_index: any) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.map((item: any, index: any) => {
            if (index === 0) {
                return {
                    ...item, id: (Math.random() * (40 - 20))
                }
            } else return item
        })
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        console.log(arr)
        return [...arr]; // for testing
    };

    const onReorder = (e: any, from: number, to: number) => {
        const findata = array_move(data, from, to)
        setData(findata);
    };

    const handleEnableCheck = (e: any) => {
        setChecked(e.target.checked);
    }

    const handleProcess = async (value: any) => {
        const data: any = await getSegmentProcessData({ path: value })
        if (data?.results?.length > 0) {
            const finData = data.results.map((item: any) => {
                return {
                    label: item,
                    value: item
                }
            })
            //setProcessData(finData)
        }
        else {
            const finData: any = []
            if (value === 'demography') {
                Object.keys((data?.results)).map((item) => {
                    finData.push({
                        name: item,
                        values: data?.results[item]?.map((item: any) => {
                            return {
                                label: item,
                                value: item
                            }
                        })
                    })
                })
            } else if (['average_order_value', 'trips'].includes(value)) {
                Object.keys((data?.results)).map((item) => {
                    finData.push({
                        label: data.results[item],
                        value: data.results[item]
                    })
                })
            }
            //setProcessData(finData)
        }
        setFormData({ ...formData, process: value })
    }




    const handleChangeFormData = (e: any, type: string, final = '') => {
        const value = e.target.value;
        switch (type) {
            case "process":
                handleProcess(value)
                break;
            case "brand":
                setFormData({ ...formData, selectBrand: value })
                break;
            case "category":
                setFormData({ ...formData, input: { ...formData.input, category: value } })
                break;
            case "timeSegmentOne":
                setFormData({ ...formData, input: { ...formData.input, timeSegmentOne: value } })
                break;
            case final:
                setFormData({ ...formData, selectBrand: value })
                break;
            case 'result':
                setFormData({ ...formData, result: value })
                break;
            case "output":
                setFormData({ ...formData, output_: value });
                break;
            case "place":
                setFormData({ ...formData, input: { ...formData.input, place: value } })
                break
            case "gender":
                setFormData({ ...formData, input: { ...formData.input, gender: value } })
                break
            case "ageGroup":
                setFormData({ ...formData, input: { ...formData.input, ageGroup: value } })
                break
            case "loyalty_scale":
                setFormData({ ...formData, input: { ...formData.input, loyalty_scale: value } })
                break
            case "trips":
                setFormData({ ...formData, input: { ...formData.input, trips: value } })
                break
            case "average_order_value":
                setFormData({ ...formData, input: { ...formData.input, average_order_value: value } })
                break
                
            default:
                break;
        }
    }
    const handleChangeFormData2 = (e: any, index: string, final = '') => {
        const value = e.target.value;
        const data={...formData2}
        data[index] = value
        setFormData2({...data});
    }


    const handleAddStack = () => {
        let isReset = false;
        if(formData?.process=="Time Shifting"){
            if(formData.input?.category && formData.input?.timeSegmentOne){
                setAddLoader(true)
                fetch(`https://api.xylicdata.com/segmention/manual_stack?item=${formData.input?.category}&process=timeshift&parameter=${formData.input?.timeSegmentOne}&users=${formData?.output}`).then((res)=>res.json()).then((res)=> {
                    console.log(res.CUSTOMERS)
                    setFormData({...formData, output: res.CUSTOMERS })
                    setFlowData([...flowData, {
                        ...formData,
                        id: `${flowData.length + 1}: Time shifting`,
                        num: res.CUSTOMERS
                    }]);
                    setStackData(res)
                     setShowManualStack(true);
                     isReset = true
                     setFormData({ ...formData, process: '', input: { ...formData.input, gender: "",  brand: "",  category: "", timeSegmentOne: "" , place: "", ageGroup: "" } })

                }).then(()=> {setAddLoader(false);})
                
            }
        }else   if(formData?.process=="Demographics"){
            if(formData.input?.category && formData.input?.gender && formData.input?.place && formData.input?.ageGroup){
                setAddLoader(true)
                fetch(`https://api.xylicdata.com/segmention/manual_stack?item=${formData.input?.category}&process=demography&parameter=${formData.input?.place}_${formData.input?.gender}_${formData.input?.ageGroup}&users=${formData?.output}`).then((res)=>res.json()).then((res)=> {
                    console.log(res.CUSTOMERS)
                    setFormData({...formData, output: res.CUSTOMERS })
                    setFlowData([...flowData, {
                        ...formData,
                        id: `${flowData.length + 1}: Demographics`,
                        num: res.CUSTOMERS
                    }]);
                    setStackData(res)
                     setShowManualStack(true);
                     isReset = true
                     setFormData({ ...formData, process: '', input: { ...formData.input, gender: "",  brand: "",  category: "", timeSegmentOne: "" , place: "", ageGroup: "" } })

                }).then(()=> {setAddLoader(false);})
            }
        } else if(formData?.process == "Loyalty check"){
            console.log("rty", formData.input?.category, formData.input?.gender , formData.input?.place, formData.input?.ageGroup)
            if(formData.input?.category && formData.input?.loyalty_scale){
                setAddLoader(true)
                fetch(`https://api.xylicdata.com/segmention/manual_stack?item=${formData.input?.category}&process=loyality_check&parameter=${formData.input?.loyalty_scale}&users=${formData?.output}`).then((res)=>res.json()).then((res)=> {
                    console.log(res.CUSTOMERS)
                    setFormData({...formData, output: res.CUSTOMERS })
                    setFlowData([...flowData, {
                        ...formData,
                        id: `${flowData.length + 1}: Loyalty check`,
                        num: res.CUSTOMERS
                    }]);
                    setStackData(res)
                     setShowManualStack(true);
                     isReset = true
                     setFormData({ ...formData, process: '', input: { ...formData.input, gender: "",  brand: "",  category: "", timeSegmentOne: "" , place: "", ageGroup: "" } })

                }).then(()=> {setAddLoader(false);})
            }
        }else if(formData?.process == "Trips"){
            console.log("rty", formData.input?.category, formData.input?.gender , formData.input?.place, formData.input?.ageGroup)
            if(formData.input?.category){
                setAddLoader(true)
                fetch(`https://api.xylicdata.com/segmention/manual_stack?item=${formData.input?.category}&process=trips&parameter=${formData.input?.trips}&users=${formData?.output}`).then((res)=>res.json()).then((res)=> {
                    console.log(res.CUSTOMERS)
                    setFormData({...formData, output: res.CUSTOMERS })
                    setFlowData([...flowData, {
                        ...formData,
                        id: `${flowData.length + 1}: Trips`,
                        num: res.CUSTOMERS
                    }]);
                    setStackData(res)
                     setShowManualStack(true);
                     isReset = true
                     setFormData({ ...formData, process: '', input: { ...formData.input, gender: "",  brand: "",  category: "", timeSegmentOne: "" , place: "", ageGroup: "" } })

                }).then(()=> {setAddLoader(false);})
            }
        }else if(formData?.process=="Average order value"){
            if(formData.input?.category){
                setAddLoader(true)
                fetch(`https://api.xylicdata.com/segmention/manual_stack?item=${formData.input?.category}&process=average_order_value&parameter=${formData.input?.average_order_value}&users=${formData?.output}`).then((res)=>res.json()).then((res)=> {
                    console.log(res.CUSTOMERS)
                    setFormData({...formData, output: res.CUSTOMERS })
                    setFlowData([...flowData, {
                        ...formData,
                        id: `${flowData.length + 1}: Average order value`,
                        num: res.CUSTOMERS
                    }]);
                    setStackData(res)
                     setShowManualStack(true);
                     isReset = true
                     setFormData({ ...formData, process: '', input: { ...formData.input, gender: "",  brand: "",  category: "", timeSegmentOne: "" , place: "", ageGroup: "" } })

                }).then(()=> {setAddLoader(false);})
            }
        }

        if(isReset){
            console.log('rodddd')
            setFormData({ ...formData, process: '', input: { ...formData.input, gender: "",  brand: "",  category: "", timeSegmentOne: "" , place: "", ageGroup: "" } })
        }
    }


    const FlowList = ({ item, arrow2 = true }: any) => {
        const styles = {
            container: {
                background: '#FFF',
                padding: '16px',
                borderRadius: '5px',
                boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.05)',
                width: 'fit-content',
            },
            content: {
                display: 'flex',
                gap: '8px',
            },
            image: {
                width: '20px', // Adjust the width as needed
                height: '20px', // Adjust the height as needed
            },
            title: {
                fontSize: '14px',
                fontWeight: 400,
                margin: 0,
            },
            value: {
                fontSize: '14px',
                fontWeight: 500,
                marginTop: '8px',
                marginLeft: '20px',
            },
        };
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '30%' }}>
                <div style={styles.container}>
                    <div style={styles.content}>
                        <img src={flowStart} alt='' style={styles.image} />
                        <h3 style={styles.title}>{item.id}</h3>
                    </div>
                    <h2 style={styles.value}>{item.num}</h2>
                </div>
                {arrow2 && <img src={arrow} alt='' />}
            </div>
        )
    }

    const category = useSelector((state: any) => state?.sidebarContext?.category);
    const delivery = useSelector((state: any) => state?.sidebarContext?.delivery);
    const location = useSelector((state: any) => state?.sidebarContext?.location);
    const age_group = useSelector((state: any) => state?.sidebarContext?.age_group);
    const products = useSelector((state: any) => state?.sidebarContext?.products);

    const autoStackdata = async () => {
        try{
            const response: any = await getAutoStack({ products, category })
            console.log("rojax", response)
            setAutoStack(response)
            setLoader(false)
            console.log("on efcx")
        }catch(e){
           console.error("Vodka", e) 
        }
    }

    useEffect(()=>{
        
        fetch('https://api.xylicdata.com/utils/items_for_segment_brand?offset=0&limit=10').then((res)=> res.json()).then((res)=> {
            const x = res.results.map((v: any)=> {
                return { label: v, value: v }
            })
            setBrands([{ label: 'Select brand', value: '' } ,...x ])
        })
        fetch('https://api.xylicdata.com/utils/items_for_segment_category?offset=0&limit=10').then((res)=> res.json()).then((res)=> {
            const x = res.results.map((v: any)=> {
                return { label: v, value: v }
            })
            setCategoryList([{ label: 'Select category', value: '' } ,...x ])
        })
        fetch('https://api.xylicdata.com/process/average_order_value').then((res)=> res.json()).then((res: any)=> {
            setavgMinMax( {min:res?.results?.min, max: 
                res?.results?.max})    
        })
    }, [])

    useEffect(() => {
        if (category?.length>0) {
            setLoader(true)
            autoStackdata()
        }
    }, [products, category, delivery, location, age_group])


    return (
        <>
            <SubtabsHeader />
            <div style={{ padding: '16px' }}>
                <div style={{ background: '#FFF' }}>
                    <div style={{ borderBottom: '1px solid rgb(95, 70, 234,0.5)', padding: '10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '6px', marginTop: '10px' }}>
                            <div style={{ display: 'flex', gap: '18px', justifyContent: 'center', alignItems: 'center' }}>
                                <h2 className={styles.loyalityHeader} style={{ width: '30px' }}>Segmentation</h2>
                                <div style={{ width: '200px', display: 'flex', gap: '10px' }}>
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'center' }}>
                                <StarIcon /> <ExportIcon /> <ShareIcon />
                            </div>
                        </div>
                    </div>
                    <Grid container className={segStyles.scrollContainerseg}>
                        <Grid xl={9.5} lg={9} sx={{ padding: '16px', borderRight: '1px solid rgb(229, 225, 236)' }}>
                            <div>
                                {false && <p style={{ fontSize: '14px', fontWeight: 400 }}>Find out how many customers were acquired, lost or remained loyal to a brand between periods.</p>}
                                {!loader && <ListCard {...{ autoStack }} />}
                                {loader && <div className='flex items-center justify-center my-2'><Loader /> </div>}
                                <div style={{ display: 'flex', gap: '12px' }}>
                                    <input type="checkbox" checked={checked} onChange={handleEnableCheck} style={{ cursor: 'pointer' }} />
                                    <p style={{ fontSize: '14px', fontWeight: 500, color: 'rgb(91, 71, 245)' }}>Enable Manual Stack</p>
                                </div>
                                <div style={{ background: 'rgb(247, 247, 247)', minHeight: '400px', marginTop: '10px', borderRadius: '5px', padding: '12px' }} className={styles.heightContainer}>
                                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                                        {showManualStack && <ListSegment {...{ item: { name: 'Manual Stack', aov: stackData?.AOV || "", trips: stackData.TRIPS || "", customers:  stackData.CUSTOMERS || ""}, }} />}
                                    </div>
                                    <div style={{ marginTop: -120 }}>
                                        {flowData.map((item: any, index: number) => {
                                            return <FlowList {...{ item, arrow2: flowData?.length === index + 1 ? false : true }} />
                                        })}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid xl={2.5} lg={3} className='right-inputs' sx={{ pointerEvents: checked ? 'all' : 'none', opacity: checked ? 1 : 0.4 }}>
                            <Grid sx={{ padding: '16px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                <p style={{ fontSize: '14px', fontWeight: 500 }}>Process</p>
                                <Select
                                    value={formData?.process}
                                    options={processTabs}
                                    variant="outlined"
                                    size='small'
                                    multiple={false}
                                    optionsType="default"
                                    onChange={(e: any) => handleChangeFormData(e, 'process')}
                                    sx={{
                                        ...selectStyles
                                    }}
                                    Menustyle={{
                                        ...menustyles
                                    }}
                                />
                                <div style={{ pointerEvents: formData?.process ? 'all' : 'none', opacity: formData?.process ? 1 : 0.4, display: "flex", gap: '10px', flexDirection: "column" }}>
                                    <p style={{ fontSize: '14px', fontWeight: 500 }}>Input</p>
                                    <Select
                                        value={formData?.brand || ''}
                                        options={[
                                            { label: 'Select', value: '' },
                                            { label: 'Brand', value: 'brand_name' },
                                            { label: 'Category', value: 'category' }
                                        ]}
                                        variant="outlined"
                                        size='small'
                                        multiple={false}
                                        optionsType="default"
                                        onChange={(e: any) => handleChangeFormData(e, 'brandType')}
                                        sx={{
                                            ...selectStyles
                                        }}
                                        Menustyle={{
                                            ...menustyles
                                        }}
                                    />
                                    <Select
                                        value={formData?.selectBrand || ''}
                                        options={[...brands]}
                                        variant="outlined"
                                        size='small'
                                        multiple={false}
                                        optionsType="default"
                                        onChange={(e: any) => handleChangeFormData(e, 'brand')}
                                        sx={{
                                            ...selectStyles
                                        }}
                                        Menustyle={{
                                            ...menustyles
                                        }}
                                    />
                                    <Select
                                        value={formData?.input?.category}
                                        options={categoryList}
                                        variant="outlined"
                                        size='small'
                                        multiple={false}
                                        optionsType="default"
                                        onChange={(e: any) => handleChangeFormData(e, 'category')}
                                        sx={{
                                            ...selectStyles
                                        }}
                                        Menustyle={{
                                            ...menustyles
                                        }}
                                    />
                                    {formData?.process == "Time Shifting" && <Select
                                        value={formData?.input?.timeSegmentOne}
                                        options={timeSegmentsOne}
                                        variant="outlined"
                                        size='small'
                                        multiple={false}
                                        optionsType="default"
                                        onChange={(e: any) => handleChangeFormData(e, 'result')}
                                        sx={{
                                            ...selectStyles
                                        }}
                                        Menustyle={{
                                            ...menustyles
                                        }}
                                    />}
                                           {formData?.process == "Demographics" && <Select
                                        value={formData?.input?.place}
                                        options={place}
                                        variant="outlined"
                                        size='small'
                                        multiple={false}
                                        optionsType="default"
                                        onChange={(e: any) => handleChangeFormData(e, 'place')}
                                        sx={{
                                            ...selectStyles
                                        }}
                                        Menustyle={{
                                            ...menustyles
                                        }}
                                    />}
                                           {formData?.process == "Demographics" && <Select
                                        value={formData?.input?.gender}
                                        options={gender}
                                        variant="outlined"
                                        size='small'
                                        multiple={false}
                                        optionsType="default"
                                        onChange={(e: any) => handleChangeFormData(e, 'gender')}
                                        sx={{
                                            ...selectStyles
                                        }}
                                        Menustyle={{
                                            ...menustyles
                                        }}
                                    />}
                                           {formData?.process == "Demographics" && <Select
                                        value={formData?.input?.ageGroup}
                                        options={ageGroup}
                                        variant="outlined"
                                        size='small'
                                        multiple={false}
                                        optionsType="default"
                                        onChange={(e: any) => handleChangeFormData(e, 'ageGroup')}
                                        sx={{
                                            ...selectStyles
                                        }}
                                        Menustyle={{
                                            ...menustyles
                                        }}
                                    />}
                                       {formData?.process == "Loyalty check" && <Select
                                        value={formData?.input?.loyalty_scale}
                                        options={loyalty_scale}
                                        variant="outlined"
                                        size='small'
                                        multiple={false}
                                        optionsType="default"
                                        onChange={(e: any) => handleChangeFormData(e, 'loyalty_scale')}
                                        sx={{
                                            ...selectStyles
                                        }}
                                        Menustyle={{
                                            ...menustyles
                                        }}
                                    />}
                                    {formData?.process == "Trips" && <><span>Select trips count</span><div className='mx-4'> 
                                    
                                    <Slider
                                        aria-label="Trips"
                                        defaultValue={formData.input.trips}
                                        valueLabelDisplay="auto"
                                        marks
                                        onChange={(e: any) => handleChangeFormData(e, 'trips')}
                                        min={minMax.min}
                                        max={minMax.max}
                                    /></div></>}
                                     {formData?.process == "Average order value" && <><span>Select avg order value</span><div className='mx-4'> 
                                    
                                    <Slider
                                        aria-label="AVG order value"
                                        defaultValue={formData.input.average_order_value}
                                        valueLabelDisplay="auto"
                                        marks
                                        onChange={(e: any) => handleChangeFormData(e, 'average_order_value')}
                                        min={avgminMax.min}
                                        max={avgminMax.max}
                                    /></div></>}
                                    
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '5px',
                                    pointerEvents: formData?.process ? 'all' : 'none',
                                    opacity: formData?.process ? 1 : 0.4,
                                }}>
                                    <p style={{ fontSize: '14px', fontWeight: 500 }}>Output</p>
                                    <Select
                                        value={formData?.output_}
                                        options={outputs}
                                        variant="outlined"
                                        size='small'
                                        multiple={false}
                                        optionsType="default"
                                        onChange={(e: any) => handleChangeFormData(e, 'output')}
                                        sx={{
                                            ...selectStyles
                                        }}
                                        Menustyle={{
                                            ...menustyles
                                        }}
                                    />
                                </div>
                                
                                {addLoader ?<div className='flex w-full items-center justify-center'> <Loader /> </div>:<p style={{
                                    textDecoration: 'underline',
                                    color: 'rgb(95, 70, 254)',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    display: 'flex',
                                    justifyContent: 'end',
                                    cursor: "pointer",
                                    pointerEvents: formData?.process ? 'all' : 'none',
                                    opacity: formData?.process ? 1 : 0.4,
                                }} onClick={handleAddStack}>+ Add</p>}
                            </Grid>
                            <Grid mt={3} borderBottom={'1px solid rgb(229, 225, 236)'}>
                                <Reorder
                                    reorderId="my-list" // Unique ID that is used internally to track this list (required)
                                    reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
                                    // getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
                                    component="div" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
                                    placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
                                    draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                                    lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
                                    holdTime={500} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
                                    touchHoldTime={500} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
                                    mouseHoldTime={200} // Hold time before dragging begins with mouse (optional), defaults to holdTime
                                    onReorder={onReorder} // Callback when an item is dropped (you will need this to update your state)
                                    autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
                                    disabled={false} // Disable reordering (optional), defaults to false
                                    disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
                                    placeholder={
                                        <div className="custom-placeholder" /> // Custom placeholder element (optional), defaults to clone of dragged element
                                    }
                                >
                                    {flowData.map((item: any, index: number) => {
                                        return (
                                            <Grid borderTop={"1px solid rgb(229, 225, 236)"}>
                                                <Grid display={'flex'} justifyContent={"space-between"} padding={"14px"}>
                                                    <Grid display={'flex'} gap={'16px'}>
                                                        <img src={seggrid} alt='' />
                                                        <h2 style={{ fontSize: '12px', fontFamily: 'Poppins' }}>{item.process}</h2>
                                                    </Grid>
                                                    <img src={edit} alt='' />
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Reorder >
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}