import { useState } from "react";
import styles from "./insights.module.scss";
import { useSelector } from "react-redux";
import { SearchInput } from "../../../components/atoms/search";
import { ReactComponent as PlusIcon } from "../../../assets/svg/plus.svg";
import { ReactComponent as StarIcon } from "../../../assets/svg/start.svg";
import { ReactComponent as ExportIcon } from "../../../assets/svg/download.svg";
import { ReactComponent as ShareIcon } from "../../../assets/svg/share.svg";
import { ReactComponent as BackArrowIcon } from "../../../assets/svg/backarr.svg";
import { ReactComponent as DownArrowIcon } from "../../../assets/svg/downarr.svg";
import ListTabs from "./elements/listTab";
import Select from "../../../components/atoms/Select";
import { DataTable } from "./elements/dataTable";
import Pagination from "../../../components/molecules/Pagination";
import { ListView } from "./elements/list-view/ListView";
import { useInsights } from "./useInsights";
import SubtabsHeader from "./elements/Subtabs/SubtabsHeader";
import CommonTable from "../../../components/organisms/CommonTable";
import { ActiveCol } from "./elements/dataTable";
import HorizontalOrgChart from '../../../components/molecules/horizontal-org-chart';
import Loader from "../../../components/atoms/loader";
export const Insights = () => {
  const subTab = useSelector((state: any) => state?.headerContext?.subHeader);
  const [listSelTab, setListSelTab] = useState("1");
  const brandsDropdown = [{ label: "Brands", value: "brand_name" },
  { label: "Brand Groups", value: "brand_group" },
  { label: "Product Category", value: "product_category" }
  ]

  const volumeDropdown = [{ label: "calculated in volume", value: "volume" },
  { label: "calculated in amount", value: "amount" }
  ]

  const { data, handleRowClick, row, detailed, timeLine, handleBack, handleChange, filter, setPage, page, loader, treeData, chartLoader } = useInsights();

  return (
    <>
      <div className={styles.insightsContainer}>
        <SubtabsHeader />
        {subTab === "Best Sellers" && (
          <div style={{ padding: "16px", paddingTop: "0px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: "265px" }}>
                <SearchInput />
              </div>
              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
              >
                <div style={{ width: '100%' }}>
                  <Select
                    value={filter?.timeline}
                    options={timeLine}
                    variant="outlined"
                    size='small'
                    multiple={false}
                    optionsType="default"
                    name="timeline"
                    onChange={(e: any) => handleChange(e)}
                    IconComponent={() => <DownArrowIcon fill="#fff" />}
                    sx={{
                      color: '#fff !important',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '24px',
                      borderRadius: '8px',
                      paddingTop: '3px',
                      width: '223px',
                      height: '37px',
                      border: 'none',
                      background: '#5F46FF !important',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                        color: '#fff !important',

                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '& .MuiSelect-outlined': {
                        color: '#fff !important',
                      },
                    }}
                    Menustyle={{
                      borderRadius: '4px',
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3),0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
                      background: '#FFF',
                      '& .MuiMenuItem-root': {
                        color: '#5F46FF',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        minwidth: '100%',
                        borderRadius: '8px',
                        paddingTop: '3px',
                        borderBottom: '1px solid rgb(73, 69, 79)',
                      },
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ListTabs
                    {...{ selTab: listSelTab, setSelTab: setListSelTab }}
                  />
                </div>
              </div>
            </div>
            {!detailed && (
              <div style={{ background: "white" }}>
                <div style={{ borderBottom: "1px solid rgb(95, 70, 234,0.5)" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "6px",
                      marginTop: "10px",
                      padding: "10px",
                      paddingLeft: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "18px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h2
                        className={styles.bestSellingHeader}
                        style={{ width: "100px" }}
                      >
                        Best Selling
                      </h2>
                      <div
                        style={{ width: "200px", display: "flex", gap: "10px" }}
                      >
                        <Select
                          value={filter.based_on}
                          options={brandsDropdown}
                          variant="filled"
                          size="small"
                          name="based_on"
                          multiple={false}
                          optionsType="default"
                          onChange={(e: any) => handleChange(e)}
                          itemHeight={30}
                          itemPaddingTop={4}
                          itemWidth={15}
                          sx={{
                            color: "#5F46FF",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "24px",
                            minwidth: "100%",
                            borderRadius: "8px",
                            paddingTop: "3px",
                            "& .MuiSelect-filled": {
                              border: "1px solid #5F46FF",
                              color: "#5F46FF",
                              backgroundColor: "#5F46FF",
                            },
                          }}
                          Menustyle={{
                            borderRadius: "9px",
                            border: "1px solid rgba(191, 191, 191, 0.25)",
                            background: " #FFF",
                            "& .MuiMenuItem-root": {
                              color: "#5F46FF",
                              fontFamily: "Inter",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "24px",
                              minwidth: "100%",
                              borderRadius: "8px",
                              paddingTop: "3px",
                            },
                          }}
                        />
                        <Select
                          value={filter.volume}
                          options={volumeDropdown}
                          variant="filled"
                          size="small"
                          multiple={false}
                          optionsType="default"
                          itemHeight={30}
                          itemPaddingTop={4}
                          onChange={(e: any) => handleChange(e)}
                          itemWidth={15}
                          name="volume"
                          sx={{
                            color: "#5F46FF",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "24px",
                            minwidth: "300px !important",
                            borderRadius: "8px",
                            paddingTop: "3px",
                            "& .MuiSelect-outlined": {
                              display: "flex",
                            },
                            "& .MuiOutlinedInput-root:hover fieldset": {
                              borderColor: "#5F46FF",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                              borderRadius: "8px",
                              borderTopRightRadius: "0px",
                              borderBottomRightRadius: "0px",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #5F46FF",
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Pagination {...{ setPage, page, count: data.total_count }} /> <PlusIcon /> <StarIcon /> <ExportIcon />{" "}
                      <ShareIcon />
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "1px" }}>
                  {listSelTab === "1" ? <DataTable handleRowClick={handleRowClick} data={data?.results} filter={filter} loader={loader} page={page} /> : <ListView handleClick={handleRowClick} data={data?.results} filter={filter} loader={loader} />}
                </div>
              </div>
            )}
            {detailed && (
              <div style={{ background: "white", borderRadius: "5px" }}>
                <div style={{ borderBottom: "1px solid rgb(95, 70, 234,0.5)", borderRadius: "5px 5px 0px 0px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      paddingLeft: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "18px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <BackArrowIcon style={{ cursor: "pointer" }} onClick={handleBack} />
                      <h2 style={{
                        color: "rgb(19, 15, 53)",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0.5px"
                      }}
                      >
                        {row?.name} - Sales diagnostic tree
                      </h2>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ExportIcon />{" "}
                      <ShareIcon />
                    </div>
                  </div>
                </div>
                <div style={{
                  height: "calc(100vh - 250px)"
                }}>
                  <CommonTable
                    {...{
                      cols: ActiveCol({ filter }),
                      data: [row],
                      tableData: [row],
                      selection: false,
                      headStyle: {
                        border: "none !important",
                        "& .MuiTableCell-head": {
                          height: "35px !important",
                        }
                      },
                      rowStyle: {
                        border: "none !important",
                        "&.MuiTableCell-body": {
                          height: "15px !important",
                        }
                      },
                      tableContainerStyles: {
                        height: 'max-content !important'
                      }
                    }}
                  />
                  {!chartLoader ? <HorizontalOrgChart
                    {...{
                      style: {
                        width: 'calc(100% - 20px)',
                        height: 'calc(100% - 100px)',
                        margin: '10px'
                      },
                      translate: {
                        x: 150,
                        y: 200
                      },
                      treeData
                    }}
                  /> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Loader size={40} />
              </div>}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
