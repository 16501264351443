import CommonTable from "../../../../../components/organisms/CommonTable";

const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}
export const ActiveCol: any = ({ inner=false }: any) => {
    const columns = [
        {
            id: 'ad',
            columnLabel: 'Ad',
            sortLabel: true,
            style: {
                width: 300,
                textAlign: 'left',
                paddingLeft: '16px'
            },
            render: (row: any, index: any) => (
                <p onClick={() => { }} style={{ paddingLeft: '16px', textDecoration: "underline", color: '#5B47F5', cursor: 'pointer' }}>
                    {row?.ad}
                </p>
            ),
        },
        {
            id: 'impressions',
            columnLabel: 'Impressions',
            style: {
              //  width: '300px'
            },
            render: (row: any) => (
                <p color='#051433' onClick={() => { }} style={{ ...style }}>
                    {row.impressions}
                </p>
            ),
        },
        {
            id: 'views',
            columnLabel: 'Views',
            style: {
                //textAlign: 'left',
           
            },
            render: (row: any) => (
                <p color='#051433' onClick={() => { }} style={{ ...style }}>
                    {row.views}
                </p>
            ),
        },
        {
            id: 'clicks',
            columnLabel: 'Clicks',
            //minWidth: 40,
            render: (row: any) => (
                <p color='#051433' onClick={() => { }} style={{ ...style }}>
                    {row?.clicks}
                </p>
            ),
        },
        {
            id: 'conversions',
            columnLabel: 'Conversions',
            style: {
              //  width: '60px',
                textAlign: 'center'
            },
            render: (row: any) => (
                <p color='#051433' onClick={() => { }} style={{ ...style }}>
                    {row?.conversions}
                </p>
            ),
        },
        {
            id: 'ctr',
            columnLabel: 'CTR',
           // minWidth: 40,
            textAlign: 'left',
            render: (row: any) => (
                <p color='#051433' onClick={() => { }} style={{ ...style }}>
                    {row?.ctr}
                </p>
            ),
        },
    ]
    if (inner) {
        columns.splice(1,0, {
            id: 'rows',
            columnLabel: 'Roas',
           /* style: {
                width: '100px'
            },*/
            render: (row: any) => (
                <p color='#051433' onClick={() => { }} style={{ ...style }}>
                    {row.rows || 4000}
                </p>
            ),
        },)
    }
    return columns
}

export const rows = [
    { ad: '‘Welcome back’ 200 X 200 banner', impressions: '24,755', views: '24,755', clicks: '143', conversions: '84', ctr: '30.5%' },
    { ad: '‘Welcome back’ 200 X 200 banner', impressions: '12,755', views: '21,755', clicks: '233', conversions: '84', ctr: '24.5%' },
    { ad: '‘Welcome back’ 200 X 200 banner', impressions: '22,755', views: '12,755', clicks: '343', conversions: '84', ctr: '12.2%' },
    { ad: '‘Welcome back’ 200 X 200 banner', impressions: '24,755', views: '1755', clicks: '243', conversions: '81', ctr: '22.1%' },
    { ad: '‘Welcome back’ 200 X 200 banner', impressions: '22,755', views: '14,755', clicks: '243', conversions: '84', ctr: '23.6%' },
    { ad: '‘Welcome back’ 200 X 200 banner', impressions: '22,755', views: '44,755', clicks: '243', conversions: '83', ctr: '25.9%' },
    { ad: '‘Welcome back’ 200 X 200 banner', impressions: '24,755', views: '14,755', clicks: '243', conversions: '84', ctr: '31.7%' },
    { ad: '‘Welcome back’ 200 X 200 banner', impressions: '33,755', views: '24,755', clicks: '250', conversions: '80', ctr: '27.2%' },
    { ad: '‘Welcome back’ 200 X 200 banner', impressions: '24,755', views: '44,755', clicks: '443', conversions: '84', ctr: '29.8%' },
    { ad: '‘Welcome back’ 200 X 200 banner', impressions: '24,755', views: '64,755', clicks: '243', conversions: '84', ctr: '19.5%' },
    { ad: '‘Welcome back’ 200 X 200 banner', impressions: '24,755', views: '23,755', clicks: '143', conversions: '84', ctr: '23.1%' },
    { ad: '‘Welcome back’ 200 X 200 banner', impressions: '21,755', views: '24,755', clicks: '303', conversions: '81', ctr: '17.7%' },
    { ad: '‘Welcome back’ 200 X 200 banner', impressions: '24,755', views: '24,755', clicks: '123', conversions: '84', ctr: '16.8%' },
    { ad: '‘Welcome back’ 200 X 200 banner', impressions: '24,755', views: '24,755', clicks: '223', conversions: '84', ctr: '26.3%' },
    { ad: '‘Welcome back’ 200 X 200 banner', impressions: '24,755', views: '24,755', clicks: '223', conversions: '79', ctr: '13.5%' },
]

export const MeasurementAndAnalysisTable = ({ data, handleRowClick }: any) => {
    return (
        <div>
            <CommonTable {...{
                cols: ActiveCol({inner:false}),
                data: rows,
                tableData: rows,
                selection: false,
                onRowClick: (row: any) => handleRowClick(row, "measurement"),
                tableContainerStyles: {
                    "& .MuiTableCell-head": {
                        textTransform: 'none'
                    }
                }
            }} />
        </div>
    )
}