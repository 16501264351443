import { useState } from "react"

export const useMedia = () => {
    const [page, setPage] = useState(0);
    const [row, setRow] = useState<any>({});
    const [manageRow, setManageRow] = useState<any>({});
    const [createForm, setCreateForm] = useState(false);
    const [detailed, setDetailed] = useState<{
        manage: boolean,
        measurement: boolean
    }>({
        manage: false,
        measurement: false
    });
    const [filter, setFilter] = useState({
        based_on: 'brand_name',
        timeline: 0,
        volume: 'amount'
    });
    const [listSelTab, setListSelTab] = useState("2");

    const handleRowClick = (row: any, rowType: string) => {
        console.log({row, rowType});
        if(rowType==='manage'){
            setManageRow(row);
            setDetailed({...detailed, manage: true, measurement: false});
        }else{
            setRow(row);
            setDetailed({...detailed, measurement: true, manage: false});
        }
    }

    const handleCreateForm = () => {
        setCreateForm(true);
    }

    const handleBack = () => {
        setCreateForm(false);
    }

    return {
        page,
        setPage,
        handleRowClick,
        createForm,
        setCreateForm,
        handleCreateForm,
        handleBack,
        row,
        filter,
        detailed,
        setDetailed,
        manageRow,
        listSelTab,
        setListSelTab
    }
}