"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Node = void 0;
const mobx_1 = require("mobx");
const uuid_1 = require("uuid");
class Node {
    constructor() {
        /** Identifier */
        this.id = (0, uuid_1.v4)();
        /** Node Name */
        this.name = this.constructor.name;
        /** Arbitrary Data Store */
        this.data = {};
        (0, mobx_1.makeObservable)(this, {
            id: mobx_1.observable,
            data: mobx_1.observable,
            connections: mobx_1.computed,
            dispose: mobx_1.action
        });
    }
    /** Associated connections */
    get connections() {
        return [...Object.values(this.inputs), ...Object.values(this.outputs)]
            .flatMap(port => ('connection' in port ? [port.connection] : port.connections))
            .filter((connection) => Boolean(connection));
    }
    /** Disposes the Node */
    dispose() {
        for (const input of Object.values(this.inputs)) {
            input.dispose();
        }
        for (const output of Object.values(this.outputs)) {
            output.dispose();
        }
    }
}
exports.Node = Node;
