import React, { useEffect } from "react";
import styles from "./dasboard.module.scss";
import { Button, Typography } from "@mui/material";
import { ReactComponent as PlusIcon } from "../../assets/svg/btnplus.svg";
// import { ReactComponent as ClipIcon } from "../../assets/svg/clip.svg";
// import { ReactComponent as MoreIcon } from "../../assets/svg/morehorizontal.svg";
import { ReactComponent as UpIcon } from "../../assets/svg/uparr.svg";
import { ReactComponent as DownIcon } from "../../assets/svg/downicon.svg";
import CardContainer from "./components/CardContainer";
import SmallCard from "./components/SmallCard";
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { ArialChart } from "./components/arial-chart";
import { useDispatch } from "react-redux";
import { onMainTabChange } from "../../store/header/reducer";

const Dashboard = () => {
  const cardsData: any = [
    {
      title: "Current month revenue",
      content: "$726,318",
      footer: (
        <div className={styles["card-footer"]}>
          <UpIcon />
          <p
            style={{
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "14px",
              color: "#03A01C",
            }}
          >
            10%{" "}
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "14px",
                color: "#8B8B8B",
              }}
            >
              vs prev = $726,200
            </span>
          </p>
        </div>
      ),
    },
    {
      title: "No. of customers",
      content: "18",
      footer: (
        <div className={styles["card-footer"]}>
          <UpIcon />
          <p
            style={{
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "14px",
              color: "#03A01C",
            }}
          >
            10%{" "}
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "14px",
                color: "#8B8B8B",
              }}
            >
              vs prev = 5
            </span>
          </p>
        </div>
      ),
    },
    {
      title: "Avg. modules per customer",
      content: "3",
      footer: (
        <div className={styles["card-footer"]}>
          <UpIcon />
          <p
            style={{
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "14px",
              color: "#03A01C",
            }}
          >
            10%{" "}
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "14px",
                color: "#8B8B8B",
              }}
            >
              vs prev = 2
            </span>
          </p>
        </div>
      ),
    },
    {
      title: "No. of categories monetized",
      content: "42",
      footer: (
        <div className={styles["card-footer"]}>
          <UpIcon />
          <p
            style={{
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "14px",
              color: "#03A01C",
            }}
          >
            10%{" "}
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "14px",
                color: "#8B8B8B",
              }}
            >
              vs prev = 5
            </span>
          </p>
        </div>
      ),
    },
    {
      title: "Avg. category per customer",
      content: "5",
      footer: (
        <div className={styles["card-footer"]}>
          <UpIcon />
          <p
            style={{
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "14px",
              color: "#03A01C",
            }}
          >
            10%{" "}
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "14px",
                color: "#8B8B8B",
              }}
            >
              vs prev = 5
            </span>
          </p>
        </div>
      ),
    },
    {
      title: "Most profitable category",
      content: "Coffee",
      footer: (
        <div className={styles["card-footer"]}>
          <p
            style={{
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "14px",
              color: "#03A01C",
            }}
          >
            vs prev = Cereals
          </p>
        </div>
      ),
    }
  ];

  const optimiseData:any = [
    {
      title: "Max opportunity",
      content: "Insights",
      footer: (
        <div className={styles["card-footer"]}>
          <UpIcon />
          <p
            style={{
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "14px",
              color: "#03A01C",
            }}
          >
            vs prev = Sharing
          </p>
        </div>
      ),
    },
    {
      title: "Most profitable module",
      content: "Media",
      footer: (
        <div className={styles["card-footer"]}>
          <UpIcon />
          <p
            style={{
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "14px",
              color: "#03A01C",
            }}
          >
            vs prev = Sharing
          </p>
        </div>
      ),
    },
    {
      title: "Max opportunity",
      content: "Face care",
      footer: (
        <div className={styles["card-footer"]}>
          <UpIcon />
          <p
            style={{
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "14px",
              color: "#03A01C",
            }}
          >
            10%{" "}
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "14px",
                color: "#8B8B8B",
              }}
            >
              vs prev = $726,200
            </span>
          </p>
        </div>
      ),
    }
  ]

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onMainTabChange({ name: "Dashboard"}));
  }, [])

  return (
    <div style={{ padding: "16px" }}>
      <Button
        sx={{
          display: "flex",
          padding: "8px 22px 8px 22px",
          gap: "8px",
          borderRadius: "4px 0px 0px 0px",
          border: "1px solid #E5E1EC",
          boxShadow: "0px 4px 4px 0px #0000000A",
          marginBottom: "16px",
        }}
      >
        <PlusIcon />
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "15px",
            fontWeight: 500,
            lineHeight: "26px",
            letterSpacing: "0.46000000834465027px",
            textTransform: "none",
          }}
        >
          Create new KPI
        </Typography>
      </Button>
      <div className={styles["container"]}>
        <CardContainer
          {...{
            title: "Revenue performance",
          }}
        >
          <div className={styles["card-body"]}>
            {cardsData?.map((card: any, index: number) => (
              <SmallCard card={card} index={index} />
            ))}
          </div>
        </CardContainer>
        <CardContainer
            {...{
                title: 'Optimise'
            }}
        >
            <div className={styles["card-body"]}>
                {optimiseData?.map((card: any, index: number) => (
                  <SmallCard card={card} index={index} />
                ))}
            </div>
            <div className={styles["second-layer"]}>
                <div className={styles["progress-card"]}>
                    <div className={styles["arial-card"]}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <p style={{
                                color: 'rgb(19, 14, 51)',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 400
                            }}>Total Revenue Potential</p>
                            <p style={{
                                color: 'rgb(24, 29, 37)',
                                fontFamily: 'Inter',
                                fontSize: '24px',
                                fontWeight: 700,
                                lineHeight: '29px'
                            }}>3457$</p>
                        </div>
                        <div style={{
                            height: ''
                        }}>
                            <ArialChart {...{
                                width: '100px',
                                height: '60px'
                            }} />
                        </div>
                    </div>
                    <div className={styles["progress-bar"]}>
                        <p style={{
                            color: 'rgb(95, 70, 254)',
                            fontFamily: 'Inter',
                            fontSize: '11px',
                            fontWeight: 500,
                            lineHeight: '13px',
                            marginBottom: '3px'
                        }}>40% completed</p>
                        <LinearProgress variant="determinate" value={40}
                            sx={{
                                height: '6px',
                                borderRadius: '6px',
                                background: 'rgb(247, 247, 247)',
                                "& .MuiLinearProgress-bar": {
                                    border: '0.5px solid rgb(95, 70, 255)',
                                    borderRadius: '32px',
                                    background: 'rgba(91, 71, 245, 0.4)'
                                }
                            }}
                        />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p style={{
                            color: 'rgb(146, 159, 177)',
                            fontFamily: 'Inter',
                            fontSize: '15px',
                            fontWeight: 400,
                            lineHeight: '20px'
                        }}>Last month</p>
                        <p style={{
                            color: 'rgb(51, 72, 255)',
                            fontFamily: 'Inter',
                            fontSize: '15px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            textAlign: 'right'
                        }}>
                            View report
                        </p>
                    </div>
                </div>
                <div className={styles["media-cards"]}>
                    <div className={styles["media-card"]}>
                        <p style={{
                             color: 'rgb(19, 14, 51)',
                             fontFamily: 'Inter',
                             fontSize: '14px',
                             fontWeight: 400
                        }}>Insights</p>
                        <div className={styles["media-footer"]}>
                            <UpIcon />
                            <p style={{
                                color: 'rgb(3, 160, 28)',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '19px',
                                letterSpacing: '0px',
                                textAlign: 'center'
                            }}>24%</p>
                        </div>
                    </div>
                    <div className={styles["media-card"]}>
                        <p style={{
                             color: 'rgb(19, 14, 51)',
                             fontFamily: 'Inter',
                             fontSize: '14px',
                             fontWeight: 400
                        }}>Media</p>
                        <div className={styles["media-footer"]}>
                            <DownIcon />
                            <p style={{
                                color: '#F32051',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '19px',
                                letterSpacing: '0px',
                                textAlign: 'center'
                            }}>24%</p>
                        </div>
                    </div>
                    <div className={styles["media-card"]}>
                        <p style={{
                             color: 'rgb(19, 14, 51)',
                             fontFamily: 'Inter',
                             fontSize: '14px',
                             fontWeight: 400
                        }}>Insights</p>
                        <div className={styles["media-footer"]}>
                            <UpIcon />
                            <p style={{
                                color: 'rgb(3, 160, 28)',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '19px',
                                letterSpacing: '0px',
                                textAlign: 'center'
                            }}>24%</p>
                        </div>
                    </div>
                    <div className={styles["media-card"]}>
                        <p style={{
                             color: 'rgb(19, 14, 51)',
                             fontFamily: 'Inter',
                             fontSize: '14px',
                             fontWeight: 400
                        }}>Media</p>
                        <div className={styles["media-footer"]}>
                            <DownIcon />
                            <p style={{
                                color: '#F32051',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '19px',
                                letterSpacing: '0px',
                                textAlign: 'center'
                            }}>24%</p>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>            
        </CardContainer>
      </div>
    </div>
  );
};

export default Dashboard;
