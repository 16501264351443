import React, { forwardRef } from 'react';
import { Button as MUIButton } from '@mui/material';
import styles from './button.module.scss';

// eslint-disable-next-line react/display-name
export const CustomButton = forwardRef((props: any, ref: any) => {
  return (
    <>
      {
        <MUIButton
          ref={ref}
          className={styles.button}
          variant='contained'
          size={props.size}
          onClick={props.onClick}
          disabled={props.disabled}
          {...props}
        >
          {props.children}
        </MUIButton>
      }
    </>
  );
});
