import React from "react";
import Dialog from "@mui/material/Dialog";
import { maxWidth, styled } from "@mui/system";
import { Button, InputAdornment, TextField, Box } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const CsvDialog = styled(Dialog)({
  zIndex: 10000,
  ".MuiPaper-root": {
    height: "40%",
    width: "40%",
    maxWidth: "10000px",
  },
});

const NBtn = styled(Button)({
  backgroundColor: "#5F46FF",
  color: "white",
  ":hover": {
    backgroundColor: "#5F46FF",
  },
});

export default function AiMessage({
  open,
  handleClose,
  flowId,
}: {
  open: any;
  handleClose: any;
  flowId: any;
}) {
  const [message, setMessage] = useState("Analysing the data...");
  useEffect(() => {
    if (open) {
      console.log("djx", flowId);
      if (flowId == 1) {
        setTimeout(() => {
          setMessage("Generating the ETL circuit...");
          setTimeout(() => {
            toast.success("ETL flow generated sucessfully!", {
              toastId: "success1",
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "light",
            });
            handleClose();
          }, 2100);
        }, 3000);
      } else if (flowId == 2) {
        setMessage("Analysing the data...");
        setTimeout(() => {
          setMessage("Generating the ETL circuit...");
          setTimeout(() => {
            toast.error("Some data missing for output node transactions!", {
              toastId: "error1",
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "light",
            });
            handleClose();
          }, 1000);
        }, 3000);
      } else if (flowId == 3) {
        setMessage("Analysing the data...");
        setTimeout(() => {
          setMessage("Generating the ETL circuit...");
          setTimeout(() => {
            toast.success("ETL flow generated sucessfully!", {
              toastId: "success1",
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "light",
            });
            handleClose();
          }, 1000);
        }, 3000);
      }
    }
  }, [open]);

  return (
    <CsvDialog
      onClose={() => {
        handleClose();
      }}
      open={open}
    >
      <DialogContent>
        <div className="flex flex-col items-center justify-center h-full w-full">
          <img height={128} width={128} src="/loader.gif" />
          <span>{message}</span>
        </div>
      </DialogContent>
    </CsvDialog>
  );
}
