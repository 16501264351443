import { CircuitStore, Circuit } from "@nodl/react";
import { Node, Input, Output, schema } from "@nodl/core";
import { Observable, map } from "rxjs";
import { z } from "zod";
import { DataTempSchema } from "../Schema/DataTemp";

class Connector extends Node {
  name = "Data Source - CSV";
  uid = "csv-connector"
  inputs = {};

  outputs = {
    output: new Output({
      name: "data",
      type: DataTempSchema,
      observable: new Observable((subscriber) => {
        subscriber.next({ columns: this.getColumns() });
      }),
    }),
  };
  nodeRedableName = "";
  columnNames_ : any = []

  constructor(name: string, columnNames: any) {
    super();
    this.nodeRedableName = name;
    this.columnNames_ = columnNames.map((v: any) => {
      return {name: v, type: ""}
    })
    this.outputs.output.next({ columns: this.columnNames_})
  }

  getColumns() {
    return this.columnNames_
  }
}

export { Connector };
