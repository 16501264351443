import React, { useEffect } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";

const Manage = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("/data-animator")
  }, [])
  return (
    <div>
    </div>
  );
};

export { Manage };
