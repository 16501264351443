"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Connection = void 0;
const mobx_1 = require("mobx");
const rxjs_1 = require("rxjs");
const uuid_1 = require("uuid");
const zod_1 = require("zod");
class Connection extends rxjs_1.Subject {
    /** Determines type compatibility */
    static isTypeCompatible(from, to) {
        if (to.type.validator instanceof zod_1.ZodUnion) {
            const types = to.type.validator._def.options;
            const hasCompatibleType = types.some(type => type.constructor === from.type.validator.constructor);
            return hasCompatibleType;
        }
        else {
            return from.type.validator.constructor === to.type.validator.constructor;
        }
    }
    constructor(from, to) {
        var _a;
        super();
        /** Identifier */
        this.id = (0, uuid_1.v4)();
        if (!Connection.isTypeCompatible(from, to)) {
            throw new Error('Connection origin & target has incompatible types');
        }
        if (to.connected) {
            /** Remove previous connection gracefully */
            (_a = to.connection) === null || _a === void 0 ? void 0 : _a.dispose();
        }
        this.from = from;
        this.to = to;
        this.from.connections.push(this);
        this.to.connection = this;
        this.subscription = this.from.subscribe(value => {
            try {
                this.to.type.validator.parse(value);
                this.to.next(value);
            }
            catch (err) {
                this.dispose();
                throw new Error('Received a value with an incompatible type');
            }
        });
        (0, mobx_1.makeObservable)(this, {
            id: mobx_1.observable,
            from: mobx_1.observable,
            to: mobx_1.observable,
            subscription: mobx_1.observable,
            dispose: mobx_1.action
        });
    }
    /** Disposes the Connection */
    dispose() {
        var _a;
        this.unsubscribe();
        (_a = this.subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.from.connections = this.from.connections.filter(connection => connection !== this);
        this.to.connection = null;
        this.to.next(this.to.defaultValue);
    }
}
exports.Connection = Connection;
