"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Output = void 0;
const mobx_1 = require("mobx");
const rxjs_1 = require("rxjs");
const uuid_1 = require("uuid");
const Connection_1 = require("../Connection/Connection");
class Output extends rxjs_1.ReplaySubject {
    constructor(props) {
        super(1);
        /** Identifier */
        this.id = (0, uuid_1.v4)();
        this.name = props.name || 'Untitled';
        this.type = props.type;
        this.observable = props.observable;
        this.subscription = this.observable.subscribe(this);
        this.connections = [];
        (0, mobx_1.makeObservable)(this, {
            id: mobx_1.observable,
            name: mobx_1.observable,
            type: mobx_1.observable,
            observable: mobx_1.observable,
            subscription: mobx_1.observable,
            connections: mobx_1.observable,
            connected: mobx_1.computed,
            connect: mobx_1.action,
            dispose: mobx_1.action
        });
    }
    /** Determines if output is connected */
    get connected() {
        return this.connections.length > 0;
    }
    /** Connects the output with a compatible input port */
    connect(input) {
        return new Connection_1.Connection(this, input);
    }
    /** Disposes the Output */
    dispose() {
        for (const connection of this.connections) {
            connection.dispose();
        }
        this.connections = [];
        this.subscription.unsubscribe();
        this.unsubscribe();
    }
}
exports.Output = Output;
