import { useEffect, useState } from "react";
import { SidebarLinkProps } from "../interfaces";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Profile from "../assets/images/profile.jpg";
import settings from "../assets/svg/settingsIcon.svg";
import "./sidebar.scss";
import RightTabs from "../components/molecules/TabPanel";
import { ReactComponent as Dashboard } from '../assets/svg/dashboard.svg';
import { ReactComponent as ManageIcon } from '../assets/svg/manage.svg'
import { ReactComponent as MonetiseIcon } from '../assets/svg/monetise.svg'
import { ReactComponent as OptimizeIcon } from '../assets/svg/optimize.svg';
import { ReactComponent as InsightsIcon } from '../assets/svg/insightsIcon.svg';
import { ReactComponent as CollaborationIcon } from '../assets/svg/collaboration.svg';
import { ReactComponent as MediaIcon } from '../assets/svg/media.svg';
import { ReactComponent as SurveysIcon } from '../assets/svg/surveys.svg';
import { ReactComponent as AttachmentIcon } from '../assets/svg/attachment.svg';
import { ReactComponent as ProductsIcon } from '../assets/svg/products.svg';
import { ReactComponent as ContentIcon } from '../assets/svg/content.svg';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';

import { useSelector, useDispatch } from 'react-redux'
import { onMainTabChange, onsubTabChange, switchDashboard } from "../store/header/reducer";
import { SideLayout } from "./sideLayout";
import xylicLogo from "../assets/svg/xylicLogo.svg";


const SidebarLink: React.FC<{
  link: SidebarLinkProps;
  isSidebarOpen: boolean;
}> = ({ link, isSidebarOpen }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (url: string) => {
    if (url) {
      navigate(url);
    } else {
      console.log("sample");
    }
  };

  return (
    <li className={`${isOpen ? "showMenu" : ""}`}>
      <div className="iocn-link" onClick={handleToggle}>
        <p
          onClick={() => handleNavigation(link.path as string)}
          className={link.subMenuHeader ? `submenu-header` : ""}
        >
          {link.icon && <i className={link.icon}></i>}
          <span
            className={`link_name${link.subMenuHeader
              ? ` submenu-header${isSidebarOpen ? "-none" : "-show"}`
              : ""
              }`}
          >
            {link.title}
          </span>
        </p>
        {(link.children?.length as number) > 0 && isSidebarOpen && (
          <i
            className={`bx ${isOpen ? "bxs-chevron-up" : "bxs-chevron-down"
              } arrow`}
          ></i>
        )}
      </div>
      {isOpen && link.children && link.children?.length > 0 && (
        <ul className={`sub-menu`} style={isSidebarOpen ? link.styles : {}}>
          {link.children.map((childLink: SidebarLinkProps, index: number) => (
            <SidebarLink
              key={index}
              link={childLink}
              isSidebarOpen={isSidebarOpen}
            /> // Render nested SidebarLink for each child
          ))}
        </ul>
      )}
    </li>
  );
};
// #5B47F5
const Sidebar = () => {
  const mainTab = useSelector((state: any) => state?.headerContext?.mainHeader)
  const dispatch = useDispatch();
  const location = useLocation()
  const navigate = useNavigate();
  const isDashboard = useSelector((state: any) => state?.headerContext?.isDashboard)

  const locationHeaderMap : any = {
    "": {
      isEnabled: true, subTab: "", mainTab: "Dashboard"
    },
    "/dashboard": {
      isEnabled: true, subTab: "", mainTab: "Dashboard"
    },
    "/insights": {
      isEnabled: false, subTab: "", mainTab: "Get Insights"
    },
    "/manage-content": {
      isEnabled: false, subTab: "", mainTab: "Manage Content"
    },
    "/content-manager": {
      isEnabled: false, subTab: "Contents", mainTab: "Manage Content"
    },
    "/ai-content-studio": {
      isEnabled: false, subTab: "AI Studio", mainTab: "Manage Content"
    },
    "/insights/best-sellers": {
      isEnabled: false, subTab: "Best Sellers", mainTab: "Get Insights"
    },
    "/insights/customer-behaviour": {
      isEnabled: false, subTab: "Customer Behaviour", mainTab: "Get Insights"
    },
    "/insights/segmentation": {
      isEnabled: false, subTab: "Segmentation", mainTab: "Get Insights"
    },
    "/media/manage-campaign": {
      isEnabled: false, subTab: "Manage Campaigns", mainTab: "Buy Media"
    },
    "/media/measurement-and-analytics": {
      isEnabled: false, subTab: "Measurement and Analytics", mainTab: "Buy Media"
    },
    "/supplychain": {
      isEnabled: false, subTab: "", mainTab: "Supply Chain"
    },
    "/manage":{
      isEnabled: true, subTab: "Connect Data", mainTab: "Manage"
    },
    "/data-animator": {
      isEnabled: true, subTab: "Connect Data", mainTab: "Manage"
    },
    "/subscribers": {
      isEnabled: true, subTab: "Subscribers", mainTab: "Manage"
    },
    "/on-boarding": {
      isEnabled: true, subTab: "On Boarding", mainTab: "Manage"
    },
  }

  useEffect(() => {
    const headData : any = locationHeaderMap[location.pathname]
    if(headData){
      dispatch(switchDashboard({ isEnabled: headData.isEnabled }))
      dispatch(onMainTabChange({ name: headData.mainTab }))
      dispatch(onsubTabChange({ name: headData.subTab }))
    }else{
      dispatch(onMainTabChange({ name: "" }))
      dispatch(onsubTabChange({ name: "" }))
    }
  }, [location.pathname])

  const tabdata = [
    { name: 'Get Insights', id: 0, value: 'insights', icon: <InsightsIcon fill={mainTab === 'Get Insights' ? "rgb(95, 70, 255)" : '#888888'} /> },
    { name: 'Manage Content', id: 0, value: 'manage-content', icon: <AddPhotoAlternateOutlinedIcon style={{ marginTop: '2px', color: mainTab === 'Manage Content' ? "rgb(95, 70, 255)" : '#888888' }} /> },
    { name: 'Buy Media', id: 0, value: 'media/manage-campaign', icon: <MediaIcon style={{ marginTop: '2px' }} fill={mainTab === 'Buy Media' ? "rgb(95, 70, 255)" : '#888888'} /> },
    { name: 'Collaborate', id: 0, value: 'collaboration', icon: <CollaborationIcon style={{ marginTop: '2px' }} fill={mainTab === 'Collaborate' ? "rgb(95, 70, 255)" : '#888888'} /> },
    { name: 'Campaign Testing', id: 0, value: 'surveys', icon: <SurveysIcon style={{ marginTop: '2px' }} fill={mainTab === 'Campaign Testing' ? "rgb(95, 70, 255)" : '#888888'} /> },
  //  { name: 'Supply Chain', id: 0, value: 'supplychain', icon: <AttachmentIcon style={{ marginTop: '2px' }} fill={mainTab === 'Supply Chain' ? "rgb(95, 70, 255)" : '#888888'} /> }
  ];


  const dash_tabdata = [
    { name: 'Dashboard', id: 0, value: 'dashboard', icon: <ProductsIcon fill={mainTab === 'Dashboard' ? "rgb(95, 70, 255)" : '#888888'} /> },
    { name: 'Manage', id: 0, value: 'manage', icon: <ManageIcon style={{ marginTop: '2px' }} fill={mainTab === 'Manage' ? "rgb(95, 70, 255)" : '#888888'} /> },
    //{ name: 'Monetise', id: 0, value: 'monetise', icon: <MonetiseIcon style={{ marginTop: '2px' }} fill={mainTab === 'Monetise' ? "rgb(95, 70, 255)" : '#888888'} /> },
    //{ name: 'Optimize', id: 0, value: 'optimize', icon: <OptimizeIcon style={{ marginTop: '2px' }} fill={mainTab === 'Optimize' ? "rgb(95, 70, 255)" : '#888888'} /> },
  ];
  const handleType = (item: any, portal: string) => {
    if(item?.name=="Get Insights"){
      window.location.replace("/insights")
      return
    }
    navigate(`${item?.value}`)
    // dispatch(onMainTabChange(item));
    // if (item.name === 'Media') {
    //   dispatch(onsubTabChange({ name: 'Manage Campaigns', id: 0, value: '/media/manage-campaign' }))
    // } else {
    //   dispatch(onsubTabChange(''))
    // }
  }

  console.log(location.pathname !=='/xylic-ai');

  return (
    <>
      {(location.pathname != "/data-animator" && location.pathname != "/manage" && location.pathname != "/content-manager" && location.pathname != "/manage-content"  && location.pathname != "/on-boarding" && location.pathname != "/monetise" && !location.pathname.startsWith("/media")) && location.pathname != "/not-found" && location.pathname != "/ai-content-studio" && <SideLayout />}
      <div className="home-content">
        <div className="flex w-full">
          <div className="logo-details cursor-pointer" onClick={() => {
            //  if (isDashboard) {
            //    dispatch(onMainTabChange({ name: 'Dashboard' }))
            //    dispatch(switchDashboard({ isEnabled: true }))
            //    dispatch(onsubTabChange(''))
            //    navigate("/")
            //  } else {
            //    navigate("/insights")
            //    dispatch(onMainTabChange({ name: 'Insights' }))
            //    dispatch(switchDashboard({ isEnabled: false }))
            //    dispatch(onsubTabChange(''))
            // }

            navigate("/xylic-ai")

          }}>
            <i className="bx bxl-c-plus-plus" />
            <span className={`logo_name`}>
              <img src={xylicLogo} alt="logo" />
            </span>

          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '3%' }}>
            <RightTabs
              {...{
                tabs: (isDashboard && location.pathname !=='/xylic-ai') ? dash_tabdata : tabdata,
                selectedTab: mainTab,
                handleType: handleType,
              }}
            />
          </div>
          <div className="navbar_content flex-1">
            <img src={Profile} alt="" className="profile" />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <h3 style={{ fontSize: '14px', fontFamily: 'Inter', fontWeight: 600, lineHeight: '17px', color: 'rgb(64, 64, 64)' }}>James Rig</h3>
              <p style={{ fontSize: '12px', fontFamily: 'Inter', fontWeight: 600, lineHeight: '15px', color: 'rgb(86, 86, 86)' }}>Admin</p>
            </div>
            <img src={settings} alt="" />
          </div>
        </div>
        <section className="home-section"
          style={
            location.pathname == "/data-animator" || location.pathname == "/manage" || location.pathname == "/on-boarding" || location.pathname == "/monetise" || location.pathname.startsWith("/media") || location.pathname == "/not-found" || location.pathname == "/content-manager" ||  location.pathname == "/ai-content-studio"
              ? { left: 0, width: "100%" }
              : {}
          }
        >
          <div style={{}} >
            <Outlet />
          </div>
        </section>
      </div>
    </>
  );
}

export default Sidebar;
