import React, { useState } from "react";
import styles from "../sidebarLayout.module.scss";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const JoinConnectorRSB = ({ node }: { node: any }) => {
  const [leftColumn, setLeftColumn] = useState(node.getLeftJoin());
  const [rightColumn, setRightColumn] = useState(node.getRightJoin());
  return (
    <div>
      <div className={styles.MainTextHeader}>
        <div className="w-full flex items-center justify-between">
          <span>Join Columns</span>
        </div>
      </div>
      <div className="p-2 my-2">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Left column</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={leftColumn}
            label="Left Column"
            onChange={(e) => {
              setLeftColumn(e.target.value);
              node.setLeftJoin(e.target.value)
            }}
          >
            {node.getLeftColumns().map((val: any) => {
              return <MenuItem value={val.name}>{val.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </div>
      <div className="p-2">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Right column</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={rightColumn}
            sx={{
              
            }}
            label="Right column"
            onChange={(e) => {
              setRightColumn(e.target.value);
              node.setRightJoin(e.target.value)
            }}
          >
            {node.getRightColumns().map((val: any) => {
              return <MenuItem value={val.name}>{val.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export { JoinConnectorRSB };
