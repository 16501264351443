import GroupedBarChart from "../../../../../../../components/molecules/bar-chart";

export const BarchartCustomerRelationship = ({data=[]}:any) => {
    
    const getData = () => {
        const labels: any = [['Customer base', '(P1)'], ['Churners to', 'competition'], ['Churners out of', 'market'], ['Recurring', 'customers'], ['New comers from', 'competition'], ['New comers on', 'market'], ['Customer base', '(P2)']]
        const datasets: any = [
            {
                label: 'dataset1',
                data: data,
                backgroundColor: [
                    'rgb(139, 151, 255)',
                    'rgb(133, 216, 221)',
                    'rgb(255, 182, 141)',
                    'rgb(116, 194, 251)',
                    'rgb(255, 133, 214)',
                    'rgb(255, 141, 148)',
                    'rgb(139, 151, 255)'
                ],
                borderColor: [
                    'rgb(139, 151, 255)',
                    'rgb(133, 216, 221)',
                    'rgb(255, 182, 141)',
                    'rgb(116, 194, 251)',
                    'rgb(255, 133, 214)',
                    'rgb(255, 141, 148)',
                    'rgb(139, 151, 255)',
                ],
                barPercentage: 0.5,
            },
            // {
            //     label: 'Churners to competition',
            //     data: [],
            //     backgroundColor: '#427ae3',
            //     borderColor: '#427ae3',

            // },
            // {
            //     label: 'Churners out of market',
            //     data: [],
            //     backgroundColor: '#3dc7d1',
            //     borderColor: '#3dc7d1',
            // }
        ]
        return {
            labels: labels,
            datasets: datasets
        }
    }

    const toptions = {
        chart: {
            type: 'bar',
            height: 350
        },
        colors: ["#1b3c7a", "#427ae3", "#3dc7d1", '#faa93e'],
        fill: {
            colors: ["#1b3c7a", "#427ae3", "#3dc7d1", '#faa93e']
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: true,
                color: "black",
                // formatter: function (context: any) {
                //     console.log(context)
                //     if(typeof context ==='number'){
                //         return context;
                //     } else {
                //         return context?.length>1 ? context[1]-context[0] : 0
                //     }
                // },
                anchor: "end",
                offset: -20,
                align: "start",

            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '96px',
                endingShape: 'rounded',
                barPercentage: 0.5,
                categorySpacing: 0,
            },
        },
        dataLabels: {
            enabled: false,

        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        scales: {
            y: {
                min: 0,
                ticks: {
                    // stepSize: 20,// <----- This prop sets the stepSize
                }
            },
            x: {
                barPercentage: 0.5,
                categorySpacing: 0,
                grid: {
                    display: false,
                },
            },
        },
        tooltip: {
            y: {
                formatter: function (context: any) {
                    if(typeof context ==='number'){
                        return context;
                    } else {
                        return context[1]-context[0]
                    }
                }
            }
        },
    };
    return (
        <div style={{ height: '450px' }}>
            <GroupedBarChart {...{
                toptions,
                data: getData(),
                width: '1400%',
                height: 350
            }} />
        </div>
    )
}