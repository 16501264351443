import React, {useState} from "react";
import { SearchInput } from "../../../../../components/atoms/search";
import Select from "../../../../../components/atoms/Select";
import { ReactComponent as DownArrowIcon } from "../../../../../assets/svg/downarr.svg";
import Tabs from "./Tabs";

const SearchHeader = ({detailed,listSelTab,setListSelTab}: any) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ width: "265px", paddingTop: "16px", paddingBottom: "8px" }}>
        <SearchInput />
      </div>
      <div style={{ display: "flex", gap: "20px", alignItems: "center", paddingTop: "8px" }}>
        <div style={{ width: "100%" }}>
          <Select
            value={15}
            options={[{ label: "15 Days", value: 15 }]}
            variant="outlined"
            size="small"
            multiple={false}
            optionsType="default"
            name="timeline"
            onChange={(e: any) => console.log(e)}
            IconComponent={() => <DownArrowIcon fill="#fff" />}
            sx={{
              color: "#fff !important",
              fontFamily: "Inter",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
              borderRadius: "8px",
              paddingTop: "3px",
              width: "223px",
              height: "37px",
              border: "none",
              background: "#5F46FF !important",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
                color: "#fff !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSelect-outlined": {
                color: "#fff !important",
              },
            }}
            Menustyle={{
              borderRadius: "4px",
              boxShadow:
                "0px 1px 2px 0px rgba(0, 0, 0, 0.3),0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
              background: "#FFF",
              "& .MuiMenuItem-root": {
                color: "#5F46FF",
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
                minwidth: "100%",
                borderRadius: "8px",
                paddingTop: "3px",
                borderBottom: "1px solid rgb(73, 69, 79)",
              },
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tabs {...{ selTab: listSelTab, setSelTab: setListSelTab, detailed }} />
        </div>
      </div>
    </div>
  );
};

export default SearchHeader;
