export var TooltipPosition;
(function (TooltipPosition) {
    TooltipPosition[TooltipPosition["TOP"] = 0] = "TOP";
    TooltipPosition[TooltipPosition["TOP_RIGHT"] = 1] = "TOP_RIGHT";
    TooltipPosition[TooltipPosition["RIGHT"] = 2] = "RIGHT";
    TooltipPosition[TooltipPosition["BOTTOM_RIGHT"] = 3] = "BOTTOM_RIGHT";
    TooltipPosition[TooltipPosition["BOTTOM"] = 4] = "BOTTOM";
    TooltipPosition[TooltipPosition["BOTTOM_LEFT"] = 5] = "BOTTOM_LEFT";
    TooltipPosition[TooltipPosition["LEFT"] = 6] = "LEFT";
    TooltipPosition[TooltipPosition["TOP_LEFT"] = 7] = "TOP_LEFT";
})(TooltipPosition || (TooltipPosition = {}));
