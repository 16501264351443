import SubtabsHeader from "../../elements/Subtabs/SubtabsHeader";
import styles from "../../styles.module.scss";
import { ReactComponent as StarIcon } from "../../../../../assets/svg/start.svg";
import { ReactComponent as ExportIcon } from "../../../../../assets/svg/download.svg";
import { ReactComponent as ShareIcon } from "../../../../../assets/svg/share.svg";
import Pagination from "../../../../../components/molecules/Pagination";
import { useMedia } from "../../../useMedia";
import { rows } from "../../elements/tables/manageCompains";
import { ActiveCol, MeasurementAndAnalysisTable } from "../../elements/tables/measurementAndAnalysis";
import SearchHeader from "../seach-header";
import { DetailedScreen } from "../Inner-Screen";

const MeasurementAndAnalytics = () => {
    const { page, setPage, handleRowClick, createForm, handleCreateForm, handleBack, row, filter, detailed, setDetailed, listSelTab, setListSelTab } = useMedia();
    
    return (
        <div>
            <SubtabsHeader />
            <div style={{ padding: "16px", paddingTop: "0px" }}>
                <SearchHeader {...{
                    detailed,
                    listSelTab,
                    setListSelTab
                }} />
                {!detailed.measurement && !(listSelTab==='1') && (
                    <div style={{ background: "white" }}>
                        <div style={{ borderBottom: "1px solid rgb(95, 70, 234,0.5)" }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    margin: "6px",
                                    marginTop: "10px",
                                    padding: "10px",
                                    paddingLeft: "20px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "18px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <h2
                                        className={styles.bestSellingHeader}
                                    >
                                        Measurement and Analytics
                                    </h2>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "20px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Pagination {...{ setPage, page: 1, count: 10 }} />{" "}
                                    <StarIcon /> <ExportIcon /> <ShareIcon />
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "1px" }}>
                            <MeasurementAndAnalysisTable
                                handleRowClick={handleRowClick}
                                data={rows}
                            />
                        </div>
                    </div>
                )}
                {detailed.measurement && !(listSelTab==='1') && <DetailedScreen {...{ row, handleBack: () => setDetailed({...detailed, measurement: false }), filter, ActiveCol }} />}
                {listSelTab==='1' && <DetailedScreen {...{row: {
                    ad: 'Total 32 adverstisements',
                    rows: '35,786',
                    impressions: '35,786',
                    views: '35,786',
                    clicks: '35,786',
                    conversions: '35,786',
                    ctr: '35,786'
                    }, handleBack:() => setListSelTab("2"),filter, ActiveCol }} />}
            </div>
        </div>
    );
};

export default MeasurementAndAnalytics;
