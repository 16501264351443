import React from 'react'
import styles from "../../styles.module.scss";
import Select from '../../../../../components/atoms/Select';
import { ReactComponent as DownArrowIcon } from "../../../../../assets/svg/downarr.svg";

const selectStyles = {
    color: '#fff !important',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    borderRadius: '8px',
    paddingTop: '3px',
    width: '223px',
    height: '36px',
    border: 'none',
    background: '#FFF !important',
    '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgb(94, 92, 113)',
        color: 'rgb(94, 92, 113)'

    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgb(94, 92, 113)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgb(94, 92, 113)',
    },
    '& .MuiSelect-outlined': {
        color: 'rgb(94, 92, 113)'
    },
}

const menustyles = {
    borderRadius: '9px',
    border: '1px solid rgba(191, 191, 191, 0.25)',
    background: ' #FFF',
    '& .MuiMenuItem-root': {
        color: '#5F46FF',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        minwidth: '100%',
        borderRadius: '8px',
        paddingTop: '3px',
    },
}

const SelectInput = ({label, options}: any) => {
  return (
    <div className={styles['input']}>
        {label ? <p className={styles['form-label']}>{label}:</p> : <div style={{lineHeight: '20px'}}>&nbsp;</div>}
        <Select
            value={''}
            options={options}
            onChange={(e: any) => console.log(e)}
            variant="outlined"
            size='small'
            multiple={false}
            IconComponent={() => <DownArrowIcon fill="#000" />}
            optionsType="default"
            sx={{
                ...selectStyles
            }}
            Menustyle={{
                ...menustyles
            }}
        />
    </div>
  )
}

export default SelectInput