import * as React from 'react';
export const useKeyboardActions = (store) => {
    const removeNodes = React.useCallback(() => {
        for (const node of store.selectedNodes || []) {
            node.dispose();
            store.removeNode(node.id);
        }
        store.selectNodes([]);
    }, [store]);
    const selectAllNodes = React.useCallback(() => {
        store.selectNodes(store.nodes);
    }, [store]);
    const deselectAllNodes = React.useCallback(() => {
        var _a;
        if ((_a = store.selectedNodes) === null || _a === void 0 ? void 0 : _a.length) {
            store.selectNodes([]);
        }
    }, [store]);
    const actions = React.useMemo(() => [
        /** Remove Nodes */
        {
            key: "Delete" /* KeyboardKey.Delete */,
            callback: removeNodes
        },
        {
            key: "Backspace" /* KeyboardKey.Backspace */,
            callback: removeNodes
        },
        /** Select Nodes */
        {
            modifier: 'metaKey',
            key: 'a',
            callback: selectAllNodes
        },
        {
            modifier: 'ctrlKey',
            key: 'a',
            callback: selectAllNodes
        },
        /** Deselect Nodes */
        {
            key: "Escape" /* KeyboardKey.Escape */,
            callback: deselectAllNodes
        }
    ], [removeNodes, selectAllNodes, deselectAllNodes]);
    const downHandler = React.useCallback((e) => {
        for (const action of actions) {
            if (action.key === e.key) {
                if (action.modifier && !e[action.modifier])
                    continue;
                action.callback(e);
            }
        }
    }, [actions]);
    React.useEffect(() => {
        window.addEventListener('keydown', downHandler);
        return () => {
            window.removeEventListener('keydown', downHandler);
        };
    }, []);
};
