import React, { useEffect, useState } from 'react'
import styles from "./styles.module.scss";
import { ReactComponent as DownArr } from '../../../../../assets/svg/downarr.svg';
import Select from '../../../../../components/atoms/Select';
import { timeline } from '../../../../../constants/utils';
import { getAutoStack } from '../../../../../services/insights';
import { useSelector } from 'react-redux';
const selectStyles = {
  color: '#000 !important',
  fontFamily: 'Inter',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  borderRadius: '8px',
  paddingTop: '3px',
  width: '223px',
  height: '24px',
  border: '1px solid #E1E7EC',
  // background: '#5F46FF !important',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    color: '#000 !important',

  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #E1E7EC'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #E1E7EC',
  },
  '& .MuiSelect-outlined': {
    color: '#000 !important',
  },
}

const menustyles = {
  borderRadius: '4px',
  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3),0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
  background: '#FFF',
  '& .MuiMenuItem-root': {
    color: '#5F46FF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    minwidth: '100%',
    borderRadius: '8px',
    paddingTop: '3px',
    borderBottom: '1px solid rgb(73, 69, 79)',
  },
}

const CardContainer = ({ name, title, dropdown, children, selectWidth, sx, onClick, className, allTimes, setAllTimes, options }: any) => {
  const [timeLines, setTimeLines] = useState<any>(timeline?.map((time: string) => {
    return {
      label: time,
      value: time
    }
  }));
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setAllTimes({ ...allTimes, [name]: value });
  }


  return (
    <div className={`${styles.mainCard} ${styles?.[className]}`} style={{ ...sx }}>
      <div className={styles.cardHeader}>
        <p className={styles.title} style={{ cursor: "pointer" }} onClick={onClick}>{title}</p>
        <div>
          {dropdown && (
            <Select
              IconComponent={() => <DownArr fill='#5E5C71' />}
              value={allTimes?.[name]}
              options={timeLines || []}
              name={name}
              onChange={(e: any) => handleChange(e)}
              variant="outlined"
              size='small'
              multiple={false}
              optionsType="default"
              sx={{
                ...selectStyles,
                width: selectWidth,
              }}
              Menustyle={{
                ...menustyles
              }}
            />
          )}
        </div>
      </div>
      <div style={{ padding: '16px' }}>
        {children}
      </div>
    </div>
  )
}

export default CardContainer;