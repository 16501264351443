import { CheckBox } from '../../../../../../components/atoms/CheckboxWithName';
import { CalenderButton } from '../../../../../../components/atoms/calender-button';
import { BarchartCustomerRelationship } from './barchart';
import styles from '../styles.module.scss';
import Select from '../../../../../../components/atoms/Select';
import { useEffect, useState } from 'react';
import { getCustomerSwitchData } from '../../../../../../services/insights';
import Loader from '../../../../../../components/atoms/loader';
import { useSelector } from 'react-redux';
import InfiniteSelect from '../../../elements/InfiniteSelect';
export const selectStyles = {
    color: '#fff !important',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    borderRadius: '8px',
    paddingTop: '3px',
    width: '223px',
    height: '36px',
    border: 'none',
    background: '#FFF !important',
    '& .MuiOutlinedInput-notchedOutline': {

        border: '1px solid rgb(95, 70, 254)',
        color: 'rgb(95, 70, 254)'

    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgb(95, 70, 254)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgb(95, 70, 254)',
    },
    '& .MuiSelect-outlined': {
        color: 'rgb(95, 70, 254)'
    },
}
export const menustyles = {
    borderRadius: '9px',
    border: '1px solid rgba(191, 191, 191, 0.25)',
    background: ' #FFF',
    '& .MuiMenuItem-root': {
        color: '#5F46FF',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        minwidth: '100%',
        borderRadius: '8px',
        paddingTop: '3px',
    },
}
export const CustomerSwitch = ({ brands, filter,value, options ,handleChangeItem, page, setPage }: any) => {
    const [brand, setBrand] = useState(brands[0]?.label)
    const [loader, setLoader] = useState(false)
    const [finalData, setFinalData] = useState<any>([])
    const handleChange = (e: any) => {
        setBrand(e)
    }
    const delivery = useSelector((state: any) => state?.sidebarContext?.delivery);
    const location = useSelector((state: any) => state?.sidebarContext?.location);
    const age_group = useSelector((state: any) => state?.sidebarContext?.age_group);
    const products = useSelector((state: any) => state?.sidebarContext?.products);

    const getData = async ({ brand, filter }: any) => {
        setLoader(true)
        const data = await getCustomerSwitchData({ brand: brand ? brand : brands[0]?.label, timeline: filter.timeline, products, location, age_group, delivery })
        setLoader(false)
        return data
    }
    useEffect(() => {
        (async () => {
            if (brand || brands[0]?.label) {
                const data: any = await getData({ brand: brand || brands[0]?.label, filter })
                const finData = [data[0]?.customer_base_p1?.count, data[0]?.churners_to_competition?.count, data[0]?.churners_out_to_market?.count, data[0]?.recurring_customer?.count, data[0]?.new_comers_from_competition?.count, data[0]?.new_comers_on_market?.count, data[0]?.customer_base_p2?.count]
                setFinalData(finData)
            }
        })();
    }, [brand, filter, location, age_group, products, age_group, delivery, brands[0]?.label])

    // useEffect(() => {
    //     setBrand(brands[0]?.value)
    // }, [brands]);

    const handleNextData = () => {
        setPage(page + 1)
    }

    return (
        <>
            <div>
                <div style={{ padding: '16px' }}>
                    {false && <p style={{ fontSize: '14px', fontWeight: 400 }}>Find out how many customers were acquired, lost or remained loyal to a brand between periods.</p>}
                    <div style={{ display: 'flex', gap: '24px', marginTop: '10px' }}>
                        {/* <div style={{ marginTop: '16px', gap: '10px', display: 'flex', flexDirection: 'column' }}>
                            <h2 className={styles.periodName}>Period 1 :</h2>
                            <div>
                                <CalenderButton variant="outline" checked={true} />
                            </div>
                            <div>
                                <CheckBox {...{ checked: true }} />
                            </div>
                        </div>
                        <div style={{ marginTop: '16px', gap: '10px', display: 'flex', flexDirection: 'column' }}>
                            <h2 className={styles.periodName}>Period 1 :</h2>
                            <div>
                                <CalenderButton variant="outline" checked={false} />
                            </div>
                            <div>
                                <CheckBox {...{ checked: false }} />
                            </div>
                        </div> */}
                        <div style={{ marginTop: '16px', gap: '10px', display: 'flex', flexDirection: 'column' }}>
                            <h2 className={styles.periodName}>Period 1:</h2>
                            <Select
                                value={value}
                                options={options}
                                onChange={(e: any) => handleChangeItem(e, 'timeline')}
                                variant="outlined"
                                size='small'
                                multiple={false}

                                optionsType="default"
                                sx={{
                                    ...selectStyles,
                                }}
                                Menustyle={{
                                    ...menustyles,
                                }}
                            />
                        </div>
                        <div style={{ marginTop: '16px', gap: '10px', display: 'flex', flexDirection: 'column' }}>
                            <h2 className={styles.periodName}>Period 2:</h2>
                            <Select
                                value={value}
                                options={options}
                                variant="outlined"
                                size='small'
                                multiple={false}
                                onChange={(e: any) => handleChangeItem(e, 'timeline')}
                                optionsType="default"
                                sx={{
                                    ...selectStyles,
                                }}
                                Menustyle={{
                                    ...menustyles,
                                }}
                            />
                        </div>
                        <div style={{ marginTop: '16px', gap: '10px', display: 'flex', flexDirection: 'column' }}>
                            <h2 className={styles.periodName}>Brand :</h2>
                            {/* <Select
                                value={brand || brands[0]?.value}
                                options={brands}
                                onChange={(e: any) => handleChange(e)}
                                variant="outlined"
                                size='small'
                                multiple={false}

                                optionsType="default"
                                sx={{
                                    ...selectStyles,
                                }}
                                Menustyle={{
                                    ...menustyles,
                                }}
                            /> */}
                            <InfiniteSelect 
                                handleNextData={handleNextData}
                                data={brands}
                                label={brand || brands[0]?.label}
                                page={page}
                                setPage={setPage}
                                handleMenuItemClick={handleChange}
                            />
                        </div>
                        {/* <div style={{ marginTop: '50px' }} className={styles.underLineText}>
                            Apply
                        </div> */}
                    </div>
                </div>
                <div style={{ padding: '16px', marginBottom: '100px' }}>
                    {!loader ? <BarchartCustomerRelationship {...{ data: finalData }} /> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader />
                    </div>}
                </div>
            </div></>
    )
}