import { CircuitStore, Circuit } from "@nodl/react";
import { Node, Input, Output, schema } from '@nodl/core';
import { Observable, map } from 'rxjs';

import { DataTempSchema } from "../Schema/DataTemp";


class TypeConvertor extends Node {
    name = 'Type Convertor';
    uid = "operation-typeConvert"
    inputs = {
        target: new Input({ name: 'input', type: DataTempSchema, defaultValue: {} }),
    };

    outputs = {
        data: new Output({
            name: 'output',
            type: DataTempSchema,
            observable: new Observable((subscriber) => {
                subscriber.next({  columns: [] })
            })
        })
    };

    constructor(columns?: any){
        super()        
        if(columns){
            if(columns.length==2 && columns[0] == "Joined On" && columns[1] == "Age"){
                this.output_ = [["Joined On","String", "DateTime"], ["Age", "String", "Number"]]
            } if (columns.length==1 && columns[0] == "ProductId"){
                this.output_ = [["ProductId ","String", "Number"]]
            }
        }
        setTimeout(()=>{
            this.outputs.data.next({ columns: this.getColumns() })

        }, 250)
    }

    output_ : any = []
    
    setOutput(val: any) {
        this.output_ = val
        this.outputs.data.next({ columns: this.getColumns() })
    }

    getColumns() {
        const val : any = this.inputs.target.value
        return val?.columns || []
    }
}

export { TypeConvertor }


