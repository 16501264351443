import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Canvas } from '../../components/Canvas/Canvas';
import { Connection } from '../../components/Connection/Connection';
import { Node } from '../../components/Node/Node';
import { CANVAS_SIZE } from '../../constants';
import { useKeyboardActions } from '../../hooks/useKeyboardActions/useKeyboardActions';
import { StoreContext } from '../../stores/CircuitStore/CircuitStore';
import { normalizeBounds } from '../../utils/bounds/bounds';
import { circuitContainerStyles, circuitSelectionStyles } from './Circuit.styles';
const Nodes = observer(({ windowResolver }) => {
    const { store } = React.useContext(StoreContext);
    return (_jsx(_Fragment, { children: store.nodes.map(node => (_jsx(Node, { node: node, window: windowResolver === null || windowResolver === void 0 ? void 0 : windowResolver(node) }, node.id))) }));
});
const Connections = observer(() => {
    const ref = React.useRef(null);
    const { store } = React.useContext(StoreContext);
    const onClick = React.useCallback((e) => {
        if (ref.current === e.target) {
            store.selectNodes([]);
        }
    }, []);
    return (_jsxs("svg", Object.assign({ ref: ref, id: "connections", width: "100%", height: "100%", onClick: onClick }, { children: [store.connections.map(connection => (_jsx(Connection, { connection: connection }, connection.id))), store.draftConnectionSource && _jsx(Connection, { output: store.draftConnectionSource })] })));
});
const Selection = observer(() => {
    const { store } = React.useContext(StoreContext);
    return store.selectionBounds ? _jsx("div", { css: circuitSelectionStyles(normalizeBounds(store.selectionBounds)) }) : null;
});
export const Circuit = observer(React.forwardRef((props, ref) => {
    useKeyboardActions(props.store);
    const onMouseMove = React.useCallback((e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.nativeEvent.clientX - rect.left;
        const y = e.nativeEvent.clientY - rect.top;
        props.store.setMousePosition({ x, y });
        if (props.store.selectionBounds) {
            const { x, y, width, height } = props.store.selectionBounds;
            const bounds = {
                x,
                y,
                width: width + e.movementX,
                height: height + e.movementY
            };
            props.store.setSelectionBounds(bounds);
        }
    }, []);
    const onMouseDown = React.useCallback(({ nativeEvent }) => {
        if (nativeEvent.target.id === 'connections') {
            props.store.setSelectionBounds({
                x: props.store.mousePosition.x,
                y: props.store.mousePosition.y,
                width: 0,
                height: 0
            });
        }
    }, []);
    const onMouseUp = React.useCallback((e) => {
        props.store.setDraftConnectionSource(null);
        props.store.setSelectionBounds(null);
    }, []);
    React.useEffect(() => {
        return reaction(() => props.store.connections, (connections, prevConnections) => {
            const addedConnections = connections.filter(connection => !prevConnections.includes(connection));
            const removedConnections = prevConnections.filter(connection => !connections.includes(connection));
            if (addedConnections.length && props.onConnection) {
                for (const connection of addedConnections) {
                    props.onConnection(connection);
                }
            }
            if (removedConnections.length && props.onConnectionRemoval) {
                for (const connection of removedConnections) {
                    props.onConnectionRemoval(connection);
                }
            }
        });
    }, [props]);
    React.useEffect(() => {
        return reaction(() => props.store.nodes, (nodes, prevNodes) => {
            const removedNodes = prevNodes.filter(node => !nodes.includes(node));
            if (removedNodes.length && props.onNodeRemoval) {
                for (const node of removedNodes) {
                    props.onNodeRemoval(node);
                }
            }
        });
    }, [props]);
    React.useEffect(() => {
        return reaction(() => props.store.selectedNodes, (selectedNodes, prevSelectedNodes) => {
            var _a;
            (_a = props.onSelectionChanged) === null || _a === void 0 ? void 0 : _a.call(props, selectedNodes);
        });
    }, [props]);
    return (_jsx(StoreContext.Provider, Object.assign({ value: { store: props.store } }, { children: _jsxs(Canvas, Object.assign({ ref: ref, className: props.className, css: circuitContainerStyles, size: { width: CANVAS_SIZE, height: CANVAS_SIZE }, onMouseDown: onMouseDown, onMouseMove: onMouseMove, onMouseUp: onMouseUp }, { children: [_jsx(Nodes, { windowResolver: props.nodeWindowResolver }), _jsx(Connections, {}), _jsx(Selection, {})] })) })));
}));
