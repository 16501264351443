import { ReactComponent as ExportIcon } from "../../../../../assets/svg/download.svg";
import { ReactComponent as ShareIcon } from "../../../../../assets/svg/share.svg";
import { ReactComponent as BackArrowIcon } from "../../../../../assets/svg/backarr.svg";
import CommonTable from "../../../../../components/organisms/CommonTable";
import { ReactComponent as StarIcon } from "../../../../../assets/svg/start.svg";
import { DetailedLineGraph } from "./detailed-line-graph";
export const DetailedScreen = ({ row, handleBack, filter, ActiveCol }: any) => {
    return (
        <div>
            <div style={{ background: "white", borderRadius: "5px" }}>
                <div style={{ borderBottom: "1px solid rgb(95, 70, 234,0.5)", borderRadius: "5px 5px 0px 0px" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px",
                            paddingLeft: "20px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                gap: "18px",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                             <BackArrowIcon style={{ cursor: "pointer" }} onClick={handleBack} />
                            <h2 style={{
                                color: "rgb(19, 15, 53)",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: 500,
                                lineHeight: "24px",
                                letterSpacing: "0.5px"
                            }}
                            >
                                Measurement and Analysis
                            </h2>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                gap: "20px",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <StarIcon />
                            <ExportIcon />{" "}
                            <ShareIcon />
                        </div>
                    </div>
                </div>
                <div className="scrollContainer55">
                    <CommonTable
                        {...{
                            cols: ActiveCol({ filter, inner: true }),
                            data: [row],
                            tableData: [row],
                            selection: false,
                            headStyle: {
                                border: "none !important",
                                "& .MuiTableCell-head": {
                                    height: "35px !important",
                                }
                            },
                            tableContainerStyles: {
                                height: 'max-content !important'
                            }
                        }}
                    />
                    <div style={{marginTop:'30px'}}>
                        <DetailedLineGraph />
                    </div>
                </div>
            </div>
        </div>
    )
}