import { useState, useEffect } from "react";
import GroupedBarChart from "../../../../../components/molecules/bar-chart";
import { getCustomerSwitchData } from "../../../../../services/insights";
import Loader from "../../../../../components/atoms/loader";
export const BarchartCustomerRelationship = ({data, loader}: any) => {

    const getData = () => {
        const labels: any = [['Customer base', ''], ['Churners ', 'competition'], ['Churners ', 'market'], ['Recurring', 'customers'], ['(C) New comers', ''], ['(M) New comers', '']]
        const datasets: any = [
            {
                label: 'dataset1',
                data: data,
                backgroundColor: [
                    'rgb(139, 151, 255)',
                    'rgb(133, 216, 221)',
                    'rgb(255, 182, 141)',
                    'rgb(116, 194, 251)',
                    'rgb(255, 133, 214)',
                    'rgb(255, 141, 148)',
                    'rgb(139, 151, 255)'
                ],
                borderColor: [
                    'rgb(139, 151, 255)',
                    'rgb(133, 216, 221)',
                    'rgb(255, 182, 141)',
                    'rgb(116, 194, 251)',
                    'rgb(255, 133, 214)',
                    'rgb(255, 141, 148)',
                    'rgb(139, 151, 255)',
                ],
                barPercentage: 0.5,
            },
            // {
            //     label: 'Churners to competition',
            //     data: [],
            //     backgroundColor: '#427ae3',
            //     borderColor: '#427ae3',

            // },
            // {
            //     label: 'Churners out of market',
            //     data: [],
            //     backgroundColor: '#3dc7d1',
            //     borderColor: '#3dc7d1',
            // }
        ]
        return {
            labels: labels,
            datasets: datasets
        }
    }

    const toptions = {
        chart: {
            type: 'bar',
            height: 350
        },
        colors: ["#1b3c7a", "#427ae3", "#3dc7d1", '#faa93e'],
        fill: {
            colors: ["#1b3c7a", "#427ae3", "#3dc7d1", '#faa93e']
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: false,
                color: "black",
                formatter: function (context: any) {
                    if(typeof context ==='number'){
                        return context;
                    } else {
                        return context[1]-context[0]
                    }
                },
                anchor: "end",
                offset: -20,
                align: "start",

            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
                barPercentage: 0.5,
                categorySpacing: 0,
            },
        },
        dataLabels: {
            enabled: false,

        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        scales: {
            y: {
                min: 0,
                ticks: {
                    stepSize: 5000,// <----- This prop sets the stepSize
                    callback: (value:any) => {
                        if (value >= 1000) {
                            return `$${value / 1000}K`;
                        }
                        return value;
                    },
                    fontSize: '10px'
                }
            },
            x: {
                barPercentage: 0.5,
                categorySpacing: 0,
                grid: {
                    display: false,
                },
            },
        },
        tooltip: {
            y: {
                formatter: function (context: any) {
                    if(typeof context ==='number'){
                        return context;
                    } else {
                        return context[1]-context[0]
                    }
                }
            }
        },
    };
    return (
        <div style={{ height: 'fit-content' }}>
            {!loader ? <GroupedBarChart {...{
                toptions,
                data: getData(),
                width: '1500%'
            }} /> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Loader size={40} />
            </div>}
        </div>
    )
}