import React, {useState, useEffect} from 'react'
import CardContainer from '../components/CardContainer';
import styles from "../monetise.module.scss";
import { getBestSellers } from '../../../../services/insights';
import { addComma } from '../useDashboard';
import Loader from '../../../../components/atoms/loader';

const BestSellersTable = ({data, height, maxHeight, bestsellersLoader, allTimes, setAllTimes}: any) => {

  return (
    <CardContainer
        {...{
            selectWidth: '130px',
            title: 'Best sellers',
            dropdown: true,
            sx: {
                height: height,
                maxHeight: maxHeight,
                display: 'flex',
                flexDirection: "column",
                // flexGrow: 1,
                overflow: 'auto'
            },
            className: 'scroll-styles',
            allTimes,
            setAllTimes,
            name: 'bestseller'
        }}
    >
        <div>
            <div className={styles["custom-table-header"]} style={{}}>
                <p style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '12px', lineHeight: '20px', color: '#202224' }}>#</p>
                <p style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '12px', lineHeight: '20px', color: '#202224', width: '230px' }}>Seller name</p>
                <p style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '12px', lineHeight: '20px', color: '#202224', width: '75px', textAlign: 'right' }}>Sales</p>
            </div>
            <div className={styles["custom-table-body"]}>
                {!bestsellersLoader ? <>
                    {data && data?.map((row: any, index: number) => (
                        <div className={styles["custom-table-row"]}>
                            <p style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '12px', lineHeight: '20px', color: '#202224' }}>{index+1}</p>
                            <p style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '12px', lineHeight: '20px', color: '#202224', width: '230px' }}>{row?.product}</p>
                            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100px', gap: '24px' }}>
                                <img src={row.img} alt="" width={24} />
                                <p style={{ cursor: "default", fontFamily: 'Poppins', fontWeight: 600, fontSize: '12px', lineHeight: '20px', color: '#202224', overflow: 'hidden', textOverflow: 'ellipsis' }} title={`$ ${addComma(row?.sales_current)}`}>${addComma(row?.sales_current)}</p>
                            </div>
                        </div>
                    ))}
                </> : <div style={{ height: height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader size={50} />
                    </div>}
            </div>
        </div>
    </CardContainer>
  )
}

export default BestSellersTable