import React, { forwardRef } from "react";
import { calHeight, StyledInput } from "./styles";
import { useTheme } from '@mui/material/styles';
import { inputLabelClasses } from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
interface FormInputProps {
  type?: React.HTMLInputTypeAttribute,
  size?: "small" | "medium",
  variant?: "outlined" | "filled" | "standard",
  name?: string,
  value?: any,
  placeholder?: string,
  disabled?: boolean,
  readonly?: any,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
  innerRef?: React.RefObject<HTMLInputElement>,
  startIcon?: React.ReactSVGElement | React.ReactNode,
  endIcon?: React.ReactSVGElement | React.ReactNode,
  sx?: any,
  error?: boolean,
  onKeyDown?: any,
  onKeyUp?: any
  autoFocus?: true,
  id?: string,
  forwardRef?: any,
  maxLength?: any,
  handlePaste?: any,
  inputProps?: any;
  className?: string;
  label?: string;
}

const FormInput = forwardRef((props: FormInputProps, ref) => {
  const theme = useTheme();
  const {
    type = "text",
    size = "small",
    variant = "outlined",
    name,
    value,
    placeholder,
    disabled = false,
    onChange,
    handleBlur,
    onFocus,
    readonly,
    innerRef,
    forwardRef,
    sx,
    error,
    startIcon = null,
    endIcon = null,
    onKeyDown,
    onKeyUp,
    autoFocus,
    id,
    maxLength,
    handlePaste,
    inputProps = {},
    className = '',
    label = ''
  } = props;
  const height = calHeight(size)

  return (
    <StyledInput
      ownerState={{ variant, size }}
      size={size}
      theme={theme}
      variant={variant}
      error={error}
      sx={{ ...sx }}
      autoComplete='off'
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      inputRef={forwardRef}
      onPaste={handlePaste}
      InputLabelProps={{
        sx: {
          color: 'rgb(93, 97, 111)',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',
          textAlign: 'left',
          [`&.${inputLabelClasses.shrink}`]: {
            color: "rgb(95, 70, 254)"
          }
        }
      }}
      InputProps={{
        ...(startIcon ? { startAdornment: <InputAdornment position="start">{startIcon}</InputAdornment> } : null),
        ...(endIcon ? { endAdornment: <InputAdornment position="end" >{endIcon}</InputAdornment> } : null)
      }}
      {...{
        label,
        type,
        inputProps,
        name,
        value,
        placeholder,
        disabled,
        onChange,
        onFocus,
        readonly,
        onBlur: handleBlur,
        autoFocus,
        id,
        className
      }
      }
    />
  )

})
export default FormInput;