import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ReactComponent as BottomLogo } from '../../../assets/svg/centerLogo.svg';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormInput from '../../../components/atoms/FormInputField';
import styles from './login.module.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const defaultTheme = createTheme();

export default function StartPage() {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };
    const navigate = useNavigate();

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid
                container
                component='main'
                sx={{ height: '100vh' }}
                className={`font-poppins ${styles.mainCont}`}
            >

                <CssBaseline />

                <Grid
                    item
                    md={12}
                    // className={styles.bgimage}
                    sx={{
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#5B47F5',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <Grid sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh'
                    }}>
                        <Box
                            component='form'
                            noValidate
                            onSubmit={handleSubmit}
                            display={'flex'}
                            gap={'16px'}
                            flexDirection={'column'}

                            className={styles.mainLoginCont}
                            sx={{
                                background: 'white',
                                width: '540px',
                                height: '300px',
                                padding: '0px 50px'
                            }}
                        >
                            <p className={styles.mainLoginText} style={{ alignItems: 'center' }}>Before we get started we need a few things from you</p>
                            <p style={{ fontSize: '16px', color: '#5D616F' }}>Please answer a few questions</p>
                            <Button sx={{
                                background: 'rgb(48, 79, 254) !important',
                                borderRadius: '4px',
                                width: '100%',
                                marginTop: '20px',
                                color: 'white !important'
                            }} onClick={() => navigate('/company-setup')}>Let’s Start</Button>
                        </Box>
                    </Grid>
                    <Grid sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: '40%',
                        zIndex: 1
                    }}>
                        <BottomLogo />
                    </Grid>
                </Grid>

            </Grid>
        </ThemeProvider>
    );
}
